import React, { useMemo } from 'react';
import Text from 'components/Text/Text';
import WebPopContent from 'components/WebPop/WebPopContent/WebPopContent';
import { useFormikContext } from 'formik';
import { useBorrowerDetails, useLoanDetails } from 'services/loans/hooks';
import { dateWithSlashes, isNotEmpty } from 'shared/utils';
import { isACH } from 'shared/utils/webpop';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import * as yup from 'yup';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import WebPopActions from 'components/WebPop/WebPopActions/WebPopActions';
import { useClientDetailsContext } from 'services/client/context';
import HTMLTemplateParser from 'components/HTMLTemplateParser/HTMLTemplateParser';

const validationSchema = yup.object({
  customEmail: yup
    .string()
    .email('Email address is not valid')
    .required('Email address is required'),
});

export default function MakePaymentDisclaimer({ onRejectClick, isSchedule }) {
  const { values, submitForm } = useFormikContext();
  const { transaction, amount, token, id, loanAmount, convFee, scheduleDate, refund } = values;
  const { data: borrowerDetails } = useBorrowerDetails();
  const { data } = useLoanDetails(borrowerDetails.loan.loanNo);
  const { validateForm } = useFormikHelpers();
  const {
    comCollectorScheduledEmailReceiptsOverride,
    collectorOnetimeAchDisclosure,
    collectorOnetimeCardDisclosure,
    collectorRecurringAchDisclosure,
    collectorRecurringCardDisclosure,
    legalCompanyName,
    phoneNumber,
    workingHour,
  } = useClientDetailsContext();

  const paymentMethodData = data.result.data.paymentMethods.find(
    (paymentMethod) => paymentMethod.token === token || paymentMethod.id === id,
  );
  const isACHPayment = isACH(paymentMethodData.paymentType);
  const paymentAmount = loanAmount || amount;
  const paymentConvenienceFee = (transaction || convFee) ?? 0;
  const showEmailOverride = isNotEmpty(comCollectorScheduledEmailReceiptsOverride);

  const handleAcceptClick = async () => {
    if (isSchedule && showEmailOverride) {
      const errors = await validateForm(validationSchema);
      if (errors) {
        return;
      }
    }
    await submitForm();
  };

  const selectedHTMLTemplate = useMemo(() => {
    if (isACHPayment) {
      if (isSchedule) {
        return collectorRecurringAchDisclosure;
      }
      return collectorOnetimeAchDisclosure;
    }
    if (isSchedule) {
      return collectorRecurringCardDisclosure;
    }
    return collectorOnetimeCardDisclosure;
  }, [
    collectorRecurringAchDisclosure,
    collectorOnetimeAchDisclosure,
    collectorRecurringCardDisclosure,
    collectorOnetimeCardDisclosure,
    isSchedule,
    isACHPayment
  ]); 

  const selectedKeys = useMemo(() => {
    const totalAmount = Number(paymentAmount) + Number(paymentConvenienceFee);
    return {
      pay_amount: paymentAmount,
      convenience_fee: paymentConvenienceFee,
      amount: refund ? -totalAmount : totalAmount,
      date_today: dateWithSlashes(),
      date: dateWithSlashes(scheduleDate),
      last_four: paymentMethodData.lastFour,
      accountName: paymentMethodData.bankName || paymentMethodData.cardBrand,
      client_name: legalCompanyName,
      client_number: phoneNumber,
      client_hours: workingHour,
      name: `${data.result.data.loanDetails.borrower.firstName} ${data.result.data.loanDetails.borrower.lastName}`,
    };
  }, [
    data, 
    legalCompanyName,
    paymentAmount,
    paymentConvenienceFee,
    paymentMethodData,
    phoneNumber,
    refund,
    scheduleDate,
    workingHour
  ]);

  return (
    <WebPopContent>
      <Text lineHeight="1.25rem" fontSize="0.875rem" color="text.primary" letterSpacing="-0.2px">
        <HTMLTemplateParser html={selectedHTMLTemplate} keys={selectedKeys} />
      </Text>
      <br />
      {isSchedule && showEmailOverride && (
        <BasicTextField
          placeholder="receipt@email.com"
          name="customEmail"
          label="Email address for scheduled payment receipt"
          showErrorsWithoutTouch
        />
      )}
      <WebPopActions
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onSecondaryButtonClick={onRejectClick}
        onPrimaryButtonClick={handleAcceptClick}
      />
    </WebPopContent>
  );
}
