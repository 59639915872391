import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import Text from 'components/Text/Text';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FormattedTextField from 'components/BasicInputs/BasicTextField/FormattedTextField/FormattedTextField';

export default function CollectionDetailsForm({ maxHeight = '70.5vh' }) {
  const mixinStyles = useMixinStyles();
  return (
    <Box
      overflow="scroll"
      maxHeight={maxHeight}
      className={mixinStyles.scrollBar}
      paddingRight="0.5rem"
      marginBottom="1rem"
    >
      <Box marginBottom="1.25rem">
        <Grid alignItems="flex-start" container>
          <Grid container alignItems="center" item xs={5}>
            <Text
              paddingTop="0.6875rem"
              fontSize="0.75rem"
              fontWeight="400"
              color='#1A1A20'
              lineHeight="0.875rem"
            >
              Collection code
            </Text>
          </Grid>
          <Grid item xs={7}>
            <BasicTextField
              name="collectionCode"
              placeholder="Enter collection code"
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom="1.25rem">
        <Grid alignItems="flex-start" container>
          <Grid container alignItems="center" item xs={5}>
            <Text
              paddingTop="0.6875rem"
              fontSize="0.75rem"
              fontWeight="400"
              color='#1A1A20'
              lineHeight="0.875rem"
            >
              Phone number
            </Text>
          </Grid>
          <Grid item xs={7}>
            <FormattedTextField
              placeholder="Enter phone number"
              name="phoneNumber"
              formatProps={{
                format: '(###) ###-####',
                allowEmptyFormatting: true,
                mask: '_',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
