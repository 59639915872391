import React from 'react';
import SearchBarProvider from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import PaginationProvider from 'components/Pagination/PaginationProvider/PaginationProvider';
import { Box } from '@material-ui/core';
import { useGroups } from 'services/groups/hooks';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import EmptyState from 'components/EmptyState/EmptyState';
import GroupsToolbar from './GroupsToolbar/GroupsToolbar';
import GroupsList from './GroupsList/GroupsList';
import GroupsTabActive from './GroupsTabActive/GroupsTabActive';
import GroupsTabActiveContextProvider from './GroupsTabActive/GroupsTabActiveProvider/GroupsTabActiveProvider';

function GroupsTab() {
  const { data, isLoading } = useGroups();
  return (
    <GroupsTabActiveContextProvider>
      <Box height="100%" display="flex">
        {isLoading ? (
          <Box width="100%" paddingTop="2rem" display="flex" flexDirection="column">
            <SpinnerWrapped />
          </Box>
        ) : (
          <>
            {data && (data?.status === API_RESPONSE_STATUS.FAILED || !data.result.data.length) ? (
              <Box width="100%" paddingTop="2rem" display="flex" flexDirection="column">
                <GroupsToolbar />
                <EmptyState variant="groups" />
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexBasis="50%"
                  marginRight="3.25rem"
                  maxWidth="50%"
                  paddingTop="2rem"
                >
                  <GroupsToolbar />
                  <GroupsList />
                </Box>
                <Box bgcolor="white" flexBasis="50%" maxWidth="50%" display="flex">
                  <GroupsTabActive />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </GroupsTabActiveContextProvider>
  );
}

export default function GroupsTabWrappedWithPagination() {
  return (
    <SearchBarProvider>
      <PaginationProvider>
        <GroupsTab />
      </PaginationProvider>
    </SearchBarProvider>
  );
}
