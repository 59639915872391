import { LMS } from "shared/constants/loans";

export function getPortfolioName(lenderBranches, portfolioNo) {
    let portfolioName = '';
    try {
      const index = lenderBranches.findIndex(x => parseInt(x.branchId) === parseInt(portfolioNo))
      portfolioName = lenderBranches[index].branchName;
    } catch (data) {
      portfolioName = 'None Found';
    };
    return portfolioName
}

export function checkLms(lms_id) {
    if (LMS[lms_id]) {
        return LMS[lms_id];
    } else {
        return false;
    }
}