import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Text from 'components/Text/Text';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import OperatorsSelect from 'components/BasicInputs/BasicSelectInput/OperatorsSelect/OperatorsSelect';
import MoneyFormatField from 'components/BasicInputs/BasicTextField/MoneyFormatField/MoneyFormatField';
import { useFormikContext } from 'formik';

export default function AddNewGroupInput({
  label,
  placeholder,
  operatorName,
  inputName,
  switchName,
  isMoneyInput,
  InputComponent,
  customOperators,
}) {
  const { values } = useFormikContext();
  let disabled = false;
  if (values[switchName] === false) {
    disabled = true;
  }
  return (
    <Box marginBottom="1.25rem">
      <Grid alignItems="flex-start" container>
        <Grid container alignItems="center" item xs={5}>
          <Text
            paddingTop="0.6875rem"
            fontSize="0.75rem"
            fontWeight={values[switchName] ? 600 : 400}
            color={values[switchName] ? 'text.primary' : '#1A1A20'}
            lineHeight="0.875rem"
          >
            {label}
          </Text>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" alignItems="flex-start">
            {operatorName && (
              <Box flex={1} marginRight="1.25rem">
                <OperatorsSelect
                  disabled={disabled}
                  name={operatorName}
                  operators={customOperators}
                />
              </Box>
            )}
            {InputComponent ? (
              <Box flex={3} minWidth="0" marginRight={switchName ? '1.25rem' : '0'}>
                <InputComponent
                  disabled={disabled}
                  name={inputName}
                  placeholder={placeholder}
                />
              </Box>
            ) : (
              <Box flex={!!operatorName ? 1 : ''} marginRight={switchName ? '1.25rem' : '0'}>
                {isMoneyInput ? (
                  <MoneyFormatField
                    disabled={disabled}
                    name={inputName}
                    placeholder={placeholder}
                    size="sm"
                  />
                ) : (
                  <BasicTextField
                    disabled={disabled && !!operatorName}
                    name={inputName}
                    placeholder={placeholder}
                    size="sm"
                  />
                )}
              </Box>
            )}
            {switchName && (
              <Box paddingTop="0.6875rem">
                <BasicSwitch name={switchName} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
