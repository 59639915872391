import React from 'react';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import { MenuItem } from '@material-ui/core';
import { useLoanStatusCode } from 'services/groups/hooks';

export default function LoanStatusCodeInput({ name, placeholder, disabled }) {
  const { data } = useLoanStatusCode();

  const hasOptions = data?.message?.length;

  return (
    <BasicSelectInput
      multiple
      noIcon
      name={name}
      placeholder={!!hasOptions ? placeholder : 'No Options'}
      disabled={disabled || !hasOptions}
      size="sm"
    >
      {hasOptions &&
        data.message.map((option) => (
          <MenuItem key={option.code} value={option.code}>
            {option.code}
          </MenuItem>
        ))}
    </BasicSelectInput>
  );
}
