import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import BasicInput from 'components/BasicInputs/BasicInput';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';
import { useClientDetailsContext } from 'services/client/context';
import { debounce } from '@material-ui/core';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import { checkLms } from 'shared/utils/loans';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex'
  },
  searchIcon: {
    zIndex: 1,
    position: 'absolute',
    left: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.common.borderInput,
    height: '1.2rem',
    width: '1.5rem',
  },
}));

export default function BorrowersSearchBar({
  placeholder = 'Search By Account Number or Borrower Name',
  placeholderBranch = 'Enter Branch Number',
  isLoading,
  hideBranch = false,
}) {
  const classes = useStyles();
  const { searchString, portfolio, setQuery } = useBorrowersFilters();
  const { currentUser } = useCurrentUser();
  const [queryString, setQueryString] = useState(searchString);
  const [branchString, setBranchString] = useState(undefined);
  const [disableBranchString, setDisableBranchString] = useState(false);
  const { lms } = useClientDetailsContext();

  const handleSearchChange = useCallback((query, branch) => {
    if (!query && !branch) {
      setQuery({ query: undefined, page: undefined });
      return;
    }
    let _data = {
      query: query,
      page: undefined
    }
    if (checkLms(lms) === "CFS") {
      _data.portfolio = branch
    }

    setQuery(_data);
  }, [lms, setQuery]);

  const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 300), []);

  const handleSearchQueryChange = useCallback((e) => {
    setQueryString(e.target.value);
    debouncedHandleSearchChange(e.target.value, branchString);
  }, [branchString, debouncedHandleSearchChange]);

  const handleBranchQueryChange = useCallback((e) => {
    setBranchString((e.target.value) ? e.target.value : undefined);
    debouncedHandleSearchChange(queryString, e.target.value);
  }, [queryString, debouncedHandleSearchChange]);

  useEffect(() => {
    setQueryString(searchString);
    setBranchString(portfolio);
  }, [searchString, portfolio]);

  useEffect(() => {
    if (currentUser && checkLms(lms) === "CFS" && currentUser.portfolioid) {
      setBranchString(currentUser.portfolioid);
      setDisableBranchString(true);
    }
  }, [lms, currentUser, setBranchString, setDisableBranchString]);

  return (
    <div className={classes.grow}>
      <BasicInput
        width={checkLms(lms) === "CFS" ? "65%" : "100%"}
        paddingRight="5%"
        value={queryString}
        startIcon={<SearchIcon className={classes.searchIcon} />}
        placeholder={placeholder}
        onChange={handleSearchQueryChange}
        loading={isLoading}
      />
      {(checkLms(lms) === "CFS" && hideBranch === false) ? <BasicInput
        label="Branch No"
        width="35%"
        value={branchString}
        startIcon={<SearchIcon className={classes.searchIcon} />}
        placeholder={placeholderBranch}
        onChange={handleBranchQueryChange}
        loading={isLoading}
        disabled={disableBranchString}
      /> : null}
    </div>
  );
}
