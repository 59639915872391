import React from 'react';
import BasicUpload from 'components/BasicInputs/BasicUpload/BasicUpload';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import { useField } from 'formik';
import Center from 'components/Center/Center';
import SettingsSection from '../SettingsSection';
import SettingsSectionUpdate from '../../SettingsSectionUpdate/SettingsSectionUpdate';

export default function SettingsSectionUpload({
  title,
  description,
  name,
  label = 'Upload image',
  accept,
  buttonLabel,
  PreviewComponent,
  ...rest
}) {
  const [field] = useField(name);

  return (
    <SettingsSection title={title} description={description}>
      <FlexColumn alignItems="center" justifyContent="center" height="12rem" {...rest}>
        {field.value ? (
          PreviewComponent
        ) : (
          <Center flex={1}>
            <BasicUpload name={name} accept={accept}>
              <FlexRow justifyContent="center" marginBottom="0.25rem">
                <Icon name="upload" />
              </FlexRow>
              <Text
                fontSize="0.75rem"
                lineHeight="0.875rem"
                color="rgba(34, 47, 62, 0.5)"
                letterSpacing="0.3px"
                textAlign="center"
              >
                {label}
              </Text>
            </BasicUpload>
          </Center>
        )}
        <SettingsSectionUpdate buttonLabel={buttonLabel}/>
      </FlexColumn>
    </SettingsSection>
  );
}
