import { useMutation, useQuery, useQueryCache } from 'react-query';
import produce from 'immer';
import { PAYMENT_CHANNELS } from 'shared/constants/payments';
import { useBorrowerDetails } from 'services/loans/hooks';
import {
  makePayment,
  getPaymentFrequencies,
  schedulePayment,
  getScheduleList,
  addCard,
  addACH,
  deletePaymentMethod,
  deleteScheduledPayment,
  deleteAnyScheduledPayment,
  editScheduledPayment,
  sendPaymentReceipt,
  getScheduleFrequency,
  getPaymentHistory,
  voidPayment,
  getConvFee,
} from './api';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { format, parse } from 'date-fns';

export const useMakePayment = (options = {}) => {
  return useMutation(makePayment, {
    ...options,
  });
};

export const useSchedulePayment = (options = {}) => {
  return useMutation(schedulePayment, {
    ...options,
  });
};
export const useEditScheduledPayment = (options = {}) => {
  return useMutation(editScheduledPayment, {
    ...options,
  });
};

export const usePaymentFrequencies = () => {
  return useQuery(['payment-frequencies'], getPaymentFrequencies, {
    staleTime: 1000 * 60 * 5,
  });
};

export const useScheduleList = (offset, limit, loanId, type) => {
  return useQuery(['schedule-list', { offset, limit, loanId, type }], getScheduleList, {
    staleTime: 0,
  });
};
export const useScheduleFrequency = (amount, pmtFreq, loanId) => {
  return useQuery(['schedule-frequency', { amount, pmtFreq, loanId }], getScheduleFrequency, {
    staleTime: 0,
    enabled: !!amount,
  });
};

const onAddPaymentMethodsSuccess = (queryCache, account, addedCard) => {
  queryCache.setQueryData(['loan-details', { account, channel: PAYMENT_CHANNELS.LMS }], old => {
    const updatedLoanDetails = produce(old, draft => {
      draft.result.data.paymentMethods.unshift(addedCard);
    });
    return updatedLoanDetails;
  });
};

export const useAddCard = (account, options = {}) => {
  const queryCache = useQueryCache();
  return useMutation(addCard, {
    ...options,
    onSuccess: addedCard => {
      if (addedCard.status === API_RESPONSE_STATUS.SUCCESS) {
        onAddPaymentMethodsSuccess(queryCache, account, {...addedCard.result.cardDetail, cardExpDate: format(parse(addedCard.result.cardDetail.cardExpDate, 'MM/yyyy', new Date()), 'MM-yyyy')});
      }
      if (options.onSuccess) {
        options.onSuccess(addedCard);
      }
    },
  });
};
export const useAddACH = (account, options = {}) => {
  const queryCache = useQueryCache();
  return useMutation(addACH, {
    ...options,
    onSuccess: addedCard => {
      if (addedCard.status === API_RESPONSE_STATUS.SUCCESS) {
        onAddPaymentMethodsSuccess(
          queryCache,
          account,
          addedCard.result.achDetails || addedCard.result.padDetails,
        );
      }
      if (options.onSuccess) {
        options.onSuccess(addedCard);
      }
    },
  });
};
export const useDeletePaymentMethod = (paymentMethodId, account, options = {}) => {
  const queryCache = useQueryCache();
  return useMutation(deletePaymentMethod, {
    ...options,
    onSuccess: () => {
      queryCache.setQueryData(['loan-details', { account, channel: PAYMENT_CHANNELS.LMS }], old => {
        const updatedLoanDetails = produce(old, draft => {
          draft.result.data.paymentMethods = draft.result.data.paymentMethods.filter(
            prevPaymentMethod => prevPaymentMethod.id !== paymentMethodId,
          );
        });
        return updatedLoanDetails;
      });
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });
};
export const useDeleteScheduledPayment = (options = {}) => {
  return useMutation(deleteScheduledPayment, {
    ...options,
  });
};
export const useDeleteRecurringScheduledPayment = (options = {}) => {
  return useMutation(deleteScheduledPayment, {
    ...options,
  });
};
export const useDeleteAnyScheduledPayment = (options = {}) => {
  return useMutation(deleteAnyScheduledPayment, {
    ...options,
  });
};
export const useSendPaymentReceipt = (options = {}) => {
  return useMutation(sendPaymentReceipt, {
    ...options,
  });
};
export const usePaymentHistory = (loanNumber, page) => {
  const { data: borrowerDetails } = useBorrowerDetails();
  return useQuery(
    ['payment-history', { loanNumber: borrowerDetails?.loan?.loanNo, page }],
    getPaymentHistory,
    {
      staleTime: 0,
    },
  );
};
export const useVoidPayment = (options = {}) => {
  return useMutation(voidPayment, {
    ...options,
  });
};

export const useConvFee = (options = {}) => {
  return useMutation(getConvFee, {
    ...options,
  });
};
