import React, { useEffect, useState } from 'react';
import useFailedLink from 'services/borrowers/hooks';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersList from '../BorrowersList/BorrowersList';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import LinkedFailedCard from './LinkedFailedCard/LinkedFailedCard';
import LinkedFailedTable from './LinkedFailedTable/LinkedFailedTable';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';
import { useClientDetailsContext } from 'services/client/context';
import { checkLms } from 'shared/utils/loans';

function LinkedFailed() {
  const { data, isFetching } = useFailedLink();
  const { lms } = useClientDetailsContext();
  const [disableBranchString, setDisableBranchString] = useState(false);
  
  useEffect(() => {
    if (checkLms(lms) === "CFS") {
      setDisableBranchString(true);
    }
  }, [lms, setDisableBranchString]);

  return (
    <BorrowersContainer>
      <BorrowersFilters
        SearchBar={<BorrowersSearchBar isLoading={isFetching} hideBranch={disableBranchString} />}
        ListTypeToggle={<BorrowersListItemToggle />}
      />
      <BorrowersList
        data={data}
        CardComponent={LinkedFailedCard}
        TableComponent={LinkedFailedTable}
      />
      <BorrowersPagination count={data?.result?.totalPage} />
    </BorrowersContainer>
  );
}

export default function StatelessSignedUp() {
  return (
    <BorrowersFiltersProvider>
      <LinkedFailed />
    </BorrowersFiltersProvider>
  );
}
