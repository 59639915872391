import React, { useMemo } from 'react';
import Container from 'components/Container/Container';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import SecondNavBar from 'containers/navigation/SecondNavBar';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useClientDetailsContext } from 'services/client/context';

export default function Settings({ routes }) {
  const { nachaEnable, showCardUpload } = useClientDetailsContext();
  const SETTINGS_TABS = useMemo(() => {
    const tabs = [
      { name: 'Borrower Tools Customization', path: '/settings' },
      { name: 'Client Admin Tools Customization', path: '/settings/cap' },
      { name: 'Notifications', path: '/settings/notifications' },
    ];
    if (nachaEnable) {
      tabs.push({ name: 'NACHA Upload', path: '/settings/nacha' });
    }
    if (showCardUpload) {
      tabs.push({ name: 'Card Upload', path: '/settings/card-upload' });
    }
    return tabs;
  }, [nachaEnable, showCardUpload]);

  return (
    <AccessControl perform={PERMISSIONS.SETTINGS.VISIT} redirectOnNoAccessTo="/">
      <SecondNavBar justifyContent="flex-start" counts={SETTINGS_TABS} />
      <Container>
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Container>
    </AccessControl>
  );
}
