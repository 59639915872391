import React from 'react';
import SearchBarProvider from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import PaginationProvider from 'components/Pagination/PaginationProvider/PaginationProvider';
import { Box } from '@material-ui/core';
import { useCollections } from 'services/collections/hooks';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import EmptyState from 'components/EmptyState/EmptyState';
import CollectionsToolbar from '../CollectionsTab/CollectionsToolbar/CollectionsToolbar';
import CollectionsList from '../CollectionsTab/CollectionsList/CollectionsList';
import { convertObjectToArray } from 'shared/utils';

function CollectionsTab() {
  const { data, isLoading } = useCollections();

  let newData = [];
  if (data && data.result) {
    newData = convertObjectToArray(data.result);
  }

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center" overflow="auto">
      {isLoading ? (
        <Box width="100%" paddingTop="2rem" display="flex" flexDirection="column">
          <SpinnerWrapped />
        </Box>
      ) : (
        <>
          {data && data.result && (data?.status === API_RESPONSE_STATUS.FAILED || !newData.length) ? (
            <Box width="100%" paddingTop="2rem" display="flex" flexDirection="column">
              <CollectionsToolbar />
              <EmptyState variant="collections" />
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                marginRight="3.25rem"
                maxWidth="50%"
                paddingTop="2rem"
              >
                <CollectionsToolbar />
                <CollectionsList />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default function CollectionsTabWrappedWithPagination() {
  return (
    <SearchBarProvider>
      <PaginationProvider>
        <CollectionsTab />
      </PaginationProvider>
    </SearchBarProvider>
  );
}
