import React, { useState } from 'react';
import { Box, makeStyles, Menu, MenuItem } from '@material-ui/core';
import Text from 'components/Text/Text';
import Icon from 'components/Icon/Icon';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import useMixinStyles from 'styles/mixins';
import { Link } from 'react-router-dom';
import FlexRow from 'components/FlexRow/FlexRow';

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: '110px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.label,
  },
}));

export default function NavigationUser() {
  const classes = useStyles();
  const mixinClasses = useMixinStyles();
  const { logout, currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box alignSelf="center">
      <Box className={mixinClasses.clickable} onClick={handleClick}>
        <Text
          lineHeight="1"
          marginBottom="0.25rem"
          fontSize="0.5rem"
          color="rgba(255, 255, 255, 0.5)"
        >
          Hello
        </Text>
        <Box display="flex" alignItems="center">
          <Text
            lineHeight="1"
            marginRight="0.5rem"
            fontSize="0.875rem"
            color="#F5F6F8"
            letterSpacing="-0.16px"
            className={mixinClasses.capitalize}
          >
            {currentUser.firstName}
          </Text>
          <Icon name="chevronDown" />
        </Box>
      </Box>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
      >
        <Link className={classes.link} to="/profile">
          <MenuItem onClick={handleClose}>
            <FlexRow>
              <Box display="flex" color="text.primary">
                <Icon name="settings" />
              </Box>
              <Text
                marginLeft="0.5rem"
                fontSize="0.75rem"
                lineHeight="0.875rem"
                letterSpacing="0.12px"
                color="text.primary"
                fontWeight={500}
              >
                Profile
              </Text>
            </FlexRow>
          </MenuItem>
        </Link>
        <MenuItem onClick={logout}>
          <FlexRow>
            <FlexRow color="text.primary">
              <Icon name="power" />
            </FlexRow>
            <Text
              marginLeft="0.5rem"
              fontSize="0.75rem"
              lineHeight="0.875rem"
              letterSpacing="0.12px"
              color="text.primary"
              fontWeight={500}
            >
              Log Out
            </Text>
          </FlexRow>
        </MenuItem>
      </Menu>
    </Box>
  );
}
