import React, { useState } from 'react';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';
import Form from 'components/Form/Form';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { buildFormData } from 'shared/utils';
import { useSendPaymentReceipt } from 'services/payments/hooks';
import { useBorrowerDetails } from 'services/loans/hooks';
import { PAYMENT_CHANNELS } from 'shared/constants/payments';
import { useClientDetailsContext } from 'services/client/context';
import WebPopActions from '../../WebPopActions/WebPopActions';
import PaymentResponseForm from './PaymentResponseForm/PaymentResponseForm';
import WebPopContent from '../../WebPopContent/WebPopContent';
import PaymentResponseHeader from './PaymentResponseHeader/PaymentResponseHeader';
import PaymentResponseReceipt from './PaymentResponseReceipt/PaymentResponseReceipt';

const initialValues = {
  phoneNo: '',
  email: '',
};
const validationSchema = yup.object({
  email: yup.string().email('Email address is not valid'),
  phoneNo: yup
  .string().optional().nullable()
});

export default function PaymentResponse({ responseInfo, isSchedule, onBackClick }) {
  const [isReceiptSent, setIsReceiptSent] = useState(false);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { onClose } = useActiveTab();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { enableSendReceiptCap } = useClientDetailsContext();

  const [mutate] = useSendPaymentReceipt({
    onSuccess: response => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage('Payment receipt sent successfully!');
        setIsReceiptSent(true);
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });
  const paymentFailed = responseInfo?.status === API_RESPONSE_STATUS.FAILED;

  const handleSendReceipt = async (values) => {
    if (!isReceiptSent) {
      const formData = new FormData();
      buildFormData(formData, {
        account: borrowerDetails.loan.loanNo,
        email: values.email,
        phoneNo: values.phoneNo,
        receiptId: responseInfo?.result.confirmId,
        channel: PAYMENT_CHANNELS.CAP,
      });
      await mutate(formData);
    }
  };

  return (
    <WebPopContent>
      <Box marginBottom="1rem" flex={1}>
        <PaymentResponseHeader
          paymentFailed={paymentFailed}
          isSchedule={isSchedule}
          responseMessage={responseInfo?.message}
        />
        {!paymentFailed && !isSchedule && (
          <>
            <PaymentResponseReceipt confirmId={responseInfo?.result?.confirmId} />
            {enableSendReceiptCap && (
              <Box>
                <Text
                  fontWeight={500}
                  letterSpacing="0.21px"
                  fontSize="0.875rem"
                  lineHeight="0.875rem"
                  marginBottom="1.25rem"
                >
                  Send a receipt via text message or email:
                </Text>
                <Form
                  initialValues={initialValues}
                  onSubmit={handleSendReceipt}
                  validationSchema={validationSchema}
                >
                  <PaymentResponseForm isReceiptSent={isReceiptSent} handleSubmit={handleSendReceipt} />
                </Form>
              </Box>
            )}
          </>
        )}
      </Box>
      <WebPopActions
        primaryButtonText={isSchedule ? 'Schedule Another Payment' : 'Make Another Payment'}
        onPrimaryButtonClick={onBackClick}
        secondaryButtonText="Finish"
        onSecondaryButtonClick={onClose}
      />
    </WebPopContent>
  );
}
