export const COLOR = {
  GREENBUTTON: 'green',
  GREENBUTTON_HOVER: '#00a757',
  WHITE: 'white',
  ORANGE: 'orange',
  LIGTHGRAY: '#ada9a9',
  GRAY: 'gray',
  THINBORDER: '#e3eaf7',
};

export const WEBPOP_TABS = {
  MAKE_PAYMENT: 1,
  SCHEDULE_PAYMENT: 2,
  SCHEDULE_LIST: 3,
};

/**
 * START API CONSTANTS
 */
const HOST = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_API_URL;
const WEBHOST = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_WEB_API_URL;
const BP = process.env.REACT_APP_ROOT_URL;
export const ENV = {
  HOST,
  WEBHOST,
  BP,
  TOKEN:
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKQAv9wZG/IMfb+OIuTujeRb9YAalLRIo7bGy7LdBNtcPas+d+PF56tzH3rZATU6TsCEU44npuydabvq9uwCUWECAwEAAQ==',
};

export const ENDPOINTS = {
  clientDetails: 'client/details',
  clientSettings: 'client/control-settings',
  getApiUser: 'client/get-api-user',
  getAppDetails: 'users/app-details',
  getBorrowerDetails: 'loan/get-borrower-details?installLoanId=',
  getData: 'users/stateslist',
  getExportBorrowers: 'users/export-borrowers',
  getFailedLenders: 'users/failed-notify-lenders',
  getFrecuency: 'client/payment-frequencies',
  getLenders: 'users/signed-up-lenders',
  getLoanDetails: 'v1/web-service/get-loan-details',
  getOnApp: 'users/get-on-app',
  getPaymentMade: 'payments/payment-made',
  getReceiptsByLoan: 'loan/receipts-by-loan',
  getRulesDetails: 'rule/get-rule-details?subCatId=',
  getScheduleList: 'v1/web-service/schedule-list',
  getScheduleByLoan: 'payments/schedule-list',
  getSsnDobDetails: 'client/get-ssn-dob-details',
  logIn: 'users/login',
  forgot: 'users/forgot-password',
  postPayment: 'v1/web-service/make-payment',
  postRefund: 'payments/refund-payment',
  postResetPassword: 'users/reset-install-password',
  postDeleteloan: 'loan/remove-loan',
  postDeleteLoans: 'loan/remove-loans',
  postAddBorrower: 'loan/add-loan-account',
  updateRuleDetails: 'rule/update-rule',
  postComment: 'client/comment',
  getConvFee: 'v1/web-service/get-conv-fee',
};
