import { webAPI } from 'shared/utils/http';

export const getCollections = async payload => {
  const response = await webAPI.get(
    `/collection/list`,
  );
  return response.data;
};


export const createCollection = async payload => {
  const response = await webAPI.post('collection/create', payload);
  return response.data;
};

export const deleteCollection = async payload => {
  const response = await webAPI.post('collection/delete', payload);
  return response.data;
};

export const updateCollection = async payload => {
  const response = await webAPI.post('collection/edit', payload);
  return response.data;
};

