import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  cardSubtitle: {
    fontSize: '1rem',
    letterSpacing: '-0.16px',
    lineHeight: 1,
    marginBottom: '0.75rem',
    textAlign: 'left',
    alignSelf: 'flex-start',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },
}));

export default function BasicCardSubtitle({ children, className }) {
  const classes = useStyles();
  return (
    <Typography component="div" className={clsx(classes.cardSubtitle, className)} color="textPrimary">
      {children}
    </Typography>
  );
}
