import React from 'react';
import { useCollections } from 'services/collections/hooks';
import { Box, makeStyles } from '@material-ui/core';
import EmptyState from 'components/EmptyState/EmptyState';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import useMixinStyles from 'styles/mixins';
import CollectionsListItem from './CollectionsListItem/CollectionsListItem';
import { convertObjectToArray } from 'shared/utils/index';

const useStyles = makeStyles(() => ({
  listItem: {
    marginBottom: '1.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

export default function CollectionsList() {
  const mixinClasses = useMixinStyles();
  const classes = useStyles();
  const { data } = useCollections();

  let newData = [];
  if (data && data.result) {
    newData = convertObjectToArray(data.result);
  }
  
  return (
    <Box className={mixinClasses.scrollBar} marginRight="-0.75rem" overflow="scroll">
      {data && data?.status !== API_RESPONSE_STATUS.FAILED && (
        <Box paddingRight="0.5rem">
          {newData.map(item => (
            <Box className={classes.listItem} key={item.id} marginBottom="1.5rem">
              <CollectionsListItem data={item} />
            </Box>
          ))}
        </Box>
      )}
      {data && (data?.status === API_RESPONSE_STATUS.FAILED || !newData.length) && (
        <EmptyState variant="collections" />
      )}
      {!data && <SpinnerWrapped size={64} />}
    </Box>
  );
}
