import React from 'react';
import BasicButton from 'components/BasicButton/BasicButton';
import { handleLogin } from 'commons';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';

export default function CommonTilesActions({ data, onLoginClick, detailsPathId, hideLogin = false }) {
  const history = useHistory();
  const viewDetails = () => {
    const path = `/borrowers/signed-up/borrowers-details/loan-id/${detailsPathId || data.id}`;
    history.push(path);
  };
  return (
    <Grid container spacing={2}>
      {hideLogin === false && <AccessControl perform={PERMISSIONS.BORROWERS.IMPERSONATE}>
        <Grid item xs>
          <BasicButton
            fullWidth
            size="small"
            variant="outlined"
            onClick={
              onLoginClick ||
              (() =>
                handleLogin(
                  data.linkAttempt?.installAccountId || data.installAccountId,
                  data.loan.loanNo,
                ))
            }
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" marginRight="0.25rem">
                <Icon name="person" />
              </Box>
              Login
            </Box>
          </BasicButton>
        </Grid>
      </AccessControl>}
      <AccessControl perform={PERMISSIONS.BORROWERS.DETAILS}>
        <Grid item xs>
          <BasicButton fullWidth size="small" onClick={viewDetails}>
            <Box display="flex" alignItems="center">
              <Box display="flex" marginRight="0.25rem">
                <Icon name="eye" />
              </Box>
              Details
            </Box>
          </BasicButton>
        </Grid>
      </AccessControl>
    </Grid>
  );
}
