import { Box } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import FlexRow from 'components/FlexRow/FlexRow';
import React from 'react';
import { useUpdateVisibleOnApp } from 'services/client/hooks';
import SettingsSectionContent from './SettingsSectionContent/SettingsSectionContent';
import SettingsSectionHeader from './SettingsSectionHeader/SettingsSectionHeader';
import SettingsSectionReset from './SettingsSectionReset/SettingsSectionReset';

export default function SettingsSection({
  title,
  description,
  children,
  switchName,
  switchUpdateName,
}) {
  const [mutate] = useUpdateVisibleOnApp({
    statusName: switchName,
  });

  const handleUpdateVisible = (e) => {
    mutate({
      [switchUpdateName]: 1,
      status: e.target.checked,
    });
  };

  return (
    <BasicCard noPadding>
      <SettingsSectionContent>
        <FlexRow justifyContent="space-between" alignItems="flex-start">
          <SettingsSectionHeader title={title} description={description} />
          <FlexRow position="relative" top={switchName ? '-0.5rem' : 0}>
            {switchName && (
              <BasicSwitch
                onChange={handleUpdateVisible}
                name={switchName}
                label="VISIBLE ON APP"
              />
            )}
            <SettingsSectionReset />
          </FlexRow>
        </FlexRow>
        <Box>{children}</Box>
      </SettingsSectionContent>
    </BasicCard>
  );
}
