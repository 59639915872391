import { API, webAPI } from 'shared/utils/http';

// eslint-disable-next-line import/prefer-default-export
export const getClientDetails = async () => {
  const { data } = await API.get('/client/get-client-details');
  return data?.result?.data;
};

export const getCAPTool = async () => {
  const { data } = await API.get('/client/get-client-details');
  return data?.result?.data;
};

export const getDetails = async () => {
  const { data } = await webAPI.get('/client/details');
  return data?.result;
};

export const getCardFees = async () => {
  const { data } = await webAPI.get('/client/card-fees');
  return data?.result;
};

export const getBackgroundImages = async () => {
  const { data } = await webAPI.get('/client/get-background-images');
  return data?.result;
};

export const getBackgroundImage = async () => {
  const { data } = await API.get('/users/web/get-background-image');
  return data?.result;
};

export const uploadClientLogo = async (payload) => {
  const { data } = await webAPI.post('/client/upload-image', payload);
  return data?.result;
};

export const uploadLogoPlaceholder = async (payload) => {
  const { data } = await webAPI.post('/client/upload-sec-image', payload);
  return data?.result;
};

export const uploadBackgroundImage = async (payload) => {
  const { data } = await webAPI.post('/client/upload-background-image', payload);
  return data?.result;
};

export const updateAddress = async (payload) => {
  const { data } = await webAPI.post('/client/edit-address', payload);
  return data?.result;
};

export const updateWebsite = async (payload) => {
  const { data } = await webAPI.post('/client/edit-website', payload);
  return data?.result;
};

export const updatePhone = async (payload) => {
  const { data } = await webAPI.post('/client/edit-phone', payload);
  return data?.result;
};

export const updateColorCode = async (payload) => {
  const { data } = await webAPI.post('/client/edit-color-code', payload);
  return data?.result;
};

export const updateTimezone = async (payload) => {
  const { data } = await webAPI.post('/client/edit-card-fees', payload);
  return data?.result;
};

export const updateTermsText = async (payload) => {
  const { data } = await webAPI.post('/client/update-copyright', payload);
  return data?.result;
};

export const updateAdditionalInformation = async (payload) => {
  const { data } = await webAPI.post('/client/update-custom-url', payload);
  return data?.result;
};

export const updatePrivacyPolicy = async (payload) => {
  const { data } = await webAPI.post('/client/update-privacy-policy', payload);
  return data?.result;
};

export const updateVisibleOnApp = async (payload) => {
  const { data } = await webAPI.post('/client/change-status', payload);
  return data?.result;
};

export const setSignInImage = async (payload) => {
  const { data } = await webAPI.post('/client/set-client-image', payload);
  return data?.result;
};

export const updateNotifications = async (payload) => {
  const { data } = await webAPI.post('/client/change-notification-setting', payload);
  return data?.result;
};

export const updateNLS = async (payload) => {
  const { data } = await webAPI.post('/client/control-settings', payload);
  return data?.result;
};

export const getControlSettings = async (payload) => {
  const { data } = await webAPI.get('/client/control-settings', payload);
  return data?.result;
};

export const getImportNACHA = async (payload) => {
  const { data } = await webAPI.get('/client/get-import-nacha', payload);
  return data?.result;
};

export const getTransactions = async (key, payload) => {
  const { data } = await webAPI.post('/client/get-transactions', payload);
  return data?.result;
};

export const uploadNACHA = async (payload) => {
  const { data } = await webAPI.post('/loan/import-borrowers', payload);
  return data;
};

export const uploadFavicon = async (payload) => {
  const { data } = await webAPI.post('/client/upload-sec-image?type=icon', payload);
  return data?.result;
};

export const updateCAPSettings = async (payload) => {
  const { data } = await webAPI.post('client/update-cap-settings', payload);
  return data?.result;
};

export const getCardUpload = async (payload) => {
  const { data } = await webAPI.get('client/get-card-import', payload);
  return data?.result;
};

export const updateImportCards = async (payload) => {
  const { data } = await webAPI.post('/client/import-card', payload);
  return data?.result;
};
export const createMessage = async (payload) => {
  const { data } = await webAPI.post('/chat/create-message', payload);
  return data;
};

export const deleteMessage = async (payload) => {
  const { data } = await webAPI.post('/chat/delete-message', payload);
  return data;
};

export const getConversationsOldChat = async (key, { page, limit, chat }) => {
  if (chat) {
    return;
  }
  const { data } = await webAPI.get(`chat/get-conversations?limit=${limit}&page=${page}`);
  return data?.result;
};

export const getMessagesOldChat = async (key, { receiverId, page, limit }) => {
  if (!receiverId) {
    return;
  }
  const { data } = await webAPI.get( `chat/get-message?limit=${limit}&receiverId=${receiverId}&page=${page}`);
  return data?.result;
};

export const checkNewMessage = async (key, { options, id }) => {
  if (options.location.pathname === '/messages' || options.hideChat === true || options.enablePms === '1' || id === undefined) {
    return;
  }
  const { data } = await webAPI.get(`/chat/new-message?installAccountId=${id}`);
  return data;
};

export const updateNewMessage = async (payload) => {
  const { data } = await webAPI.post('/chat/update-new-message', payload);
  return data;
};
