import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import UploadNew from 'components/BasicInputs/BasicUpload/UploadNew/UploadNew';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import { useField } from 'formik';
import * as pdfjsLib from 'pdfjs-dist';
import useImagesMixins from 'styles/images-mixins';
import useMixinStyles from 'styles/mixins';
import SettingsSectionUpload from '../../../Shared/SettingsSection/SettingsSectionUpload/SettingsSectionUpload';

export default function PrivacyPolicyForm() {
  const mixinStyles = useMixinStyles();
  const imagesStyles = useImagesMixins();
  const [field] = useField('privacyPolicy');

  const draw = (pdfUrl) => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js`;
    pdfjsLib
      .getDocument(pdfUrl)
      .promise.then(function (pdf) {
        pdf.getPage(1).then(function getPageHelloWorld(page) {
          const scale = 1.5;
          const viewport = page.getViewport({ scale });

          const canvas = document.getElementById('the-canvas');
          if (!canvas) return;
          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const task = page.render({ canvasContext: context, viewport });
          task.promise.then(function () {});
        });
      })
      .catch(console.error);
  };

  const getPreviewImage = useMemo(() => {
    let pdfUrl;
    if (typeof field.value === 'string') {
      pdfUrl = `${field.value}`;
      draw(pdfUrl);
    }
    if (field.value instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        const typedarray = new Uint8Array(this.result);
        draw(typedarray);
      };
      fileReader.readAsArrayBuffer(field.value);
    }

    return pdfUrl;
  }, [field.value]);

  return (
    <SettingsSectionUpload
      title="Privacy Policy"
      description="Upload Privacy Policy here (.pdf)"
      name="privacyPolicy"
      label="Upload Document"
      height="15rem"
      PreviewComponent={
        <FlexRow alignItems="flex-start" height="100%" width="100%">
          <Box height="100%" display="flex" marginRight="1.25rem" flexBasis="40%" maxWidth="40%">
            <Box display="flex" flex={1}>
              <Box
                padding="0.75rem 0.375rem"
                bgcolor="rgba(187, 195, 208, 0.1)"
                overflow="hidden"
                className={imagesStyles.objectFitContain}
                component="canvas"
                height="11rem"
                width="100%"
                id="the-canvas"
              />
            </Box>
          </Box>
          <FlexColumn flexBasis="60%" maxWidth="60%" alignItems="center">
            <Text
              className={mixinStyles.ellipsis}
              marginBottom="1.2rem"
              fontSize="1.125rem"
              fontWeight={500}
              lineHeight="1.125rem"
              letterSpacing="0.27px"
            >
              Privacy Policy
            </Text>
            <FlexRow justifyContent="center">
              <Box lineHeight="1" marginRight="1rem">
                <UploadNew name="privacyPolicy" />
              </Box>
              <Box
                component="a"
                href={getPreviewImage}
                download
                className={clsx(mixinStyles.clickable, mixinStyles.noTextDecoration)}
                display="flex"
                target="_blank"
              >
                <FlexRow color="#BBC3D0" marginRight="0.25rem">
                  <Icon width="0.75rem" height="0.75rem" name="download" />
                </FlexRow>
                <Text
                  color="#BBC3D0"
                  fontSize="0.75rem"
                  fontWeight={500}
                  lineHeight="0.875rem"
                  letterSpacing="0.3px"
                >
                  Download
                </Text>
              </Box>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      }
    />
  );
}
