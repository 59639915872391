import Container from 'components/Container/Container';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import Form from 'components/Form/Form';
import PageHeader from 'components/PageHeader/PageHeader';
import PaginationProvider from 'components/Pagination/PaginationProvider/PaginationProvider';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useClientDetailsContext } from 'services/client/context';
import CardUploadTable from './CardUploadTable/CardUploadTable';
import { useImportCards } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import CardUploadForm from './CardUploadForm/CardUploadForm';
import useBTC from '../BTC/useBTC';

const initialValues = {
  fileName: null,
  upload: null,
  processDate: null,
  count: null,
  batchTotal: null,
  status: null
};

const initialValuesForm = {
  loanNo: null
};

export default function CardUpload() {
  const { showCardUpload } = useClientDetailsContext();
  const { onSettingsUpdateSuccess } = useBTC();

  const [mutate] = useImportCards({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Success' });
    },
  });
  
  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      addText: values.cardUpload,
      type: 'text'
    });
    await mutate(formData);
  };
  
  if (!showCardUpload) return <Redirect to="/settings" />;
  return (
    <Container>
      <Form initialValues={initialValuesForm} onSubmit={handleOnSubmit}>
        <CardUploadForm />
      </Form>
      <PaginationProvider>
        <Form initialValues={initialValues}>
          <FlexColumn>
            <FlexRow marginBottom="2rem">
              <PageHeader
                title=""
                description=""
                marginBottom="0"
                marginRight="1rem"
              />
            </FlexRow>
            <CardUploadTable />
          </FlexColumn>
        </Form>
      </PaginationProvider>
    </Container>
  );
}
