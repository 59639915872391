import React from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import TableActions from 'pages/Borrowers/TableActions/TableActions';
import { handleLogin } from 'commons';
import { dateWithSlashes } from 'shared/utils';

const columns = [
  { id: 'loanNumber', label: 'Account Number', minWidth: 100, align: 'center' },
  { id: 'lastSeen', label: 'Last Seen On', minWidth: 100, align: 'center' },
  {
    id: 'user',
    label: 'User',
    minWidth: 100,
    format: value => value.toLocaleString('en-US'),
    align: 'center',
  },
  { id: 'zipCode', label: 'Zip Code', minWidth: 100, align: 'center' },
  { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' },
];

function createData(loanNumber, lastSeen, user, zipCode, actions) {
  return { loanNumber, lastSeen, user, zipCode, actions };
}

export default function OnAppTable({ data }) {
  const rows = data.map(data => {
    const handleOnLoginClick = () => {
      handleLogin(data.linkAttempts[0]?.installAccountId, data.linkAttempts[0]?.attemptLoanNo);
    };
    const detailsPathId = data.linkAttempts.find(elem => !!elem.installLoan?.id && !elem.deletedAt);
    const array = createData(
      data.linkAttempts[0]?.attemptLoanNo ? data.linkAttempts[0]?.attemptLoanNo : 'N/A',
      dateWithSlashes(data.lastOnline?.date),
      data.instlFirstName ? `${data.instlFirstName} ${data.instlLastName}` : 'N/A',
      data.linkAttempts[0]?.attemptZipCd ? data.linkAttempts[0]?.attemptZipCd : 'N/A',
      <TableActions detailsPathId={detailsPathId?.installLoan?.id} onLoginClick={handleOnLoginClick} data={data} />,
    );
    return array;
  });

  return <BasicTable columns={columns} rows={rows} />;
}
