import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonBase from './ButtonBase/ButtonBase';

const useStyles = makeStyles(theme => ({
  buttonSolid: {
    border: '1px solid transparent',
    backgroundColor: theme.palette.primary[200],
    '&:hover': {
      backgroundColor: 'rgba(0, 138, 73, 0.2)',
    },
  },
  buttonLink: {
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  buttonError: {
    backgroundColor: theme.palette.error[100],
    color: theme.palette.error[500],
    '&:hover': {
      backgroundColor: theme.palette.error[200],
    },
  },
  buttonText: {
    border: 'none',
  },
}));

const mapVariantToClassName = {
  solid: 'buttonSolid',
  error: 'buttonError',
  link: 'buttonLink',
  text: 'buttonText',
};

export default function BasicButton({
  variant = 'solid',
  color = 'primary',
  className = '',
  ...rest
}) {
  const classes = useStyles({ ...rest });

  const selectedVariant = useMemo(() => classes[mapVariantToClassName[variant]], [variant, classes]);
  return (
    <>
      {selectedVariant ? (
        <ButtonBase className={`${selectedVariant} ${className}`} color={color} {...rest} />
      ) : (
        <ButtonBase className={`${className}`} color={color} variant={variant} {...rest} />
      )}
    </>
  );
}
