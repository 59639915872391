import React, { useEffect, useMemo, useCallback } from 'react';
import { Grid, Box, MenuItem } from '@material-ui/core';
import MoneyFormatField from 'components/BasicInputs/BasicTextField/MoneyFormatField/MoneyFormatField';
import { useFormikContext } from 'formik';
import Text from 'components/Text/Text';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import { useClientDetailsContext } from 'services/client/context';
import {
  SCHEDULED_PAYMENT_TYPES,
  PAYMENT_TYPES_TO_PLATFORM_DATA_FLAGS,
} from 'shared/constants/payments';
import { useLoanDetails, useBorrowerDetails } from 'services/loans/hooks';
import { useConvFee } from 'services/payments/hooks';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

export default function ConvenienceFee({ name = 'transaction', isSchedule, ...rest }) {
  const { values, setFieldValue } = useFormikContext();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { data: loanDetails } = useLoanDetails(borrowerDetails.loan.loanNo);
  const {
    convFeeWaivedReasons,
    waiveConvFee,
    colConvFeeOneSch,
    colConvFeeRecuSch,
    waiveConvFeeReasons,
    customConvFees,
  } = useClientDetailsContext();

  const handleChangeValue = useCallback((name, value) => {
    setFieldValue(name, value);
  }, [setFieldValue]);

  const shouldHaveConvenienceFee = useMemo(() => {
    if (values.scheFreq && isSchedule) {
      const selectedKey =
        values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME
          ? SCHEDULED_PAYMENT_TYPES.ONE_TIME
          : SCHEDULED_PAYMENT_TYPES.RECURRING;

      return loanDetails?.result?.data?.platformData[0][
        PAYMENT_TYPES_TO_PLATFORM_DATA_FLAGS[selectedKey]
      ];
    }
    return loanDetails?.result?.data?.platformData[0]['conv_fee_one_time'];
  }, [values.scheFreq, isSchedule, loanDetails.result.data]);

  const [mutate] = useConvFee({
    onSuccess: response => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setFieldValue(name, response.result?.data?.transactionFee);
      }
    },
  });

  // Get convenience fee from the backend and update the input everytime the amount, 
  // payment method or type of payment changes
  const setConvFee = useCallback(() => {
    let amount = values.amount ?? values.loanAmount;
    amount = parseFloat(amount);
    if (isNaN(amount)) {
      amount = parseFloat(0);
    }
    if (values.paymentMethodData) {
      let type = 0;
      if (values.scheFreq !== undefined) {
        if (values.scheFreq === 0) {
          type = 1;
        } else {
          type = 2;
        }
      }
      let _data = {
        loanId: borrowerDetails.loanId,
        id: values.paymentMethodData?.id,
        token: values.paymentMethodData?.token,
        type_of_payment: type,
        type: values.paymentMethodData?.paymentType,
        amount: parseFloat(amount),
        channel: 'CAP',
      };
      setTimeout(() => {
        mutate(_data);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, borrowerDetails.loanId]);

  
  useEffect(() => {
    if (values.paymentMethodData && shouldHaveConvenienceFee && !values.waiveConvenienceFee) {
      handleChangeValue(name, values.paymentMethodData.transactionFeeFinal);
    } else {
      handleChangeValue(name, '0');
    }
  }, [values.paymentMethodData, shouldHaveConvenienceFee, values.waiveConvenienceFee, name, handleChangeValue]);

  useEffect(() => {
    if (shouldHaveConvenienceFee) {
      if (values.waiveConvenienceFee || values.refund) {
        handleChangeValue(name, '0');
      } else {
        handleChangeValue(name, values?.paymentMethodData?.transactionFeeFinal);
      }
    }
  }, [values.waiveConvenienceFee, values.refund, shouldHaveConvenienceFee, values.paymentMethodData, name, handleChangeValue]);

  useEffect(() => {
    if (values.scheFreq) {
      if (
        (values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME && !colConvFeeOneSch) ||
        (values.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME && !colConvFeeRecuSch)
      ) {
        handleChangeValue('waiveConvenienceFee', false);
      }
    }
  }, [values.scheFreq, colConvFeeOneSch, colConvFeeRecuSch, handleChangeValue]);

  useEffect(() => {
    setConvFee();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentMethodData, values.amount, values.loanAmount, values.scheFreq, values.refund, values[name]]);

  return (
    <Grid item xs={5}>
      <Text fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="-0.12px" marginBottom="0.5rem">
        Convenience Fee
      </Text>
      {values.waiveConvenienceFee ? (
        <BasicSelectInput
          disabled={!waiveConvFeeReasons}
          noShadow
          placeholder="Select Reason"
          name="convFeeWaivedReasonId"
        >
          {convFeeWaivedReasons.map(convFeeWaiveReason => (
            <MenuItem key={convFeeWaiveReason.id} value={convFeeWaiveReason.id}>
              {convFeeWaiveReason.reasonText}
            </MenuItem>
          ))}
        </BasicSelectInput>
      ) : (
        <MoneyFormatField
          name={name}
          disabled={!customConvFees}
          noShadow
          placeholder="$0.00"
          {...rest}
        />
      )}
      {
        ((!!waiveConvFee &&!isSchedule) ||
          (values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME && colConvFeeOneSch) ||
          (values.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME && colConvFeeRecuSch)) && (
          <Box>
            <BasicCheckbox name="waiveConvenienceFee" label="Waive Convenience Fee" />
          </Box>
        )}
    </Grid>
  );
}
