import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import { Grid, makeStyles } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import PostComment from 'containers/borrowers/PostComment';
import {
  useWebPopSwitch,
  WEBPOP_COMPONENTS,
} from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useClientDetailsContext } from 'services/client/context';
import { WEBPOP_TABS } from '../../../../constants';
import { useDetails } from 'services/client/hooks';

const useStyles = makeStyles(({ palette }) => ({
  postComment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px'
  },
}));

export default function DetailButtons() {
  const { data } = useDetails();
  const classes = useStyles();
  const { setActive, setInitialTab } = useWebPopSwitch();
  const { hideSchedule } = useClientDetailsContext();
  const [open, setOpen] = useState(false);

  const handleSetActiveTab = (initialTab) => {
    setActive(WEBPOP_COMPONENTS.PAYMENTS_TABS);
    setInitialTab(initialTab);
  };

  const openPostComment = () => {
    setOpen(true);
  };

  const closePostComment = () => {
    setOpen(false);
  };

  return (
    <BasicCardWrapper padding="1.5rem 1.125rem 1.5rem">
      <CardContent>
        <Grid container spacing={3}>
          {!hideSchedule && (
            <AccessControl perform={PERMISSIONS.PAYMENTS.SCHEDULE}>
              <Grid item xs>
                <ContainedButton
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSetActiveTab(WEBPOP_TABS.SCHEDULE_PAYMENT)}
                >
                  Schedule
                </ContainedButton>
              </Grid>
            </AccessControl>
          )}
          <AccessControl perform={PERMISSIONS.PAYMENTS.MAKE}>
            <Grid item xs>
              <ContainedButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSetActiveTab(WEBPOP_TABS.MAKE_PAYMENT)}
              >
                Make Payment
              </ContainedButton>
            </Grid>
          </AccessControl>
        </Grid>
        {(data && data.comment === true) && <Grid container spacing={3}>
          <Grid className={classes.postComment} item xs>
            <Grid item xs={6}>
              <ContainedButton
                fullWidth
                type="submit"
                variant="outlined"
                color="primary"
                onClick={() => openPostComment()}
              >
                Post Comment
              </ContainedButton>
            </Grid>
          </Grid>
        </Grid>}
        {open && (
          <PostComment onOpen={openPostComment} onClose={closePostComment}/>
        )}
      </CardContent>
    </BasicCardWrapper>
  );
}
