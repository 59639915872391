import React, { useState, useContext, useMemo, createContext } from 'react';

const BasicsTabsContext = createContext();

const BasicsTabsContextProvider = ({ children, initialActiveTab, onClose }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab || 0);
  const value = useMemo(() => ({ activeTab, setActiveTab, onClose }), [activeTab, onClose]);

  return <BasicsTabsContext.Provider value={value}>{children}</BasicsTabsContext.Provider>;
};

export default BasicsTabsContextProvider;

export function useActiveTab() {
  const context = useContext(BasicsTabsContext);
  if (!context) {
    throw new Error(`useActiveTab must be used within a BasicsTabsContext`);
  }
  return context;
}
