import React from 'react';
import { Box } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import Icon from '../Icon';

export default function IconButton({ width, height, name, color = '#7C8DA0', ...rest }) {
  const mixinClasses = useMixinStyles();
  return (
    <Box className={mixinClasses.clickable} role="button" display="flex" {...rest}>
      <Icon name={name} width={width} height={height} color={color} />
    </Box>
  );
}
