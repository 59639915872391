import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Text from 'components/Text/Text';
import MoneyFormat from 'components/Formatters/MoneyFormat/MoneyFormat';
import { useFormikContext } from 'formik';
import ScheduleCount from 'components/WebPop/PaymentTabs/SchedulePayment/SchedulePaymentForm/ScheduleCount/ScheduleCount';
import { SCHEDULED_PAYMENT_TYPES } from 'shared/constants/payments';

export default function PaymentDetailsContent({ children, withScheduleCount }) {
  const { values } = useFormikContext();
  const { loanAmount, amount, transaction, convFee } = values;
  const paymentAmount = loanAmount || amount || 0;
  const paymentConvenienceFee = transaction || convFee || 0;
  const totalAmount = Number(paymentAmount) + Number(paymentConvenienceFee) || 0;

  return (
    <Box borderRadius="4px" overflow="hidden">
      <Box padding="0.875rem 2rem 1.25rem 2rem" bgcolor="common.backgroundMainLight">
        <Grid container justifyContent="space-between" spacing={3}>
          {children}
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="1rem 2rem"
        bgcolor="rgba(124, 141, 160, 0.09)"
      >
        {withScheduleCount && values.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME && (
          <ScheduleCount />
        )}
        <Box marginLeft="auto">
          <Text
            textAlign="right"
            fontSize="0.875rem"
            letterSpacing="-0.14px"
            lineHeight="0.875rem"
            color="common.label"
            marginBottom="0.5rem"
          >
            Total Amount
          </Text>
          <Text
            textAlign="right"
            color="rgba(34, 47, 62, 0.87);"
            fontWeight={500}
            fontSize="1.125rem"
            lineHeight="0.875rem"
            letterSpacing="-0.18px"
          >
            <MoneyFormat value={values.refund ? -totalAmount : totalAmount} />
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
