import React from 'react';
import { Badge, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  secBadgeRoot: {
    fontWeight: ({ active }) => (active ? 600 : 500),
    whiteSpace: 'nowrap',
    color: ({ active, textColor }) =>
      active ? theme.palette.primary[500] : (textColor ? textColor : theme.palette.common.secNavbarText),
  },
  SecCustomBadge: {
    borderRadius: '3px',
    backgroundColor: ({ active, badgeColor }) =>
      active ? theme.palette.primary[500] : (badgeColor ? badgeColor : theme.palette.common.secNavbarText),
    color: theme.palette.common.backgroundCardHighlight,
    top: '-3px',
    right: '-12px',
    fontSize: '0.5rem',
    fontWeight: 500,
    letterSpacing: '0.08px',
    height: '0.75rem',
    padding: '2px 4px 4px',
  },
}));

export default function ActiveBadge({ children, active, textColor = null, badgeColor = null, ...rest }) {
  const classes = useStyles({ active, textColor, badgeColor });
  return (
    <Badge overlap="rectangular" classes={{ badge: classes.SecCustomBadge, root: classes.secBadgeRoot }} {...rest}>
      {children}
    </Badge>
  );
}
