import React, { useMemo, useEffect, useCallback } from 'react';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import { MenuItem, makeStyles } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import PaymentMethodsListItem from '../PaymentMethodsList/PaymentMethodsListItem/PaymentMethodsListItem';

const parsePaymentMethods = (paymentMethods) =>
  paymentMethods &&
  paymentMethods.map(method => ({
    id: method.id,
    value: `Account Ending ${method.lastFour} - ${method.nameOnAch}`,
    token: method.token,
    paymentType: method.paymentType,
    brand: method.cardBrand,
    lastFourDigits: method.lastFour,
    nameOnCard: method.name,
    nameOnAch: method.nameOnAch,
    bankName: method.bankName,
    expDate: method.cardExpDate,
  }));

const useStyles = makeStyles({
  menuItem: {
    padding: 0,
    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
      background: 'rgba(0, 137, 73, 0.1)',
    },
  },
});

export default function PaymentMethodsInput({
  paymentMethods,
  inputValueKey = 'token',
  name = 'token',
  isSchedule,
}) {
  const classes = useStyles();
  const [field] = useField(name);
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const parsedPaymentMethods = useMemo(
    () => parsePaymentMethods(paymentMethods),
    [paymentMethods],
  );

  const handleErrorValue = useCallback((value) => {
    setFieldError(name, value);
  }, [name, setFieldError]);

  const handleChangeValue = useCallback((name, value) => {
    setFieldValue(name, value);
  }, [setFieldValue]);

  useEffect(() => {
    if (values.scheFreq && isSchedule && field.value) {
      const paymentMethodData = paymentMethods.find(
        paymentMethod =>
          (!isSchedule && paymentMethod.token === field.value) ||
          (isSchedule && paymentMethod.id === field.value),
      );
      if (!paymentMethodData?.accountNumber) {
        handleErrorValue(name, 'Invalid method for recurring frequencies. Please select an ACH');
      } else {
        handleErrorValue(name, '');
      }
    }
  }, [values.scheFreq, field.value, isSchedule, paymentMethods, name, handleErrorValue]);

  useEffect(() => {
    const paymentMethodData = paymentMethods.find(
      paymentMethod =>
        (!isSchedule && paymentMethod.token === field.value) ||
        (isSchedule && paymentMethod.id === field.value),
    );
    handleChangeValue('paymentMethodData', paymentMethodData);
  }, [field.value, isSchedule, paymentMethods, handleChangeValue]);

  return (
    <BasicSelectInput noShadow placeholder="Select Payment Method" name={name}>
      {parsedPaymentMethods.map(paymentMethod => (
        <MenuItem
          className={classes.menuItem}
          key={paymentMethod.id}
          value={paymentMethod[inputValueKey]}
        >
          <PaymentMethodsListItem readOnly {...paymentMethod} />
        </MenuItem>
      ))}
    </BasicSelectInput>
  );
}
