import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import SubmitSelect from 'components/SubmitSelect/SubmitSelect';
import SubmitInput from 'components/SubmitInput/SubmitInput';
import Icon from 'components/Icon/Icon';
import { Box, Grid } from '@material-ui/core';
import { useBorrowerDetails } from 'services/loans/hooks';
import MoneyFormat from 'components/Formatters/MoneyFormat/MoneyFormat';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { useClientDetailsContext } from 'services/client/context';
import { handleLogin } from '../../../../commons';
import { useStyles } from '../../../../containers/borrowers/borrowerStyles';
import LoanDetailsBalance from '../LoanDetailsCommon/LoanDetailsBalance/LoanDetailsBalance';
import LoanDetailsRow from '../LoanDetailsCommon/LoanDetailsRow/LoanDetailsRow';
import { dateWithSlashes } from 'shared/utils';
import { getPortfolioName } from 'shared/utils/loans';
import { getLoanDetails } from 'services/loans/api';
import { useAlertContext } from 'shared/contexts/useAlertContext';

export default function LoanDetails(props) {
  const classes = useStyles();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { hidePayOffBalanceCap, isSsn, allowUpdateSsn, createAccountWithPortfolio, lenderBranches } = useClientDetailsContext();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();

  const handleSubmitPortfolio = async (value, setIsEdit) => {
    const _data = {
      account: borrowerDetails.loan.loanNo,
      portfolioId: value,
      channel: 'CAP'
    };
    const result = await getLoanDetails(null, _data);
    if (result.status) {
      setAlertMessage(result.message);
    } else { 
      setErrorAlertMessage(result.message);
    }
    setIsEdit(false);
  };

  const handleSubmitSsn = async (value, setIsEdit) => {
    const _data = {
      account: borrowerDetails.loan.loanNo,
      portfolioId: borrowerDetails.loan.loanPortfolioNo,
      ssn_last_four: value,
      channel: 'CAP'
    };
    const result = await getLoanDetails(null, _data);
    if (result.status) {
      setAlertMessage(result.message);
    } else { 
      setErrorAlertMessage(result.message);
    }
    setIsEdit(false);
  };

  let newList = [];
  if (lenderBranches.length) {
    for (let i in lenderBranches) {
      newList.push({
        id: lenderBranches[i].id,
        name: lenderBranches[i].branchName,
        value: lenderBranches[i].branchId,
        labelFormat: lenderBranches[i].branchName + ' - ' + lenderBranches[i].branchId
      })
    }
  }

  const options = {
    name: "Portfolio",
    list: newList
  };

  let ssn = 'N/A';
  if (borrowerDetails.linkAttempt.attemptTinFour) {
    ssn = `XXX-XX-${borrowerDetails.linkAttempt.attemptTinFour}`;
  }

  let ssnValue = 
    <LoanDetailsRow
      attribute="Social Security Number"
      value={ssn}
    />;
  if (allowUpdateSsn) {
    ssnValue = 
    <LoanDetailsRow
      attribute="Social Security Number"
      value={
        <SubmitInput
          value={borrowerDetails.linkAttempt.attemptTinFour}
          displayValue={ssn}
          onSubmit={handleSubmitSsn}
          textAlign="center"
          maxLength="4"
          type="number"
        />
      }
    />
  }
  
  return (
    <BasicCardWrapper padding="1rem 1.125rem">
      <CardContent>
        <Box>
          <Box marginBottom="1.25rem">
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <LoanDetailsBalance
                  label="Loan Balance"
                  value={<MoneyFormat value={parseFloat(borrowerDetails.loan.principalBalance).toFixed(2)} />}
                />
              </Grid>
              <Grid item xs={6}>
                <LoanDetailsBalance
                  label="Payment Remaining"
                  value={<MoneyFormat value={parseFloat(borrowerDetails.loan.totalAmtDue).toFixed(2)} />}
                />
              </Grid>
            </Grid>
          </Box>
          <LoanDetailsRow attribute="Account" value={borrowerDetails.loan?.loanNo} />
          {(createAccountWithPortfolio && lenderBranches.length) && <LoanDetailsRow attribute="Portfolio" value={
            <SubmitSelect
              value={getPortfolioName(lenderBranches, borrowerDetails.loan.loanPortfolioNo)}
              onSubmit={handleSubmitPortfolio}
              options={options}
            />
          } />}
          {isSsn ? ssnValue : (<LoanDetailsRow
            attribute="Date of Birth"
            value={
              borrowerDetails.linkAttempt.attemptDob
                ? dateWithSlashes(borrowerDetails.linkAttempt.attemptDob)
                : 'N/A'
            }
          />)}
          
          <LoanDetailsRow attribute="Days Past Due" value={borrowerDetails.loan.daysPastDue} />
          <LoanDetailsRow attribute="Next Promise Date" value={borrowerDetails.loan.nxtPtpDt} />
          <LoanDetailsRow
            attribute="Next Promise Amount"
            value={<MoneyFormat value={parseFloat(borrowerDetails.loan.nxtPtpAmt).toFixed(2)} />}
          />
          <LoanDetailsRow attribute="Payment Frequency" value={borrowerDetails.loan.pmtFrequencyLabel} />
          <LoanDetailsRow
            attribute="Regular Payment"
            value={<MoneyFormat value={parseFloat(borrowerDetails.loan.regularPmtAmt).toFixed(2)} />}
          />
          {!hidePayOffBalanceCap && (
            <LoanDetailsRow
              attribute="Payoff balance"
              value={<MoneyFormat value={parseFloat(borrowerDetails.loan.loanPayOffBalance).toFixed(2)} />}
            />
          )}

          <AccessControl perform={PERMISSIONS.BORROWERS.IMPERSONATE}>
            <Button
              className={classes.Button}
              size="small"
              onClick={() =>
                handleLogin(
                  borrowerDetails.linkAttempt.installAccountId,
                  borrowerDetails.loan.loanNo,
                )}
            >
              <Box display="flex" marginRight="0.5rem">
                <Icon height="13px" width="13px" name="person" />
              </Box>
              <Box fontSize="0.875rem" letterSpacing="0.54px" color="primary.500" fontWeight={500}>
                {`Login as ${borrowerDetails.linkAttempt.installAccount.instlFirstName} ${borrowerDetails.linkAttempt.installAccount.instlLastName}`}
              </Box>
            </Button>
          </AccessControl>
        </Box>
      </CardContent>
    </BasicCardWrapper>
  );
}
