import React from 'react';
import Form from 'components/Form/Form';
import { Box } from '@material-ui/core';
import BasicModalContent from 'components/BasicModal/BasicModalContent/BasicModalContent';
import { useCreateCollection, useEditCollection, useCollections } from 'services/collections/hooks';
import * as yup from 'yup';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useQueryCache } from 'react-query';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import AddNewCollectionFooter from '../AddNewCollectionFooter/AddNewCollectionFooter';
import CollectionDetailsForm from './CollectionDetailsForm/CollectionDetailsForm';
import { convertObjectToArray } from 'shared/utils';

const initialValues = {
  collectionCode: '',
  phoneNumber: ''
};

const validationSchema = yup.object({
  collectionCode: yup.string().required('Collection code is required'),
  phoneNumber: yup.string().min(10, 'Phone number is not valid').required('Phone number is required'),
});

export default function CollectionDetails({ collectionToEditId, onSuccess = () => {}, maxHeight }) {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const isEdit = !!collectionToEditId;
  const { data, isLoading: isCollectionDetailLoading } = useCollections();

  let newData = {
    collectionCode: '',
    phoneNumber: ''
  };
  if (data && data.result && collectionToEditId) {
    newData = convertObjectToArray(data.result);
    newData = newData.filter((prev) => {
      return prev.id === collectionToEditId;
    });
    newData = newData[0]
    if (newData) {
      newData = {
        collectionCodeId: collectionToEditId,
        collectionCode: newData.collectionCode,
        phoneNumber: newData.phoneNumber
      }
    }
  }

  const queryCache = useQueryCache();

  const [mutate] = useCreateCollection({
    onSuccess: response => {
      if (response?.result) {
        queryCache.invalidateQueries('collections');
        setAlertMessage('Collection created successfully!');
        onSuccess(response);
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });
  const [mutateEdit] = useEditCollection({
    onSuccess: response => {
      if (response?.result) {
        queryCache.invalidateQueries('collections');
        setAlertMessage('Collection updated successfully!');
        onSuccess();
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleOnSubmit = async values => {
    if (isEdit) {
      delete values.clientUserId;
      delete values.createdAt;
      delete values.updatedAt;
      await mutateEdit({...values});
      return;
    }
    await mutate({...values});
  };
  
  return (
    <Box>
      <Box flex={1}>
        {isCollectionDetailLoading ? (
          <SpinnerWrapped />
        ) : (
          <Form
            initialValues={newData || initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
          >
            <BasicModalContent>
              <CollectionDetailsForm maxHeight={maxHeight} />
              <AddNewCollectionFooter isEdit={isEdit} />
            </BasicModalContent>
          </Form>
        )}
      </Box>
    </Box>
  );
}
