import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import ChatSection from 'components/OldChat/ChatSection/ChatSection';
import MessagesSection from 'components/OldChat/MessagesSection/MessagesSection';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import PageHeader from 'components/PageHeader/PageHeader';
import { useQueryCache } from 'react-query';
import { useConversationsOldChat, useMessagesOldChat, useUpdateNewMessage } from 'services/client/hooks';
import { format, parseJSON } from 'date-fns/esm';
import { isToday } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

const useStyles = makeStyles(theme => ({
  messageSection: {
    height: ({ activeChat }) => (activeChat ? 'auto' : '63vh'),
    backgroundColor: 'white'
  }
}));

export default function OldChat({ activeChat }) {
  const classes = useStyles({ activeChat });
  const queryCache = useQueryCache();

  const [dataDisplayConversations, setDataDisplayConversations] = useState([]);
  const [dataDisplayGroupMessages, setDataDisplayGroupMessages] = useState([]);
  const [dataDisplayMessages, setDataDisplayMessages] = useState([]);
  const [dataAllDisplayMessages, setDataAllDisplayMessages] = useState([]);
  const [dataAllDisplayConversations, setDataAllDisplayConversations] = useState([]);
  const [currentMessagesPage, setCurrentMessagesPage] = useState(1);
  const [totalMessagesPage, setTotalMessagesPage] = useState(1);
  const [changingMessagesPage, setChangingMessagesPage] = useState(false);
  const [currentConversationsPage, setCurrentConversationsPage] = useState(1);
  const [totalConversationsPage, setTotalConversationsPage] = useState(1);
  const [updateConversations, setUpdateConversations] = useState(false);
  const [activeTab, setActiveTab] = useState([]);
  const [activeChatId, setActiveChatId] = useState(activeChat ?? false);
  const { data: dataConversation, isLoading: isLoadingConversation } = useConversationsOldChat(currentConversationsPage, 10, activeChat);
  const { data: dataMessages, isLoading: isLoadingMessages } = useMessagesOldChat(activeChatId, currentMessagesPage);
  const [ mutate ] = useUpdateNewMessage({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('get-new-message', {
          refetchInactive: true,
          refetchActive: true,
        });
        return;
      }
    },
    onError: () => {
    },
  });

  useEffect(() => {
    let tempArray = [];
    let dataArray = [];
    if (dataAllDisplayConversations.length > 0 && currentConversationsPage > 1) {
      tempArray = dataAllDisplayConversations;
      dataArray = activeTab;
    }

    if (dataConversation && dataConversation.data) {
        for (let i in dataConversation.data) {
            let _data = {};
            _data.id = dataConversation.data[i].installAccountId;
            _data.fullName = dataConversation.data[i].installAccount.instlFirstName + " " + dataConversation.data[i].installAccount.instlLastName;
            _data.accountNumber = '';
            if (dataConversation.data[i].installAccount.linkAttempts) {
              let accountNumbers = dataConversation.data[i].installAccount.linkAttempts.map(item => item.attemptLoanNo);
              _data.accountNumber = accountNumbers.join(", ");
            }
            _data.message = '';
            if (dataConversation.data[i].message) {
                _data.message = dataConversation.data[i].message;
            }
            _data.date = format(parseJSON(dataConversation.data[i].datetime.date), 'LLL dd, yyyy', {timezone: dataConversation.data[i].datetime.timezone});
            // Replace today label inside conversation for appropriate date
            const today = format(parseJSON(dataConversation.data[i].datetime.date), 'MM/dd/yyyy', {timezone: dataConversation.data[i].datetime.timezone});
            if (isToday(new Date(today))) {
              _data.date = 'Today'; 
            }
            _data.time = format(parseJSON(dataConversation.data[i].datetime.date), 'hh:mm aaa', {timezone: dataConversation.data[i].datetime.timezone});
            _data.unreadMsgCount = dataConversation.data[i].installAccount.unreadMsgCount ?? 0;

            let checkArray = false;
            tempArray = tempArray.map((item, i) => {
              if (item.id === _data.id) {
                checkArray = true;
                return Object.assign({}, item, _data);
              } else {
                return item;
              }
            });
            if (checkArray === false) {
              tempArray.push(_data);
              dataArray.push({id: _data.id, active: false});
            }
        }
        
        const activeIndex = getActiveId(activeChatId, dataArray);
        // Set first chat as active, if there are no chat selected
        if ((activeIndex === null && dataArray.length > 0) || (activeIndex && !dataArray[activeIndex])) {
          dataArray[0].active = true;
          setActiveChatId(tempArray[0].id);
        } else if (activeIndex && dataArray[activeIndex] && dataArray.length > 0) { // If there are new conversations, keep the previous active
          dataArray[activeIndex].active = true;
          setActiveChatId(tempArray[activeIndex].id);
        }
        setCurrentConversationsPage(dataConversation.currentPage);
        setTotalConversationsPage(dataConversation.totalPage);
      }
    
    setActiveTab(dataArray);
    setDataDisplayConversations(tempArray);
    setDataAllDisplayConversations(tempArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConversation]);

  useEffect(() => {
    let tempArray = [];
    if (dataAllDisplayMessages) {
      tempArray = dataAllDisplayMessages;
    }
    if (dataMessages && dataMessages.data) {
      for (let i in dataMessages.data) {
        let _data = {};
        _data.id = dataMessages.data[i].id;
        _data.accountId = dataMessages.data[i].installAccountId;
        _data.type = dataMessages.data[i].type;
        _data.message = '';
        if (dataMessages.data[i].message) {
            _data.message = dataMessages.data[i].message;
        }
        _data.username = dataMessages.data[i].clientUsers.userName ?? '';
        _data.date = format(parseJSON(dataMessages.data[i].datetime.date), 'MM/dd/yyyy', {timezone: dataMessages.data[i].datetime.timezone});
        _data.dateSort = format(parseJSON(dataMessages.data[i].datetime.date), 'yyyy/MM/dd', {timezone: dataMessages.data[i].datetime.timezone});
        _data.time = format(parseJSON(dataMessages.data[i].datetime.date), 'hh:mm aaa', {timezone: dataMessages.data[i].datetime.timezone});
        _data.timeSort = format(parseJSON(dataMessages.data[i].datetime.date), 'hh:mm:ss', {timezone: dataMessages.data[i].datetime.timezone});
        tempArray.push(_data);
      }
      // Sort by time
      tempArray = tempArray.sort(function(a, b) {
        return a.timeSort.localeCompare(b.timeSort)
      });
      // Sort by date
      tempArray = tempArray.sort(function(a, b) {
        return a.dateSort.localeCompare(b.dateSort)
      });
      // Remove duplicates
      tempArray = tempArray.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === tempArray.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });
      // Group results by date
      let groupedMessages = tempArray.reduce((prev, next) => {
        const { date } = next;
        prev[date] = prev[date] ?? [];
        prev[date].push(next);
        return prev;
      }, {});
      if (dataMessages.totalPage > 1 && dataMessages.currentPage < dataMessages.totalPage) {
        groupedMessages = Object.assign({'LOAD_MORE_MESSAGES': []}, groupedMessages);
      }
      // Replace today label inside group of messages for appropriate date
      const today = format(Date.now(), 'MM/dd/yyyy');
      if (isToday(new Date(today)) && groupedMessages[today]) {
        groupedMessages['Today'] = groupedMessages[today];
        delete groupedMessages[today]; 
      }
      const groupedKeys = Object.keys(groupedMessages);
      setDataAllDisplayMessages(tempArray);
      setDataDisplayGroupMessages(groupedKeys);
      setDataDisplayMessages(groupedMessages);
      setCurrentMessagesPage(dataMessages.currentPage);
      setTotalMessagesPage(dataMessages.totalPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMessages]);

  useEffect(() => {
    if (activeChat) {
      mutate({ installAccountId: activeChat });
    } else {
      mutate();
    }
  }, [activeChat, mutate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!activeChat && updateConversations === true && currentConversationsPage === 1) {
        queryCache.invalidateQueries('get-conversations-old-chat', {
          refetchInactive: false,
          refetchActive: true,
        });
      }
      if (currentMessagesPage === 1) {
        queryCache.invalidateQueries('get-messages-old-chat', {
          refetchInactive: false,
          refetchActive: true,
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat, updateConversations, currentConversationsPage, currentMessagesPage]);

  const setActive = (id) => {
    let indexArray = false;
    const list = activeTab.map((item, index) => {
      if (item.id === id) {
          indexArray = index;
          return {id: item.id, active: true};
        } else {
          return {id: item.id, active: false};
        }
    });
    resetCurrentMessagesPage();
    setActiveTab(list);
    if (dataDisplayConversations[indexArray]) {
      setActiveChatId(dataDisplayConversations[indexArray].id);
    } else {
      setActiveChatId(false);
    }
  };

  const getActiveId = (activeChatId, dataArray) => {
    if (dataArray.length === 0) {
      return null;
    }
    let active = null;
    dataArray.map((item, index) => {
      if (item.active === true || item.id === activeChatId) {
        active = index;
      }
      return item;
    });
    return active;
  };

  const updateCurrentMessagesPage = useCallback(
    (currentPage) => {
      if (currentPage < totalMessagesPage) {
        setCurrentMessagesPage(currentPage + 1);
        setChangingMessagesPage(true);
      }
    },
    [totalMessagesPage],
  );

  const resetCurrentMessagesPage = useCallback(
    () => {
      setDataAllDisplayMessages([]);
      setCurrentMessagesPage(1);
      setChangingMessagesPage(false);
    },
    [],
  );

  const updateCurrentConversationsPage = useCallback(
    (currentPage) => {
    if (currentPage < totalConversationsPage) {
      setCurrentConversationsPage(currentPage + 1);
    }
    },
    [totalConversationsPage],
  );

  const resetCurrentConversationsPage = useCallback(
    (page) => {
      if (page > 1) {
        setDataAllDisplayConversations([]);
        setCurrentConversationsPage(1);
      }
    },
    [],
  );

  let display = (
    <React.Fragment>
      <ChatSection
        dataDisplayConversations={dataDisplayConversations}
        activeTab={activeTab}
        setActive={setActive}
        updateConversations={setUpdateConversations}
        currentPage={currentConversationsPage}
        totalPage={totalConversationsPage}
        updatePage={updateCurrentConversationsPage}
        resetPage={resetCurrentConversationsPage}
      />
      <Grid className={classes.messageSection} item xs={12} sm={12} md={8} lg={8}>
        <MessagesSection
          dataDisplayMessages={dataDisplayMessages}
          dataDisplayGroupMessages={dataDisplayGroupMessages}
          activeChatId={activeChatId}
          isLoadingMessages={isLoadingMessages}
          currentPage={currentMessagesPage}
          totalPage={totalMessagesPage}
          updatePage={updateCurrentMessagesPage}
          resetPage={resetCurrentMessagesPage}
          changingPage={changingMessagesPage}
        />
      </Grid>
    </React.Fragment>
  );
  if (activeChat) {
    display = (
      <Grid className={classes.messageSection} item xs={12} >
        <MessagesSection
          dataDisplayMessages={dataDisplayMessages}
          dataDisplayGroupMessages={dataDisplayGroupMessages}
          activeChatId={activeChatId} 
          isLoadingMessages={isLoadingMessages}
          currentPage={currentMessagesPage}
          totalPage={totalMessagesPage}
          updatePage={updateCurrentMessagesPage}
          resetPage={resetCurrentMessagesPage}
          changingPage={changingMessagesPage}
          onlyMessages="true"
        />
      </Grid>
    );
  }

  return (
    <Box>
      {!activeChat && <PageHeader title="Messages" paddingTop="10px" />}
      {isLoadingConversation || isLoadingMessages ? (
        <SpinnerWrapped />
      ) : 
        <Grid alignItems="flex-start" container>
          {display}
        </Grid>}
    </Box>
  );
}
