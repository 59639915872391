import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30vh"
  },
  field: {
    width: "100%",
    height: '215px !important'
  },
}));

export default function PostCommentForm({ handleChange, comment }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <TextareaAutosize
            id="comment"
            rowsMax={20}
            value={comment}
            aria-label="maximum height"
            placeholder="Put your comment here..."
            onChange={handleChange}
            className={classes.field}
          />
        </Grid>
      </Grid>
    </div>
  )
}
