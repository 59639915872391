import React from 'react';
import { Grid } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FormattedTextField from 'components/BasicInputs/BasicTextField/FormattedTextField/FormattedTextField';
import { useClientDetailsContext } from 'services/client/context';

export default function DebitCardForm() {
  const { hideCvvInWebpop } = useClientDetailsContext();
  return (
    <>
      <Grid item xs={12}>
        <BasicTextField name="name" label="Name On Card" placeholder="John Doe" />
      </Grid>
      <Grid item xs={12}>
        <FormattedTextField
          name="cardNumber"
          label="Card Number"
          placeholder="4242 4242 4242 4242"
          formatProps={{
            format: '#### #### #### ####',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormattedTextField
          name="expiryDate"
          label="Expiration Date"
          formatProps={{
            format: '##/##',
            placeholder: 'MM/YY',
            mask: ['M', 'M', 'Y', 'Y'],
            isNumericString: false,
            maintainFormattedValue: true,
          }}
        />
      </Grid>
      {!hideCvvInWebpop && (
        <Grid item xs={4}>
          <FormattedTextField
            name="cvvNumber"
            label="CVC"
            placeholder="123"
            formatProps={{
              format: '####',
            }}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <BasicTextField 
          name="billingZip" 
          label="Billing Zip"
          minLength="5"
          maxLength="7"
        />
      </Grid>
    </>
  );
}
