import React from 'react';
import { Box } from '@material-ui/core';
import BasicUpload from 'components/BasicInputs/BasicUpload/BasicUpload';
import UploadNew from 'components/BasicInputs/BasicUpload/UploadNew/UploadNew';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import { useField } from 'formik';
import useImagesMixins from 'styles/images-mixins';
import SettingsSectionUpload from '../SettingsSectionUpload/SettingsSectionUpload';

export default function SettingsSectionUploadImage({
  title,
  description,
  name,
  accept = '.jpg,.jpeg,.png',
}) {
  const imagesStyles = useImagesMixins();
  const [field] = useField(name);

  const getPreviewImage = () => {
    if (typeof field.value === 'string') {
      return field.value;
    }
    if (field.value instanceof File) {
      return URL.createObjectURL(field.value);
    }
  };

  return (
    <SettingsSectionUpload
      title={title}
      description={description}
      label="Upload image"
      name={name}
      PreviewComponent={
        <FlexColumn height="100%" width="100%">
          <Box>
            <BasicUpload name={name} accept={accept}>
              <Box flex={1}>
                <Box
                  className={imagesStyles.objectFitContain}
                  component="img"
                  height="100%"
                  width="100%"
                  maxHeight="100px"
                  src={getPreviewImage()}
                />
              </Box>
            </BasicUpload>
          </Box>
          <FlexRow marginTop="auto" marginBottom="1rem" justifyContent="center">
            <Box lineHeight="1" marginRight="1rem">
              <UploadNew name={name} accept={accept} />
            </Box>
          </FlexRow>
        </FlexColumn>
      }
    />
  );
}
