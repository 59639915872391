import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useField } from 'formik';

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: ({ noMargin }) => (noMargin ? 0 : theme.spacing(1)),
  },
  switchBase: {
    padding: 1,
    backgroundColor: theme.palette.primary[200],
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary[400],
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.common.border}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: theme.palette.primary[200],
    boxShadow: '0px 0px 8px rgba(34, 47, 62, 0.06)',
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  delete props.noMargin;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    marginRight: ({ noLabel }) => (noLabel ? '0' : '1rem'),
    marginLeft: ({ noLabel }) => (noLabel ? '0' : '-11px'),
  },
  label: {
    fontSize: '0.625rem',
    letterSpacing: '0.55px',
    color: ({ checked }) => (checked ? theme.palette.primary[500] : 'rgba(124, 141, 160, 0.88)'),
    fontWeight: 600,
    '&.Mui-focused': {
      color: 'rgba(124, 141, 160, 0.88)', // to overwrite the default behaviour
    },
  },
}));

const BasicSwitch = ({ name, label, disabled, onChange = () => {}, ...props }) => {
  const [field, , helpers] = useField({ name });
  const handleChange = (e) => {
    onChange(e);
    helpers.setValue(e.target.checked);
  };
  const classes = useStyles({ noLabel: !label, checked: !!field.value });
  return (
    <FormControlLabel
      control={
        <StyledSwitch
          disableRipple
          name={name}
          {...props}
          {...field}
          checked={!!field.value}
          onChange={handleChange}
          disabled={disabled}
          noMargin={!label}
        />
      }
      label={label}
      classes={{ label: classes.label, root: classes.labelRoot }}
    />
  );
};

export default BasicSwitch;
