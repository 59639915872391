import React from 'react';
import { Card, makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    filter: 'drop-shadow(0px 0px 8px rgba(34, 47, 62, 0.06))',
    height: ({ height }) => height,
    flexDirection: 'column',
    display: 'flex',
    borderRadius: '8px',
    padding: ({ noPadding }) => (noPadding ? '0' : '1rem 0.875rem 0.5rem'),
    '& .MuiCardContent-root': {
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      marginBottom: 'auto',
      padding: 0,
    },
    boxShadow: 'none',
    overflow: ({ overflow }) => overflow,
    border: `1px solid ${theme.palette.common.borderLight}`,
    backgroundColor: ({ backgroundColor }) => (backgroundColor ? backgroundColor : ''),
  },
}));

export default function BasicCard({
  children,
  noPadding,
  className,
  height = '100%',
  backgroundColor,
  overflow = 'initial',
  ...rest
}) {
  const classes = useStyles({ noPadding, overflow, height, backgroundColor });
  return (
    <Box height="100%" {...rest}>
      <Card className={clsx(classes.card, className)}>{children}</Card>
    </Box>
  );
}
