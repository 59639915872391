import React from 'react';
import { useGroupDetail } from 'services/groups/hooks';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import Text from 'components/Text/Text';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useGroupsActiveTab } from '../GroupsTabActiveProvider/GroupsTabActiveProvider';

export default function GroupsTabActiveHeader() {
  const { groupsTabActive, groupsTabActiveName } = useGroupsActiveTab();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { data: groupDetailData } = useGroupDetail(
    groupsTabActive,
    {
      initialData: {
        result: {
          data: {
            groupName: groupsTabActiveName,
          },
        },
      },
      initialStale: true,
    },
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type) => {
    window.open(
      `${process.env.REACT_APP_ROOT_URL}/app/web/rule/export-groups?groupRuleId=${groupsTabActive}&type=${type}`,
    );
  };

  return (
    <Box
      marginBottom="1rem"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="2rem 2.5rem 0"
    >
      <Text fontSize="1.125rem" fontWeight={500} lineHeight="1.25rem" letterSpacing="-0.18px">
        {groupDetailData.result.data.groupName}
      </Text>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick('csv')}>Export to CSV</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('pdf')}>Export to PDF</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('xls')}>Export to Excel</MenuItem>
      </Menu>
    </Box>
  );
}
