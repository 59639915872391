import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import useImagesMixins from 'styles/images-mixins';
import { useField } from 'formik';
import FlexRow from 'components/FlexRow/FlexRow';
import UploadNew from 'components/BasicInputs/BasicUpload/UploadNew/UploadNew';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';
import Icon from 'components/Icon/Icon';
import clsx from 'clsx';
import SettingsSectionUpload from '../../Shared/SettingsSection/SettingsSectionUpload/SettingsSectionUpload';

export default function CardUploadForm() {
  const mixinStyles = useMixinStyles();
  const imagesStyles = useImagesMixins();
  const [field] = useField('cardUpload');

  const draw = (contentFile) => {
    const decoder = new TextDecoder("utf-8");
    const content = decoder.decode(contentFile);

    const canvas = document.getElementById('the-canvas');
    if (!canvas) return;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    
    const start = 20;
    const end = 20;
    const lines = content.split('\n');
    const lineHeight = 15;

    for (var j = 0; j < 10; j++) {
      if (!lines[j]) {
        continue
      }
      context.fillText(lines[j], start, end + (j * lineHeight));
    }

  };

  const getPreviewImage = useMemo(() => {
    let contentFile;
    if (field.value instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        const typedarray = new Uint8Array(this.result);
        draw(typedarray);
      };
      fileReader.readAsArrayBuffer(field.value);
    }

    return contentFile;
  }, [field.value]);

  return (
    <SettingsSectionUpload
      title="Card Upload"
      description="Upload Card here (.txt)"
      name="cardUpload"
      label="Upload Document"
      height="15rem"
      accept=".txt,.csv"
      buttonLabel="Upload"
      PreviewComponent={
        <FlexRow alignItems="flex-start" height="100%" width="100%">
          <Box height="100%" display="flex" marginRight="1.25rem" flexBasis="40%" maxWidth="40%">
            <Box display="flex" flex={1}>
              <Box
                padding="0.75rem 0.375rem"
                bgcolor="rgba(187, 195, 208, 0.1)"
                overflow="hidden"
                className={imagesStyles.objectFitContain}
                component="canvas"
                height="11rem"
                width="100%"
                id="the-canvas"
              />
            </Box>
          </Box>
          <FlexColumn flexBasis="60%" maxWidth="60%" alignItems="center">
            <Text
              className={mixinStyles.ellipsis}
              marginBottom="1.2rem"
              fontSize="1.125rem"
              fontWeight={500}
              lineHeight="1.125rem"
              letterSpacing="0.27px"
            >
              Card Upload
            </Text>
            <FlexRow justifyContent="center">
              <Box lineHeight="1" marginRight="1rem">
                <UploadNew name="cardUpload" accept=".txt,.csv"/>
              </Box>
              <Box
                component="a"
                href={getPreviewImage}
                download
                className={clsx(mixinStyles.clickable, mixinStyles.noTextDecoration)}
                display="flex"
                target="_blank"
              >
                <FlexRow color="#BBC3D0" marginRight="0.25rem">
                  <Icon width="0.75rem" height="0.75rem" name="download" />
                </FlexRow>
                <Text
                  color="#BBC3D0"
                  fontSize="0.75rem"
                  fontWeight={500}
                  lineHeight="0.875rem"
                  letterSpacing="0.3px"
                >
                  Download
                </Text>
              </Box>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      }
    />
  );
}
