import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import Text from 'components/Text/Text';
import { BasicDateField } from 'components/BasicInputs/BasicDateField/BasicDateField';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useFormikContext } from 'formik';
import { RULES_APPLY_TO } from 'shared/constants/rules';
import { isFuture } from 'date-fns';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import { dateWithSlashes } from 'shared/utils';
import { isToday } from 'date-fns/esm';
import RulesFormSection from './RulesFormSection/RulesFormSection';
import RulesFormApplyTo from './RulesFormApplyTo/RulesFormApplyTo';
import RulesFormPlatforms from './RulesFormPlatforms/RulesFormPlatforms';
import RulesFormLoansList from './RulesFormLoansList/RulesFormLoansList';
import RulesFormsGroups from './RulesFormsGroups/RulesFormsGroups';

const statusColors = {
  Active: {
    color: 'primary.500',
    bgcolor: 'primary.200',
  },
  'Pre-Active': {
    color: '#a9a45f',
    bgcolor: '#ffefa4',
  },
  Inactive: {
    color: 'error.500',
    bgcolor: 'error.100',
  },
};

export default function RulesForm({ title, platforms }) {
  const { values, resetForm, isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  const { activeTab } = useActiveTab();

  const ruleStatus = useMemo(() => {
    if ((isFuture(values.endDate) || isToday(values.endDate)) && !isFuture(values.startDate)) {
      return 'Active';
    }
    if (isFuture(values.startDate)) {
      return 'Pre-Active';
    }
    return 'Inactive';
  }, [values.startDate, values.endDate]);

  return (
    <Box padding="1.25rem 2rem">
      <Box display="flex" alignItems="center" marginBottom="1.5rem" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Text
            fontSize="1.25rem"
            textAlign="center"
            fontWeight={700}
            letterSpacing="-0.20px"
            color="textPrimary"
            marginRight="0.5rem"
          >
            {title}
          </Text>
          {values.offHide && (
            <Text
              fontSize="0.875rem"
              textAlign="center"
              fontWeight={700}
              letterSpacing="-0.20px"
              color={statusColors[ruleStatus].color}
              bgcolor={statusColors[ruleStatus].bgcolor}
              borderRadius="8px"
              padding="0.5rem"
            >
              {ruleStatus}
            </Text>
          )}
        </Box>
        <BasicSwitch key={`offHide-${activeTab}`} name="offHide" label="Enable" />
      </Box>
      <Box display="flex" flexDirection="column">
        <RulesFormSection title="Who does this rule apply to?">
          <RulesFormApplyTo name="applyTo" />
        </RulesFormSection>
        {Number(values.applyTo) === RULES_APPLY_TO.SPECIFIC_LOANS && (
          <RulesFormSection title="What loans should this rule be applied to?">
            <RulesFormLoansList />
          </RulesFormSection>
        )}
        {Number(values.applyTo) === RULES_APPLY_TO.GROUPS && (
          <RulesFormSection
            title="What groups should this rule be applied to?"
            inputName="allGroup"
          >
            <RulesFormsGroups />
          </RulesFormSection>
        )}
        <RulesFormSection title="Where should this rule be applied?" inputName="applyOn">
          <RulesFormPlatforms platforms={platforms} />
        </RulesFormSection>
        <RulesFormSection title="When does this rule apply?">
          <Box display="flex">
            <Box width="100%" maxWidth="200px" marginRight="1rem">
              <BasicDateField
                minDate={dateWithSlashes()}
                maxDate={values.endDate}
                disabled={!values.offHide}
                label="Start Date"
                name="startDate"
              />
            </Box>
            <Box width="100%" maxWidth="200px">
              <BasicDateField
                minDate={isFuture(values.startDate) ? values.startDate : dateWithSlashes()}
                disabled={!values.offHide}
                label="Stop Date"
                name="endDate"
              />
            </Box>
          </Box>
        </RulesFormSection>
        <Box alignSelf="flex-end" marginTop="2rem">
          <Grid spacing={2} container alignItems="center">
            <Grid item xs={6}>
              <ContainedButton
                size="md"
                onClick={resetForm}
                fullWidth
                variant="outlined"
                color="primary"
              >
                Reset
              </ContainedButton>
            </Grid>
            <Grid item xs={6}>
              <ContainedButton
                size="md"
                disabled={isFormInvalid}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                isLoading={isSubmitting}
              >
                Update
              </ContainedButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
