import { Box } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicTab from 'components/BasicTabs/BasicTab/BasicTab';
import BasicTabPanel from 'components/BasicTabs/BasicTabPanel/BasicTabPanel';
import BasicTabs from 'components/BasicTabs/BasicTabs';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import Form from 'components/Form/Form';
import React from 'react';
import { useChangePassword } from 'services/users/hooks';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import * as yup from 'yup';
import * as JSEncrypt from 'jsencrypt';
import { ENV } from '../../../../constants';
import ProfilePasswordForm from './ProfilePasswordForm/ProfilePasswordForm';

const PROFILE_SETTINGS_TABS = {
  SECURITY: 1,
};

const initialValues = {
  old_password: '',
  password: '',
  password_confirmation: '',
};

const validationSchema = yup.object({
  old_password: yup.string().required('Current password is required'),
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password Must contain at least one number. ')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
      'Password must contain at least one special character',
    ),
  password_confirmation: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function ProfilePassword() {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const [mutate] = useChangePassword({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage('Password changed successfully!');
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleSubmit = async (values, { resetForm }) => {
    const key = new JSEncrypt.JSEncrypt();
    key.setPublicKey(ENV.TOKEN);
    const oldPasswordEncrypted = key.encrypt(values.old_password);
    const passwordEncrypted = key.encrypt(values.password);
    const passwordConfirmationEncrypted = key.encrypt(values.password_confirmation);
    await mutate({
      old_password: oldPasswordEncrypted,
      password: passwordEncrypted,
      password_confirmation: passwordConfirmationEncrypted,
    });
    resetForm();
  };

  return (
    <BasicCard noPadding height="27.5rem">
      <BasicsTabsContextProvider initialActiveTab={PROFILE_SETTINGS_TABS.SECURITY}>
        <Box marginBottom="0.875rem">
          <BasicTabs>
            <Box display="flex" padding="0.5rem 2rem 0">
              <Box marginRight="3.5rem">
                <BasicTab size="lg" index={PROFILE_SETTINGS_TABS.SECURITY}>
                  SECURITY
                </BasicTab>
              </Box>
            </Box>
          </BasicTabs>
        </Box>
        <BasicTabPanel index={PROFILE_SETTINGS_TABS.SECURITY} height="auto" flex={1}>
          <Box height="100%" flex={1}>
            <Form
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={initialValues}
            >
              <ProfilePasswordForm />
            </Form>
          </Box>
        </BasicTabPanel>
      </BasicsTabsContextProvider>
    </BasicCard>
  );
}
