import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import { HuePicker, SketchPicker } from 'react-color';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { useField } from 'formik';
import SettingsSectionUpdate from '../../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';

const useStyles = makeStyles(() => ({
  colorPicker: {
    '& .sketch-picker': {
      padding: '0 !important',
      width: 'auto !important',
      borderRadius: '11px !important',
      overflow: 'hidden',
      '& > div:not(:first-child)': {
        display: 'none !important',
      },
    },
  },
  huePicker: {
    '& .hue-picker': {
      padding: '0 !important',
      width: 'auto !important',
      height: '10px !important',
    },
    '& .hue-horizontal': {
      borderRadius: '14px !important',
    },
  },
}));

export default function AccentColorForm() {
  const classes = useStyles();
  const [field, , helpers] = useField('accentColor');

  const handleOnColorChange = (color) => {
    helpers.setValue(color.hex);
  };

  return (
    <FlexRow alignItems="flex-start" height="15rem">
      <Box marginRight="2rem" className={classes.colorPicker} flexBasis="50%" maxWidth="50%">
        <Box marginBottom="0.75rem">
          <SketchPicker onChangeComplete={handleOnColorChange} color={field.value} />
        </Box>
        <Box className={classes.huePicker} width="100%" position="relative">
          <HuePicker onChangeComplete={handleOnColorChange} color={field.value} />
        </Box>
      </Box>
      <FlexColumn flexBasis="50%" maxWidth="50%">
        <Box marginBottom="5.5rem">
          <BasicTextField
            name="accentColor"
            startIcon={<Box height="1.5rem" width="1.5rem" bgcolor={field.value} />}
          />
        </Box>
        <SettingsSectionUpdate />
      </FlexColumn>
    </FlexRow>
  );
}
