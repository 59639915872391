import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Link, useLocation } from 'react-router-dom';
import ActiveBadge from 'components/ActiveBadge/ActiveBadge';
import { Box } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import useMixinStyles from 'styles/mixins';
import { useStyles } from '../../assets/css/style';

function SecondNavBar({ counts, justifyContent }) {
  const mixinStyles = useMixinStyles();
  const classes = useStyles({
    justifyContent,
  });
  const location = useLocation();

  return (
    <Box marginBottom="2rem">
      <AppBar position="static" className={classes.SecAppbar}>
        <Toolbar className={mixinStyles.scrollBar}>
          <FlexRow width="100%">
            {counts &&
              counts.map((count) => {
                const id = count.name.replace(/\s+/g, '-').toLowerCase();
                return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.SecGrid}
                    key={count.name}
                  >
                    <Link
                      to={count.path}
                      className={classes.SecMenuButton}
                      aria-label="naked"
                      color="inherit"
                    >
                      {(count.value > 0) ? 
                        (<ActiveBadge
                          active={count.path === location.pathname}
                          badgeContent={count.value}
                          id={id}
                          color="secondary"
                        >
                          {count.name}
                        </ActiveBadge>) : count.name}
                    </Link>
                    <Divider orientation="vertical" className={classes.verticalDiv} flexItem />
                  </Grid>
                );
              })}
          </FlexRow>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SecondNavBar;
