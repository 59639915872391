import React from 'react';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import Icon from 'components/Icon/Icon';
import useDisclosure from 'shared/hooks/useDisclosure';
import AddNewCollectionModal from 'components/AddNewCollectionModal/AddNewCollectionModal';

export default function AddNewCollection() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <ContainedButton onClick={onOpen} size="md" startIcon={<Icon name="add" />}>
        New Collection
      </ContainedButton>
      <AddNewCollectionModal isOpen={isOpen} onClose={onClose} onSuccess={onClose} />
    </>
  );
}
