import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { RULES_APPLY_TO } from 'shared/constants/rules';
import RulesFormApplyToOption from './RulesFormApplyToOption/RulesFormApplyToOption';

const RULES_APPLY_TO_OPTIONS = [
  {
    value: RULES_APPLY_TO.ALL_LOANS,
    text: 'All Loans',
  },
  {
    value: RULES_APPLY_TO.SPECIFIC_LOANS,
    text: 'Specific Loans',
  },
  {
    value: RULES_APPLY_TO.GROUPS,
    text: 'Groups',
  },
];

export default function RulesFormApplyTo({ name }) {
  const [field, , helpers] = useField({ name });
  const { values } = useFormikContext();

  const handleApplyToOptionClick = value => {
    helpers.setValue(value);
  };
  return (
    <Box width="100%" maxWidth="400px">
      <Grid container spacing={2} alignItems="center">
        {RULES_APPLY_TO_OPTIONS.map(applyToOption => (
          <Grid key={applyToOption.value} item xs={4}>
            <RulesFormApplyToOption
              disabled={!values.offHide}
              onClick={() => handleApplyToOptionClick(applyToOption.value)}
              selected={Number(field.value) === applyToOption.value}
            >
              {applyToOption.text}
            </RulesFormApplyToOption>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
