import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useDisclosure from 'shared/hooks/useDisclosure';
import { useDeleteCollection } from 'services/collections/hooks';
import AddNewCollectionModal from 'components/AddNewCollectionModal/AddNewCollectionModal';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';
import { makeStyles, Box, Grid } from '@material-ui/core';
import Icon from 'components/Icon/Icon';

const useStyles = makeStyles((theme) => ({
  cardContainerHover: {
    '&:hover': {
      backgroundColor: ({ isActive }) => !isActive && theme.palette.grey[100],
      transition: 'background-color 0.2s, border-color 0.2s',
    },
  },
  cardContainerActive: {
    borderColor: theme.palette.primary[500],
  },
  cardActive: {
    color: theme.palette.primary[500],
  },
  editButton: {
    color: '#7C8DA0',
    '&:hover': {
      color: '#008949',
    },
  },
  deleteButton: {
    color: '#7C8DA0',
    '&:hover': {
      color: '#EB4D4B',
    },
  },
}));

export default function CollectionListItem({ data }) {
  const classes = useStyles();
  const mixinStyles = useMixinStyles();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
  const [mutate, { isLoading }] = useDeleteCollection({
    onMutate: () => {
      onClose();
    },
  });

  const handleRemoveCollection = () => {
    mutate({
      collectionCodeId: data.id,
    });
  };

  const handleOnEditClick = (e) => {
    e.stopPropagation();
    onEditOpen();
  };

  const handleOnDeleteClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <BasicCard
        className={clsx(
          mixinStyles.clickable,
          classes.cardContainerHover
        )}
        noPadding
      >
        <BasicCardWrapper>
          <BasicCardContent>
            <Box width="80%">
              <Grid alignItems="flex-start" container>
                <Grid container alignItems="center" item xs={10}>
                  <Box>
                    <BasicCardSubtitle>
                      Collection code: {data.collectionCode}
                    </BasicCardSubtitle>
                    <BasicCardSubtitle>
                      Phone number: {data.phoneNumber}
                    </BasicCardSubtitle>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box height="35px" display="flex" alignItems="center" justifyContent="right">
                    <Box
                      onClick={handleOnEditClick}
                      role="button"
                      display="flex"
                      marginRight="10px"
                      className={clsx(classes.editButton, mixinStyles.clickable)}
                    >
                      <Icon name="pen" />
                    </Box>
                    <Box
                      onClick={handleOnDeleteClick}
                      role="button"
                      display="flex"
                      className={clsx(classes.deleteButton, mixinStyles.clickable)}
                    >
                      <Icon name="deleteAlt" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </BasicCardContent>
        </BasicCardWrapper>
      </BasicCard>
      <ConfirmationDialog
        title="Are you sure you want to delete this collection?"
        description=""
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleRemoveCollection}
        loading={isLoading}
      />
      {isEditOpen && (
        <AddNewCollectionModal isOpen={isEditOpen} onClose={onEditClose} onSuccess={onEditClose} collectionToEditId={data.id} />
      )}
    </>
  );
}
