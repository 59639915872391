import React from 'react';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BasicTable from 'components/BasicTable/BasicTable';
import BasicButton from 'components/BasicButton/BasicButton';
import AddBorrower from 'pages/Borrowers/LinkedFailed/AddBorrower/AddBorrower';
import { useStyles } from 'containers/borrowers/borrowerStyles';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Icon from 'components/Icon/Icon';
import { Box } from '@material-ui/core';
import FlexRow from 'components/FlexRow/FlexRow';
import useLinkedFailedTiles from '../useLinkedFailedTiles';

const columns = [
  { id: 'loanNumber', label: 'Account Number', minWidth: 100, align: 'left' },
  {
    id: 'user',
    label: 'User',
    minWidth: 100,
    format: (value) => value.toLocaleString('en-US'),
    align: 'left',
  },
  { id: 'zipCode', label: 'Zip Code', minWidth: 100, align: 'left' },
  { id: 'DOB', label: 'DOB', minWidth: 100, align: 'left' },
  { id: 'primaryEmail', label: 'Primary Email', minWidth: 100, align: 'left' },
  { id: 'actions', label: 'Actions', minWidth: 100, align: 'left' },
];

function createData(loanNumber, user, zipCode, DOB, primaryEmail, actions) {
  return { loanNumber, user, zipCode, DOB, primaryEmail, actions };
}

export default function LinkedFailedTable({ data }) {
  const { onOpen, isOpen, onClose, handleOnDeleteFailedLink, isLoading } = useLinkedFailedTiles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const addBorrower = () => {
    setOpen(true);
  };

  const change = () => {
    setOpen(!open);
  };

  const rows = data.map((data) => {
    const array = createData(
      data.attemptLoanNo ? data.attemptLoanNo : 'N/A',
      data.installAccount.instlFirstName
        ? `${data.installAccount.instlFirstName} ${data.installAccount.instlLastName}`
        : 'N/A',
      data.attemptZipCd ? data.attemptZipCd : 'N/A',
      data.attemptDob ? data.attemptDob : 'N/A',
      data.installAccount.installPrimaryEmailAddress
        ? data.installAccount.installPrimaryEmailAddress.emailAddrText
        : 'N/A',
      <>
        <FlexRow>
          <Box marginRight="0.5rem">
            <BasicButton onClick={addBorrower}>
              <ControlPointIcon fontSize="small" className={classes.cardIcons} />
              Add Borrower
            </BasicButton>
          </Box>
          <BasicButton variant="error" onClick={onOpen}>
            <Box marginRight="0.25rem">
              <Icon height={20} width={20} name="delete" />
            </Box>
            Delete
          </BasicButton>
        </FlexRow>
        <ConfirmationDialog
          title="Delete failed link"
          description="Are you sure you want to delete this failed linked loan?"
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => handleOnDeleteFailedLink(data.id)}
          loading={isLoading}
        />
        {open && (
          <AddBorrower
            open={open}
            change={change}
            loanNo={data.attemptLoanNo}
            loanId={data.id}
            id={data.id}
            zipCode={data.attemptZipCd ?? ''}
            dob={data.attemptDob}
            ssn={data.attemptTin}
            ssn4={data.attemptTinFour}
            phoneNo={data.attemptPhoneNo}
            branchNo={data.attemptBranchNo}
          />
        )}
      </>,
    );
    return array;
  });

  return <BasicTable rows={rows} columns={columns} />;
}
