import React, { useMemo, useContext, createContext, useState, useCallback } from 'react';
import ROLES_PERMISSIONS from 'shared/constants/roles';
import {
  getStoredLoggedInUser,
  removeStoredAuthToken,
  removeStoredLoggedInUser,
} from 'shared/utils/authToken';

const CurrentUser = createContext();
export function CurrentUserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(getStoredLoggedInUser());
  const logout = useCallback(() => {
    removeStoredAuthToken();
    removeStoredLoggedInUser();
    setCurrentUser(null);
  }, []);

  const canUserAccessTo = useCallback(
    (action, data) => {
      const permissions = ROLES_PERMISSIONS[currentUser.role];
      if (!permissions) {
        return false;
      }

      const staticPermissions = permissions.static;
      if (staticPermissions && staticPermissions.includes(action)) {
        return true;
      }

      const dynamicPermissions = permissions.dynamic;

      if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
          // dynamic rule not provided for action
          return false;
        }

        return permissionCondition(data);
      }
      return false;
    },
    [currentUser],
  );

  const value = useMemo(() => ({ currentUser, setCurrentUser, canUserAccessTo, logout }), [
    currentUser,
    canUserAccessTo,
    logout
  ]);

  return <CurrentUser.Provider value={value}>{children}</CurrentUser.Provider>;
}

export function useCurrentUser() {
  const context = useContext(CurrentUser);
  if (!context) {
    throw new Error(`useCurrentUser must be used within a CurrentUserProvider`);
  }
  return context;
}
