import React, { useEffect, useRef, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import IconButton from 'components/Icon/IconButton/IconButton';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useDeleteMessage } from 'services/client/hooks';
import { useQueryCache } from 'react-query';
import { makeStyles } from '@material-ui/core';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

const useStyles = makeStyles(theme => ({
  chatSection: {
    height: '63vh',
    overflowY: 'scroll'
  },
  activeChatSelection: {
    borderColor: theme.palette.primary[500]
  },
  noActiveChatSelection: {
    '&:hover': {
      borderColor: theme.palette.primary[300]
   },
  },
  unreadMsgChat: {
    fontWeight: 'bold',
    fontSize: '13px'
  },
  gridChatSection: {
    width: '98%'
  },
  removeSection: {
    display: 'flex',
    justifyContent: 'end'
  },
  messageContent: {
    width: '95%'
  },
  dateTimeContent: {
    width: '95%'
  }
}));

export default function ChatSection({
  dataDisplayConversations, 
  activeTab, 
  setActive,
  currentPage,
  totalPage,
  updatePage,
  resetPage,
  updateConversations
}) {
  const classes = useStyles();
  const chatRef = useRef(null);

  const { setErrorAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const [ mutate ] = useDeleteMessage({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('get-conversations-old-chat', {
          refetchInactive: true,
          refetchActive: true,
        });
        resetPage(currentPage);
        return;
      }
    },
    onError: () => {
      setErrorAlertMessage('There was a problem when hiding the conversation');
    },
  });

  const removeConversation = (id) => {
    mutate({receiverId: id});
  };

  const handleScrollConversations = useCallback(
    () => {
      if ((chatRef.current.scrollHeight - chatRef.current.scrollTop) === chatRef.current.clientHeight) {
        updatePage(currentPage);
      } else if (chatRef.current.scrollTop === 0) {
        resetPage(currentPage);
        updateConversations(true);
      } else {
        updateConversations(false);
      }
    },
    [chatRef, currentPage, updatePage, resetPage, updateConversations],
  );

  useEffect(() => {
    const ref = chatRef.current;
    if (ref) {
      updateConversations(true);
      ref.addEventListener('scroll', handleScrollConversations);
      return () => {
          ref.removeEventListener('scroll', handleScrollConversations);
      };
    }
  }, [handleScrollConversations, updateConversations]);

  

  return (
    <Grid className={classes.chatSection} container alignItems="center" item xs={12} sm={12} md={4} lg={4} ref={chatRef}>
      <Box className={classes.gridChatSection}>
        {dataDisplayConversations && dataDisplayConversations.map((item, index) => {
            return (
            <BasicCard className={(activeTab[index] && activeTab[index].active) ? classes.activeChatSelection : classes.noActiveChatSelection} onClick={() => setActive(item.id)} key={item.id}>
                <BasicCardContent>
                  <Grid alignItems="flex-start" container>
                      <Grid container alignItems="center" item xs={9}>
                        <Box>
                            <BasicCardTitle className={(item.unreadMsgCount > 0) ? classes.unreadMsgChat : ''}>
                              {item.fullName}
                            </BasicCardTitle>
                            <BasicCardTitle>
                              {item.accountNumber}
                            </BasicCardTitle>
                            <BasicCardTitle className={classes.messageContent} opacity="0.5">
                              {item.message}
                            </BasicCardTitle>
                        </Box>
                      </Grid>
                      <Grid container alignItems="center" item xs={3}>
                        <Box className={classes.dateTimeContent}>
                            <BasicCardTitle>
                              {item.date}
                            </BasicCardTitle>
                            <BasicCardTitle>
                              {item.time}
                            </BasicCardTitle>
                            <BasicCardTitle className={classes.removeSection}>
                              <Box right="0.5rem">
                                <IconButton
                                  color="#00a757"
                                  onClick={() => removeConversation(item.id)}
                                />
                              </Box>
                            </BasicCardTitle>
                        </Box>
                      </Grid>
                  </Grid>     
                </BasicCardContent>
            </BasicCard>
            )
        })}
      </Box>
    </Grid>
  );
}
