import React from 'react';
import { Grid, Box } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useFormikContext } from 'formik';

export default function WebPopActions({
  primaryButtonText = 'Make Payment',
  secondaryButtonText = 'Cancel',
  onPrimaryButtonClick = () => {},
  onSecondaryButtonClick = () => {},
  isSubmit,
  hideSecondaryButton,
  disabled,
}) {
  const { isSubmitting } = useFormikContext();
  return (
    <Box marginTop="auto">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {!hideSecondaryButton && (
            <ContainedButton
              onClick={onSecondaryButtonClick}
              fullWidth
              variant="outlined"
              color="primary"
            >
              {secondaryButtonText}
            </ContainedButton>
          )}
        </Grid>
        <Grid item xs={6}>
          <ContainedButton
            disabled={disabled}
            fullWidth
            isLoading={isSubmitting}
            type={isSubmit ? 'submit' : 'button'}
            onClick={onPrimaryButtonClick}
            variant="contained"
            color="primary"
          >
            {primaryButtonText}
          </ContainedButton>
        </Grid>
      </Grid>
    </Box>
  );
}
