import { usePagination } from 'components/Pagination/PaginationProvider/PaginationProvider';
import { usePaginatedQuery, useMutation, useQueryCache } from 'react-query';
import { useSearchBar } from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import produce from 'immer';
import {
  getCollections,
  createCollection,
  deleteCollection,
  updateCollection,
} from './api';
import { convertObjectToArray } from 'shared/utils';

export function useCollections() {
  const { page } = usePagination();
  const { searchString } = useSearchBar();
  return usePaginatedQuery(['collections', { page, searchString }], getCollections, {
    staleTime: 0,
  });
}

export const useCreateCollection = (options = {}) => {
  return useMutation(createCollection, {
    ...options,
  });
};

export const useDeleteCollection = (options = {}) => {
  const { setAlertMessage } = useAlertContext();
  const { page } = usePagination();
  const { searchString } = useSearchBar();
  const queryCache = useQueryCache();
  return useMutation(deleteCollection, {
    ...options,
    onMutate: ({ collectionCodeId }) => {
      
      const previousItems = queryCache.getQueryData(['collections', { page, searchString }]);
      
      queryCache.setQueryData(['collections', { page, searchString }], (old) => {
        const updatedCollections = produce(old, (draft) => {
          
          draft.result = convertObjectToArray(draft.result);
          draft.result = draft.result.filter((prevCollection) => {
            return prevCollection.id !== collectionCodeId;
          });
        });
        return updatedCollections;
      });
      setAlertMessage('Collection deleted successfully!');
      if (options.onMutate) {
        options.onMutate();
      }
      return () => queryCache.setQueryData(['collections', { page, searchString }], previousItems);
    },
    onError: (err, collectionRemoved, rollback) => {
    },
    onSettled: () => {
      queryCache.invalidateQueries('collections');
    },
  });
};
export const useEditCollection = (options = {}) => {
  return useMutation(updateCollection, {
    ...options,
  });
};
