import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import MessageIcon from '@material-ui/icons/Message';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Container from 'components/Container/Container';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import logo from 'assets/img/white-label-navbar.svg';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import FlexRow from 'components/FlexRow/FlexRow';
import { useClientDetailsContext } from 'services/client/context';
import { useStyles } from '../../assets/css/style';
import NavigationUser from './NavigationUser/NavigationUser';
import ActiveBadge from 'components/ActiveBadge/ActiveBadge';
import { useCheckNewMessage } from 'services/client/hooks';
import { useQueryCache } from 'react-query';
import { useLocation } from 'react-router-dom';

function MainNavBar({ messagesCount, setMessagesCount }) {
  const classes = useStyles();
  const queryCache = useQueryCache();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { currentUser } = useCurrentUser();
  const { hideChat, enablePms, capCustomizationSettings } = useClientDetailsContext();
  const { data } = useCheckNewMessage({location: location, hideChat: hideChat, enablePms: enablePms});
  const history = useHistory();

  useEffect(() => {
    if (data?.result?.isNew) {
      setMessagesCount(parseInt(data.result.isNew));
    } else {
      setMessagesCount(0);
    }
  }, [data, setMessagesCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      queryCache.invalidateQueries('get-new-message', {
        refetchInactive: false,
        refetchActive: true,
      });
    }, 5000);
    return () => clearInterval(interval);
  },[queryCache]);

  const mobileMenu = (text) => {
    let path = '/';
    switch (text) {
      case 'Borrowers':
        path = '/borrowers/signed-up';
        break;

      case 'Messages':
        path = '/messages';
        break;

      case 'App Rules':
        path = '/app-rules/payment';
        break;

      case 'Groups':
        path = '/groups';
        break;

      case 'Settings':
        path = '/settings';
        break;

      default:
        break;
    }
    setOpen(false);
    history.push(path);
  };

  const handleMobileMenu = () => {
    setOpen(!open);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Drawer
      className={classes.MainNavDrawer}
      variant="persistent"
      anchor="top"
      open={open}
      classes={{
        paper: classes.MainNavDrawerPaper,
      }}
    >
      <List>
        {['Borrowers', 'Messages', 'App Rules', 'Groups', 'Settings'].map((text, index) => (
          <div id={text} key={text}>
            <ListItem onClick={() => mobileMenu(text)} button>
              <ListItemIcon>
                {index === 0 ? (
                  <PersonIcon className={classes.MainNavIcons} />
                ) : index === 1 ? (
                  <MessageIcon className={classes.MainNavIcons} />
                ) : index === 2 ? (
                  <MenuBookIcon className={classes.MainNavIcons} />
                ) : index === 3 ? (
                  <PeopleIcon className={classes.MainNavIcons} />
                ) : (
                  <SettingsIcon className={classes.MainNavIcons} />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      {currentUser ? (
        <AppBar position="static" className={classes.appbar}>
          <Container height="100%" display="flex">
            <Box display="flex" flex={1} justifyContent="space-between">
              <Box flex={1} display="flex" alignItems="center">
                <Typography variant="h6" className={classes.MainNavTitle}>
                  <Link to="/borrowers/signed-up">
                    <Box component="img" height="1.75rem" src={capCustomizationSettings?.imageNavbarLogo || logo} className="img img-responsive" alt="Payix logo"  />
                  </Link>
                </Typography>
              </Box>
              <div className={classes.MainNavDesktop}>
                <FlexRow width="100%" justifyContent="space-between">
                  <Link
                    to="/borrowers/signed-up"
                    color="inherit"
                    className={classes.MainNavMenuButton}
                    id="borrowers"
                  >
                    <Box display="flex" marginRight="0.5rem">
                      <Icon name="personFilled" />
                    </Box>
                    borrowers
                  </Link>
                  {!hideChat && (
                    <AccessControl perform={PERMISSIONS.CHAT.USE}>
                      <Link
                        to="/messages"
                        color="inherit"
                        className={classes.MainNavMenuButton}
                        id="messages"
                      >
                        <Box display="flex" marginRight="0.5rem">
                          <Icon name="messages" />
                        </Box>
                        {(messagesCount > 0) ? 
                          <ActiveBadge
                            active={false}
                            badgeContent={messagesCount}
                            id="messages"
                            color="primary"
                            textColor="white"
                            badgeColor="rgb(0, 137, 73)"
                          >
                            messages
                          </ActiveBadge> : "Messages"
                        }  
                      </Link>
                    </AccessControl>
                  )}
                  <AccessControl perform={PERMISSIONS.RULES.VISIT}>
                    <Link
                      to="/app-rules/payment"
                      color="inherit"
                      className={classes.MainNavMenuButton}
                      id="app-rules"
                    >
                      <Box display="flex" marginRight="0.5rem">
                        <Icon name="file" />
                      </Box>
                      App Rules
                    </Link>
                  </AccessControl>
                  <AccessControl perform={PERMISSIONS.GROUPS.VISIT}>
                    <Link
                      to="/groups"
                      color="inherit"
                      className={classes.MainNavMenuButton}
                      id="groups"
                    >
                      <Box display="flex" marginRight="0.5rem">
                        <Icon name="people" />
                      </Box>
                      Groups
                    </Link>
                  </AccessControl>
                  <AccessControl perform={PERMISSIONS.SETTINGS.VISIT}>
                    <Link
                      to="/settings"
                      color="inherit"
                      className={classes.MainNavMenuButton}
                      id="settings"
                    >
                      <Box display="flex" marginRight="0.5rem">
                        <Icon name="settings" />
                      </Box>
                      settings
                    </Link>
                  </AccessControl>
                  <NavigationUser />
                </FlexRow>
              </div>
              {/* <Link
                to="/"
                color="inherit"
                className={`${classes.MainNavMenuButton} ${classes.MainNavLogout}`}
                onClick={logout}
              >
                <PowerSettingsNewIcon className={classes.MainNavIcons} />
              </Link> */}
              <div className={classes.MainNavMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Box>
          </Container>
        </AppBar>
      ) : (
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <Typography variant="h6" className={classes.MainNavTitle}>
              <img src={capCustomizationSettings?.imageNavbarLogo || logo} className="img img-responsive" alt="Payix logo" />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {currentUser ? renderMobileMenu : null}
    </div>
  );
}

export default MainNavBar;
