import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PAYMENT_TYPES } from 'shared/constants/payments';
import WebPopActions from 'components/WebPop/WebPopActions/WebPopActions';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import { useWebPopSwitch } from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import { useClientDetailsContext } from 'services/client/context';
import { BOOLEAN_NUMBERS_STRING } from 'shared/constants/common';
import AddPaymentMethodTypes from '../AddPaymentMethodTypes/AddPaymentMethodTypes';
import DebitCardForm from './DebitCardForm/DebitCardForm';
import ACHForm from './ACHForm/ACHForm';
import PADForm from './PADForm/PADForm';

export default function AddPaymentMethodForm() {
  const {
    enableDeletePaymentMethodsAfterUseAch,
    enableDeletePaymentMethodsAfterUseCard,
  } = useClientDetailsContext();
  const { values, resetForm, setFieldValue } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  const { onClose } = useWebPopSwitch();

  useEffect(() => {
    if (values.paymentMethodType) {
      resetForm();
      setFieldValue('paymentMethodType', values.paymentMethodType);
    }
  }, [values.paymentMethodType, resetForm, setFieldValue]);

  const shouldDisplayDoNotSavePaymentMethodsCheckbox = useMemo(() => {
    if (
      (values.paymentMethodType === PAYMENT_TYPES.CREDIT_CARD &&
        enableDeletePaymentMethodsAfterUseCard === BOOLEAN_NUMBERS_STRING.TRUE) ||
      (values.paymentMethodType === PAYMENT_TYPES.ACH &&
        enableDeletePaymentMethodsAfterUseAch === BOOLEAN_NUMBERS_STRING.TRUE)
    ) {
      return true;
    }
    return false;
  }, [
    values.paymentMethodType,
    enableDeletePaymentMethodsAfterUseAch,
    enableDeletePaymentMethodsAfterUseCard,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <AddPaymentMethodTypes name="paymentMethodType" />
        </Grid>
        {values.paymentMethodType === PAYMENT_TYPES.CREDIT_CARD && <DebitCardForm />}
        {values.paymentMethodType === PAYMENT_TYPES.ACH && <ACHForm />}
        {values.paymentMethodType === PAYMENT_TYPES.PAD && <PADForm />}
        {shouldDisplayDoNotSavePaymentMethodsCheckbox && (
          <Grid item xs={12}>
            <BasicCheckbox name="doNotSavePaymentMethods" label="Do not save payment method" />
          </Grid>
        )}
      </Grid>
      <WebPopActions
        disabled={isFormInvalid}
        onSecondaryButtonClick={onClose}
        isSubmit
        primaryButtonText="Add Method"
      />
    </>
  );
}
