import React from 'react';
import BorrowersEmptyState from 'assets/img/borrowers-empty-state.svg';
import GroupsEmptyState from 'assets/img/empty-state-groups.svg';
import PaymentsEmptyState from 'assets/img/empty-state-payments.svg';
import ActiveGroupEmptyState from 'assets/img/empty-state-no-group-selected.svg';
import NACHAEmptyState from 'assets/img/nacha-empty-state.svg';
import { Box } from '@material-ui/core';
import Text from 'components/Text/Text';

const EMPTY_STATE_IMAGES = {
  default: {
    image: BorrowersEmptyState,
    title: 'No records found',
  },
  payments: {
    image: PaymentsEmptyState,
    title: 'No payments yet',
    description: 'Create a payment history using the buttons of your left ',
  },
  schedulePayments: {
    image: PaymentsEmptyState,
    title: 'No scheduled payments yet',
    description: 'Create a payment history using the buttons of your left ',
  },
  groups: {
    image: GroupsEmptyState,
    title: 'No groups yet',
    description: 'Create new groups using the left button',
  },
  collections: {
    image: GroupsEmptyState,
    title: 'No collections yet',
    description: 'Create new collections using the left button',
  },
  activeGroup: {
    image: ActiveGroupEmptyState,
    title: 'You don’t have a group selected',
    description: 'Choose one from your existing groups, or start a new one.',
  },
  nachaFiles: {
    image: NACHAEmptyState,
    title: 'No NACHA Files',
  },
};

export default function EmptyState({ variant = 'default' }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box component="img" src={EMPTY_STATE_IMAGES[variant]?.image} />
      <Text
        fontSize="1.125rem"
        textAlign="center"
        fontWeight={500}
        lineHeight="0.875rem"
        letterSpacing="0.6px"
        marginBottom="1.25rem"
      >
        {EMPTY_STATE_IMAGES[variant]?.title}
      </Text>
      {EMPTY_STATE_IMAGES[variant]?.description && (
        <Text
          maxWidth="13.75rem"
          fontSize="0.875rem"
          textAlign="center"
          lineHeight="0.875rem"
          letterSpacing="0.025em"
        >
          {EMPTY_STATE_IMAGES[variant]?.description}
        </Text>
      )}
    </Box>
  );
}
