import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BasicDateField } from 'components/BasicInputs/BasicDateField/BasicDateField';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import { useAddBorrower } from 'services/borrowers/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { dateWithSlashes } from 'shared/utils';
import FormattedTextField from 'components/BasicInputs/BasicTextField/FormattedTextField/FormattedTextField';
import { useQueryCache } from 'react-query';
import { useClientDetailsContext } from 'services/client/context';
import { useStyles } from '../../../../containers/borrowers/borrowerStyles';

const validation = Yup.object({
  loanNumber: Yup.string().required('Required'),
  zip: Yup.string().required('Required')
        .min(5, 'Zip code is invalid'),
});

export default function AddBorrower({
  open,
  change,
  loanNo,
  loanId,
  id,
  zipCode,
  dob,
  ssn,
  ssn4,
  branchNo,
  phoneNo,
  name,
}) {
  const classes = useStyles();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const { isSsn, linkField1Type, linkField2Type, linkField3Type } = useClientDetailsContext();

  const [mutate, { isLoading }] = useAddBorrower({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('failed-notify-lenders');
        queryCache.invalidateQueries('app-details');
        setAlertMessage('Borrower linked successfully!');
        change();
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });
  let submitForm = null;

  const bindHandleSubmit = (data) => {
    submitForm = data;
  };

  const handleSubmit = (e) => {
    if (submitForm) {
      submitForm(e);
    }
  };

  const handleSubmitBorrower = (values) => {
    mutate({
      branchNo: values.branchNo || null,
      dob: !isSsn ? dateWithSlashes(values.dob) : '',
      id,
      loanId,
      loanNumber: values.loanNumber,
      name: values.name || null,
      phoneNo: values.phoneNo || null,
      ssn: values.ssn || '',
      ssn4: values.ssn4 || '',
      zipCode: values.zip,
    });
  };

  const FieldType1ComponentOptions = useMemo(
    () => ({
      SSN: (
        <FormattedTextField
          name="ssn"
          type="text"
          label="SSN"
          formatProps={{
            format: '###-##-####',
            allowEmptyFormatting: true,
            mask: '_',
          }}
        />
      ),
      SSN4: (
        <FormattedTextField
          name="ssn4"
          type="text"
          label="Last Four of SSN"
          formatProps={{
            format: '#####',
          }}
        />
      ),
      DOB: <BasicDateField format="MM/dd/yyyy" noShadow name="dob" label="Date of birth" />,
      NAME: <BasicTextField name="name" label="First Name" />,
    }),
    [],
  );

  const FieldType2ComponentOptions = useMemo(
    () => ({
      ACCOUNT_NUMBER: <BasicTextField name="loanNumber" label="Account Number" />,
    }),
    [],
  );
  const FieldType3ComponentOptions = useMemo(
    () => ({
      ZIP_CODE: ( 
        <BasicTextField 
          name="zip" 
          label="Zip Code"
          minLength="5"
          maxLength="7"
        />
      ),
      PHONE: (
        <FormattedTextField
          placeholder="1234234563"
          name="phoneNo"
          label="Mobile Number"
          formatProps={{
            format: '(###) ###-####',
            allowEmptyFormatting: true,
            mask: '_',
          }}
        />
      ),
      BRANCH_NO: <BasicTextField name="branchNo" label="Branch Number" />,
    }),
    [],
  );

  const DefaultField = {
    IS_SNN_COMPONENT: FieldType1ComponentOptions.SSN,
    NOT_IS_SNN_COMPONENT: FieldType1ComponentOptions.DOB,
  };

  const SelectedFieldType1Component = useMemo(
    () => ({
      1: {
        VALIDATE_IS_SSN: true,
      },
      2: {
        VALIDATE_IS_SSN: false,
        Component: FieldType1ComponentOptions.SSN4,
      },
      3: {
        VALIDATE_IS_SSN: false,
        Component: FieldType1ComponentOptions.NAME,
      },
    }),
    [FieldType1ComponentOptions],
  );

  const SelectedFieldType2Component = useMemo(
    () => ({
      0: {
        Component: FieldType2ComponentOptions.ACCOUNT_NUMBER,
      },
      1: {
        Component: FieldType2ComponentOptions.ACCOUNT_NUMBER,
      },
    }),
    [FieldType2ComponentOptions.ACCOUNT_NUMBER],
  );
  const SelectedFieldType3Component = useMemo(
    () => ({
      1: {
        Component: FieldType3ComponentOptions.ZIP_CODE,
      },
      2: {
        Component: FieldType3ComponentOptions.PHONE,
      },
      3: {
        Component: FieldType3ComponentOptions.BRANCH_NO,
      },
    }),
    [FieldType3ComponentOptions],
  );

  const IsSsnComponent = useMemo(
    () => DefaultField[isSsn ? 'IS_SNN_COMPONENT' : 'NOT_IS_SNN_COMPONENT'],
    [DefaultField, isSsn],
  );

  return (
    <div>
      <Formik
        initialValues={{
          dob,
          loanNumber: loanNo,
          zip: zipCode,
          ssn4,
          ssn,
          branchNo,
          phoneNo,
          name,
        }}
        validationSchema={validation}
        onSubmit={handleSubmitBorrower}
      >
        {(formikProps) => {
          bindHandleSubmit(formikProps.submitForm);
          return (
            <Dialog open={open} aria-labelledby="form-dialog-title">
              <div className={classes.SmallDialog}>
                <DialogTitle id="form-dialog-title">Add Borrower</DialogTitle>
                <DialogContent>
                  <form>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.root}
                      spacing={2}
                    >
                      <Grid item xs={12} className={classes.bottom}>
                        {SelectedFieldType1Component[linkField1Type] ? (
                          <>
                            {SelectedFieldType1Component[linkField1Type].VALIDATE_IS_SSN
                              ? IsSsnComponent
                              : SelectedFieldType1Component[linkField1Type].Component}
                          </>
                        ) : (
                          <>{IsSsnComponent}</>
                        )}
                      </Grid>
                      {SelectedFieldType2Component[linkField2Type] && (
                        <Grid item xs={6} className={classes.bottom}>
                          {SelectedFieldType2Component[linkField2Type].Component}
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={SelectedFieldType2Component[linkField2Type] ? 6 : 12}
                        className={classes.bottom}
                      >
                        {SelectedFieldType3Component[linkField3Type]
                          ? SelectedFieldType3Component[linkField3Type].Component
                          : FieldType3ComponentOptions.ZIP_CODE}
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={change} color="primary" className={classes.Button}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    className={classes.Button}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          );
        }}
      </Formik>
    </div>
  );
}
