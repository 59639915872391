import React, { useMemo } from 'react';
import { Box, Grid, MenuItem } from '@material-ui/core';

import { usePaymentFrequencies } from 'services/payments/hooks';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';
import WebPopSection from 'components/WebPop/WebPopSection/WebPopSection';
import PaymentDetailsContent from 'components/WebPop/WebPopForm/PaymentDetails/PaymentDetailsContent/PaymentDetailsContent';
import PaymentDetailsItem from 'components/WebPop/WebPopForm/PaymentDetails/PaymentDetailsContent/PaymentDetailsItem/PaymentDetailsItem';
import WebPopActions from 'components/WebPop/WebPopActions/WebPopActions';
import { useFormikContext } from 'formik';
import { SCHEDULED_PAYMENT_FREQUENCIES, SCHEDULED_PAYMENT_TYPES } from 'shared/constants/payments';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';
import { useClientDetailsContext } from 'services/client/context';
import { isACH } from 'shared/utils/webpop';
import BasicCheckbox from 'components/BasicInputs/BasicCheckbox/BasicCheckbox';
import { startOfTomorrow } from 'date-fns';
import { getFollowingDaysFromToday } from 'shared/utils';
import { useActiveTab } from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import { useWebPopSwitch } from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import PaymentMethods from '../../../WebPopForm/PaymentMethods/PaymentMethods';
import PaymentAmount from '../../../WebPopForm/PaymentAmount/PaymentAmount';
import PaymentDate from '../../../WebPopForm/PaymentDate/PaymentDate';
import ConvenienceFee from '../../../WebPopForm/ConvenienceFee/ConvenienceFee';
import WebPopContent from '../../../WebPopContent/WebPopContent';

export default function SchedulePaymentForm({ isEdit, onDisclaimerOpen }) {
  const { data: paymentFrequencies } = usePaymentFrequencies();
  const { onClose } = useActiveTab();
  const { isFormInvalid } = useFormikHelpers();
  const { submitForm, values } = useFormikContext();
  const { achDisclosure, cardDisclosure } = useClientDetailsContext();
  const { goBack } = useWebPopSwitch();
  const handleMakePaymentClick = async () => {
    const isACHPayment = isACH(values.paymentMethodData.paymentType);
    if (!isEdit && ((isACHPayment && achDisclosure) || (!isACHPayment && cardDisclosure))) {
      onDisclaimerOpen();
      return;
    }
    await submitForm();
    goBack();
  };
  const { colSchedMaxDays } = useClientDetailsContext();
  const maximumDateToScheduleAPayment = useMemo(
    () =>
      values.scheFreq === SCHEDULED_PAYMENT_TYPES.ONE_TIME
        ? getFollowingDaysFromToday(colSchedMaxDays, 'MM/dd/yyyy')
        : undefined,
    [values.scheFreq, colSchedMaxDays],
  );

  return (
    <WebPopContent>
      <Box marginBottom="1rem">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PaymentMethods isSchedule inputValueKey="id" name="id" />
          </Grid>
          <Grid item xs={12}>
            <WebPopSection title="Payment Details">
              <PaymentDetailsContent withScheduleCount={!isEdit}>
                <PaymentDate
                  name="scheduleDate"
                  disabled={values.recurringEdit}
                  minDate={startOfTomorrow()}
                  maxDate={(colSchedMaxDays !== undefined && parseInt(colSchedMaxDays) > 0)? maximumDateToScheduleAPayment : undefined}
                />
                {values.scheFreq === SCHEDULED_PAYMENT_FREQUENCIES.SEMIMONTHLY && !isEdit && (
                  <PaymentDate
                    name="scheduleDate2"
                    disabled={values.recurringEdit}
                    minDate={startOfTomorrow()}
                    maxDate={(colSchedMaxDays !== undefined && parseInt(colSchedMaxDays) > 0)? maximumDateToScheduleAPayment : undefined}
                    label="Second Payment Date"
                  />
                )}

                {!isEdit && (
                  <PaymentDetailsItem
                    label="Payment Frequency"
                    input={
                      <BasicSelectInput noShadow name="scheFreq">
                        <MenuItem value={SCHEDULED_PAYMENT_TYPES.ONE_TIME}>One Time</MenuItem>
                        {paymentFrequencies.data.map((paymentFrequency) => (
                          <MenuItem
                            key={paymentFrequency.id}
                            value={paymentFrequency.payment_frequency}
                          >
                            {paymentFrequency.label}
                          </MenuItem>
                        ))}
                      </BasicSelectInput>
                    }
                  />
                )}
                <PaymentAmount isSchedule isEdit={isEdit} name="loanAmount" />
                <ConvenienceFee isSchedule name="convFee" />
              </PaymentDetailsContent>
            </WebPopSection>
          </Grid>
          {(isEdit && Boolean(values.scheFreq) && values.scheFreq !== SCHEDULED_PAYMENT_FREQUENCIES.ONETIME) && (
            <Grid item xs={12}>
              <BasicCheckbox name="recurringEdit" label="Apply to all recurring payments" />
            </Grid>
          )}
        </Grid>
      </Box>
      <WebPopActions
        disabled={isFormInvalid}
        isSubmit={isEdit}
        primaryButtonText={isEdit ? 'Save Changes' : 'Schedule'}
        onPrimaryButtonClick={handleMakePaymentClick}
        onSecondaryButtonClick={onClose}
      />
    </WebPopContent>
  );
}
