import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useField } from 'formik';
import { PAYMENT_TYPES } from 'shared/constants/payments';
import { useClientDetailsContext } from 'services/client/context';
import AddPaymentMethodType from './AddPaymentMethodType/AddPaymentMethodType';

export default function AddPaymentMethodTypes({ name }) {
  const [field, , helpers] = useField({ name });
  const { achEnable, cardEnable, enablePad } = useClientDetailsContext();

  const handleApplyToOptionClick = (value) => {
    helpers.setValue(value);
  };
  return (
    <Box marginBottom="1.5rem">
      <Grid container spacing={2}>
        {!!cardEnable && (
          <Grid item xs>
            <AddPaymentMethodType
              onClick={() => handleApplyToOptionClick(PAYMENT_TYPES.CREDIT_CARD)}
              selected={field.value === PAYMENT_TYPES.CREDIT_CARD}
            >
              Debit Card
            </AddPaymentMethodType>
          </Grid>
        )}
        {!!achEnable && (
          <Grid item xs>
            <AddPaymentMethodType
              onClick={() => handleApplyToOptionClick(PAYMENT_TYPES.ACH)}
              selected={field.value === PAYMENT_TYPES.ACH}
            >
              ACH
            </AddPaymentMethodType>
          </Grid>
        )}
        {enablePad && (
          <Grid item xs>
            <AddPaymentMethodType
              onClick={() => handleApplyToOptionClick(PAYMENT_TYPES.PAD)}
              selected={field.value === PAYMENT_TYPES.PAD}
            >
              PAD
            </AddPaymentMethodType>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
