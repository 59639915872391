import React, { useEffect } from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicTable from 'components/BasicTable/BasicTable';
import Pagination from 'components/Pagination/Pagination';
import { usePagination } from 'components/Pagination/PaginationProvider/PaginationProvider';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useTransactions } from 'services/client/hooks';
import useMixinStyles from 'styles/mixins';

const columns = [
  {
    id: 'loanNo',
    label: 'Loan No',
    format: (value) => value.toFixed(2),
    withSearch: true,
  },
  { id: 'transDate', label: 'Trans Date', withDate: true, minWidth: '128px' },
  { id: 'amt', label: 'Amt', withSearch: true },
  { id: 'authId', label: 'Auth Id', withSearch: true },
  { id: 'orderId', label: 'Order Id', withSearch: true },
  { id: 'firstName', label: 'First Name', withSearch: true },
  { id: 'lastName', label: 'Last Name', withSearch: true },
  { id: 'lastFour', label: 'Last Four', withSearch: true },
  { id: 'type', label: 'Type', withSearch: true },
  { id: 'pmtType', label: 'Pmt Type', withSearch: true },
];

function createData(
  loanNo,
  transDate,
  amt,
  authId,
  orderId,
  firstName,
  lastName,
  lastFour,
  type,
  pmtType,
) {
  return { loanNo, transDate, amt, authId, orderId, firstName, lastName, lastFour, type, pmtType };
}

export default function TransactionsTable() {
  const { resolvedData, latestData } = useTransactions();
  const { values } = useFormikContext();
  const { setData } = usePagination();
  const mixinStyles = useMixinStyles();
  const rows = resolvedData
    ? resolvedData.transactions.map((item) => {
        const array = createData(
          item.loanNo,
          format(new Date(item.transDate.date.replace(/-/g, "/").substring(0, 19)), 'MM/dd/yyyy'),
          `$${parseFloat(item.amt).toFixed(2)}`,
          item.authId,
          item.orderId,
          item.firstName,
          item.lastName,
          item.lastFour,
          item.type,
          item.pmtType,
        );
        return array;
      })
    : null;

  useEffect(() => {
    setData({payload: values, page: 1});
  }, [values, setData])

  return (
    <>
      <BasicCard
        className={mixinStyles.scrollBar}
        flex={1}
        overflow={!latestData ? 'hidden' : 'auto'}
        height="25rem"
        noPadding
        marginBottom="2rem"
      >
        <BasicTable
          align="left"
          horizontalPadding="0.5rem"
          rows={rows}
          columns={columns}
          isLoading={!latestData}
        />
      </BasicCard>
      <Pagination count={resolvedData?.totalPage} />
    </>
  );
}
