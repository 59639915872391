import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useBorrowerDetails } from 'services/loans/hooks';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BasicCard from 'components/BasicCard/BasicCard';
import { Divider } from '@material-ui/core';
import LoanData from 'pages/Borrowers/LoanDetails/detailButtons/LoanData';
import DetailButtons from 'pages/Borrowers/LoanDetails/detailButtons/DetailButtons';
import DetailsUserInfo from 'pages/Borrowers/LoanDetails/detailButtons/DetailUserInfo';
import ReceiptsView from 'components/borrowers/payments/ReceiptsView';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import LoanDetails from 'pages/Borrowers/LoanDetails/detailButtons/LoanDetails';
import { WebPopSwitchProvider } from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { storeAPIUser } from 'shared/utils/authToken';
import { useAPIUser } from 'services/users/hooks';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';
import BorrowersList from '../BorrowersList/BorrowersList';
import SignedUpCard from '../SignedUp/SignedUpCard/SignedUpCard';
import SignedUpTable from '../SignedUp/SignedUpTable/SignedUpTable';
import AddBorrower from '../AddBorrower/AddBorrower';
import { useSignedUp } from 'services/borrowers/hooks';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import { useClientDetailsContext } from 'services/client/context';
import { checkLms } from 'shared/utils/loans';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function LoanDetailsPage() {
  const { isLoading } = useBorrowerDetails();
  const { data, isLoading: isAPIUserLoading } = useAPIUser();
  const { data: signedUpData, isFetching } = useSignedUp();
  const { lms } = useClientDetailsContext();
  const [options, setOptions] = useState(null);

  const prevSignedUpData = usePrevious(signedUpData);

  useEffect(() => {
    if (checkLms(lms) === "CFS") {
      if (prevSignedUpData !== signedUpData && prevSignedUpData !== undefined) {
        setOptions(getDisplayOptions(true, true, false));
      } else {
        setOptions(getDisplayOptions(true, false, true));
      }
    } else {
      setOptions(getDisplayOptions(false, false, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedUpData, isLoading]);

  useEffect(() => {
    if (data) {
      storeAPIUser(data.result.Credentials);
    }
  }, [data]);
  const classes = useStyles();

  const getLoanDetailsOption = () => {
    const loanDetails = (
      <Grid container spacing={4}>
        <Grid className={classes.loanDetailsCard} item xs={12} md={4}>
          <BasicCard noPadding>
            <LoanData />
            <DetailButtons />
            <Divider />
            <LoanDetails />
            <Divider />
            <DetailsUserInfo />
          </BasicCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <ReceiptsView />
        </Grid>
      </Grid>
    );
    return loanDetails;
  }

  const getDisplayOptions = (filters = false, borrowersList = false, loanDetails = false) => {
      const options = (
        <BorrowersContainer>
          {filters === true && 
            <BorrowersFilters
              SearchBar={<BorrowersSearchBar />}
              ListTypeToggle={<BorrowersListItemToggle />}
            />
          }
          {borrowersList === true && 
            <Fragment>
              <BorrowersList data={signedUpData} CardComponent={SignedUpCard} TableComponent={SignedUpTable} EmptyStateComponent={AddBorrower} />
              <BorrowersPagination count={signedUpData?.result?.totalPage} />
            </Fragment>
          }
          {loanDetails === true && getLoanDetailsOption()}
        </BorrowersContainer>
      );
      return options;
  }
  
  return (
    <WebPopSwitchProvider>
      {(isLoading || isAPIUserLoading || isFetching) ? (
        <SpinnerWrapped />
      ) : (
        <div className={classes.root}>
          {options}
        </div>
      )}
    </WebPopSwitchProvider>
  );
}

export default function LoanDetailsAccessControled(params) {
  return (
    <AccessControl perform={PERMISSIONS.BORROWERS.DETAILS} redirectOnNoAccessTo="/borrowers">
      <BorrowersFiltersProvider>
        <LoanDetailsPage {...params} />
      </BorrowersFiltersProvider>
    </AccessControl>
  );
}
