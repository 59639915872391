import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import BasicSelectInput from 'components/BasicInputs/BasicSelectInput/BasicSelectInput';

export default function ACHForm() {
  return (
    <>
      <Grid item xs={12}>
        <BasicTextField name="name" label="Name On Account" placeholder="John Doe" />
      </Grid>
      <Grid item xs={6}>
        <BasicSelectInput name="accountType" label="Account Type">
          <MenuItem value={0}>--</MenuItem>
          <MenuItem value={2}>Savings</MenuItem>
          <MenuItem value={1}>Checking</MenuItem>
        </BasicSelectInput>
      </Grid>
      <Grid item xs={6}>
        <BasicTextField name="bankName" label="Bank Name" placeholder="Bank of America" />
      </Grid>
      <Grid item xs={12}>
        <BasicTextField name="routingNumber" label="Routing Number" placeholder="123456789" maxLength="9"/>
      </Grid>
      <Grid item xs={12}>
        <BasicTextField name="accountNumber" label="Account Number" placeholder="000123456789" maxLength="17"/>
      </Grid>
    </>
  );
}
