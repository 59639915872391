import React from 'react';
import { Tab, makeStyles } from '@material-ui/core';
import { useActiveTab } from '../BasicTabsProvider/BasicTabsProvider';

const getStylesBySize = (size) => {
  switch (size) {
    case 'lg':
      return {
        padding: '0 2rem',
      };
    default:
      return {
        padding: 0,
      };
  }
};

const useTabItemStyles = makeStyles(({ breakpoints, palette }) => ({
  root: ({ isActive, size }) => ({
    position: 'relative',
    minHeight: 40,
    minWidth: 80,
    padding: '0 2rem',
    '&:hover': {
      color: palette.primary[500],
    },
    '&::after': {
      content: '""',
      visibility: isActive ? 'visible' : 'hidden',
      height: '2px',
      width: '100%',
      backgroundColor: palette.primary[500],
      position: 'absolute',
      bottom: 0,
    },
    ...getStylesBySize(size),
  }),
  selected: {},
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 600,
    color: palette.primary[500],
    letterSpacing: '0.25px',
  },
}));

export default function BasicTab({ label, children, index, size }) {
  const { setActiveTab, activeTab } = useActiveTab();
  const tabItemClasses = useTabItemStyles({ isActive: activeTab === index, size });

  return (
    <Tab classes={tabItemClasses} onClick={() => setActiveTab(index)} label={label || children} />
  );
}
