import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import IconButton from 'components/Icon/IconButton/IconButton';
import FlexRow from 'components/FlexRow/FlexRow';
import BasicSelectBase from 'components/BasicInputs/BasicSelectBase/BasicSelectBase';
import { MenuItem, makeStyles } from '@material-ui/core';

export default function SubmitSelect({ value, onSubmit, options }) {
  const [inputValue, setInputValue] = useState(value);
  const [newValue, setNewValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (e) => {
    setInputValue(e);
    const name = options.list.find((item) => parseInt(item.id) === parseInt(e)).name;
    setNewValue(name);
  };

  useEffect(() => {
    if (isEdit) {
      setInputValue(value);
    }
  }, [isEdit, value]);

  const useStyles = makeStyles({
    select: {
      minWidth: '5px'
    },
    menuItem: {
      minWidth: '5px',
      padding: 0,
      '&.Mui-selected, &.Mui-selected:hover, &:hover': {
        background: 'rgba(0, 137, 73, 0.1)',
      },
    },
  });
  const classes = useStyles();
  const list = options.list;
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Box minWidth="0" alignItems="center" display={isEdit ? 'flex' : 'none'}>
        <Box minWidth="0" overflow="hidden" marginRight="0.75rem">
          <Box position="relative">
            <FlexRow overflow="hidden" height="2.25rem" minWidth="100px" padding="0 1rem">
              {isEdit ? '' : newValue}
              <React.Fragment>
                <BasicSelectBase height="30px" paddingTop="3px" className={classes.select} name={options.name.toLowerCase()} onChange={handleChange} defaultValue={list[0].id}>
                    {list.map(values => (
                      <MenuItem
                        className={classes.menuItem}
                        key={values.id}
                        value={values.value}
                      >
                        {values.labelFormat}
                      </MenuItem>
                    ))}
                </BasicSelectBase>
              </React.Fragment>
            </FlexRow>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => onSubmit(inputValue, setIsEdit)}
            name="check"
            marginRight="0.75rem"
          />
          <IconButton name="close" onClick={() => setIsEdit(false)} />
        </Box>
      </Box>
      <Box display={isEdit ? 'none' : 'flex'} alignItems="center" minWidth="0">
        <IconButton marginRight="0.5rem" onClick={() => setIsEdit(true)} name="penFilled" />
        <Box component="span" textOverflow="ellipsis" overflow="hidden" title={value || 'N/A'}>
          {value || 'N/A'}
        </Box>
      </Box>
    </Box>
  );
}
