import React, { useState } from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import BasicSelectBase from 'components/BasicInputs/BasicSelectBase/BasicSelectBase';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import BasicInput from 'components/BasicInputs/BasicInput';
import { useAddLoan } from 'services/loans/hooks';
import { useHistory } from 'react-router-dom';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useBorrowersFilters } from '../BorrowersFiltersProvider/BorrowersFiltersProvider';
import { useClientDetailsContext } from 'services/client/context';
import { getLoanDetails } from 'services/loans/api';
import { checkLms } from 'shared/utils/loans';

export default function AddBorrower() {
  const useStyles = makeStyles({
    selectPortfolio: {
      minWidth: '5px'
    },
    menuItem: {
      minWidth: '5px',
      padding: 0,
      '&.Mui-selected, &.Mui-selected:hover, &:hover': {
        background: 'rgba(0, 137, 73, 0.1)',
      },
    },
  });

  const classes = useStyles();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { createAccountWithPortfolio, lenderBranches, lms } = useClientDetailsContext();
  const history = useHistory();
  const { searchString, portfolio } = useBorrowersFilters();
  const [ portfolioId, setPortfolioId ] = useState(lenderBranches.length ? (lenderBranches[0].id + '') : '');
  const [ ssn, setSsn ] = useState('');
  const [ disabled, setDisabled ] = useState((checkLms(lms) === "CFS" || (createAccountWithPortfolio && lenderBranches.length)) ? true : false);
  const [mutate, { isLoading }] = useAddLoan({
    onSuccess: (res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCESS) {
        const path = `/borrowers/signed-up/borrowers-details/loan-id/${res.result?.data}`;
        history.push(path);
        setAlertMessage('Account added successfully!');
        setTimeout(() => {});
        return;
      }
      setErrorAlertMessage('Account not found');
    },
  });

  const handleOnAdd = async () => {
    const _data = {
      account: searchString
    };
    if (createAccountWithPortfolio && lenderBranches.length) {
      _data.portfolioId = portfolioId;
    }
    if (checkLms(lms) === "CFS") {
      const _data = {
        account: searchString,
        portfolioId: portfolio,
        ssn_last_four: ssn,
        channel: 'CAP'
      };
      const result = await getLoanDetails(null, _data);
      if (result.status) {
        const path = `/`;
        history.push(path);
        setAlertMessage(result.message);
      } else { 
        setErrorAlertMessage(result.message);
      }
    } else {
      mutate(_data);
    }
  };

  const handlePortfolio = event => {
    setDisabled(false);
    setPortfolioId(event);
  };

  const handleSsn = event => {
    if (event.target.value.length === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setSsn(event.target.value);
  };

  return (
    <BasicCard>
      <BasicCardContent>
        <BasicCardTitle color="textSecondary">Account</BasicCardTitle>
        <BasicCardSubtitle>{searchString}</BasicCardSubtitle>
        {checkLms(lms) === "CFS" ?
          <React.Fragment>
            <BasicCardTitle color="textSecondary">Branch Number</BasicCardTitle>
            <BasicCardSubtitle>{portfolio}</BasicCardSubtitle>
            <BasicCardTitle color="textSecondary">Last Four of SSN</BasicCardTitle>
            <BasicInput
                width="100%"
                paddingBottom="5px"
                position="relative"
                minLength="4"
                maxLength="4"
                onChange={handleSsn}
                value={ssn}
                size="sm"
                autoGrow
              />
          </React.Fragment> : null}
        {createAccountWithPortfolio && lenderBranches.length ?
          <React.Fragment>
            <BasicCardTitle color="textSecondary">Portfolio</BasicCardTitle>
            <BasicSelectBase whiteSpace="break-spaces" className={classes.selectPortfolio} name="portfolio" onChange={handlePortfolio} defaultValue={lenderBranches[0].id}>
              {lenderBranches.map(branch => (
                <MenuItem
                  className={classes.menuItem}
                  key={branch.id}
                  value={branch.branchId}
                >
                  {branch.branchName + ' - ' + branch.branchId}
                </MenuItem>
              ))}
            </BasicSelectBase>
          </React.Fragment> : null}
      </BasicCardContent>
      <BasicCardActions>
        <ContainedButton onClick={handleOnAdd} isLoading={isLoading} size="sm" disabled={disabled}>
          Add Account
        </ContainedButton>
      </BasicCardActions>
    </BasicCard>
  );
}
