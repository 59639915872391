import { Grid } from '@material-ui/core';
import React from 'react';
import ProfilePassword from './ProfilePassword/ProfilePassword';
import ProfileSummary from './ProfileSummary/ProfileSummary';

export default function ProfileSettings() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <ProfileSummary />
      </Grid>
      <Grid item xs={8}>
        <ProfilePassword />
      </Grid>
    </Grid>
  );
}
