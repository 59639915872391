import React from 'react';
import { Box } from '@material-ui/core';
import GroupDetails from 'components/AddNewGroupModal/GroupDetails/GroupDetails';
import { useQueryCache } from 'react-query';
import { useGroupsActiveTab } from '../GroupsTabActiveProvider/GroupsTabActiveProvider';

export default function GroupDetailsTab() {
  const { groupsTabActive } = useGroupsActiveTab();
  const queryCache = useQueryCache();

  const handleOnSuccess = () => {
    queryCache.invalidateQueries(['group-loan-list', { groupId: groupsTabActive }], {
      refetchInactive: true,
    });
  };

  return (
    <Box padding="0">
      <GroupDetails
        maxHeight="37.5vh"
        onSuccess={handleOnSuccess}
        groupToEditId={groupsTabActive}
      />
    </Box>
  );
}
