import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  modal: {
    maxHeight: 'calc(100vh - 1rem)',
    borderRadius: '8px',
  },
}));

export default function BasicDialog({ isOpen, onClose, children, className }) {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ className: clsx(classes.modal, className) }}
    >
      {children}
    </Dialog>
  );
}
