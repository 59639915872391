import { Box } from '@material-ui/core';
import React from 'react';
import iPhoneNotifications from 'assets/img/notifications-iphone.png';
import NotificationsPreviewItem from './NotificationsPreviewItem/NotificationsPreviewItem';
import NotificationsPreviewCarousel from './NotificationsPreviewCarousel/NotificationsPreviewCarousel';

export default function NotificationsPreview() {
  return (
    <Box height="100%" bgcolor="#c4c4c414">
      <NotificationsPreviewCarousel>
        <NotificationsPreviewItem
          title="NO. OF DAYS BEFORE DUE DATE PREVIEW"
          description="This is a preview of the notifications the users will receive"
          image={iPhoneNotifications}
        />
        <NotificationsPreviewItem
          title="NO. OF DAYS BEFORE EXPIRY OF CARD PREVIEW"
          description="This is a preview of the notifications the users will receive"
          image={iPhoneNotifications}
        />
      </NotificationsPreviewCarousel>
    </Box>
  );
}
