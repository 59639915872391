import React from 'react';
import { Box } from '@material-ui/core';
import { useField, FieldArray, useFormikContext } from 'formik';
import RulesFormPlatformsItem from './RulesFormPlatformsItem/RulesFormPlatformsItem';

export default function RulesFormPlatforms({ platforms }) {
  const [field, , helpers] = useField('applyOn');
  const checkIfIsSelected = (value) => !!field.value.find((item) => item === value);
  const { values } = useFormikContext();

  const handleRemoveItem = (value) => {
    helpers.setValue(
      field.value.filter((prevSelectedPlatforms) => prevSelectedPlatforms !== value),
    );
  };

  return (
    <Box display="flex" alignItems="center">
      <FieldArray
        name="applyOn"
        render={(arrayHelpers) => (
          <>
            {platforms.map((platform) => (
              <RulesFormPlatformsItem
                disabled={!values.offHide}
                key={platform.name}
                name={platform.name}
                icon={platform.icon}
                addItem={() => arrayHelpers.push(platform.value)}
                removeItem={() => handleRemoveItem(platform.value)}
                arrayName="applyOn"
                active={checkIfIsSelected(platform.value)}
              />
            ))}
          </>
        )}
      />
    </Box>
  );
}
