import React, { useState, useEffect, useMemo } from 'react';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import { Switch, useLocation } from 'react-router-dom';
import SecondNavBar from 'containers/navigation/SecondNavBar';
import Container from 'components/Container/Container';
import { useClientDetailsContext } from 'services/client/context';
import { useAppDetails } from 'services/borrowers/hooks';

export default function Borrowers({ routes }) {
  const { showTransactionsPageInCap } = useClientDetailsContext();
  const BORROWERS_TABS = useMemo(() => {
    const tabs = [
      { name: 'Signed Up', value: 0, path: '/borrowers/signed-up', appDetailsKey: 'signedUp' },
      { name: 'On App', value: 0, path: '/borrowers/on-app', appDetailsKey: 'appCount' },
      {
        name: 'Payment Made',
        value: 0,
        path: '/borrowers/payment-made',
        appDetailsKey: 'paymentMade',
      },
      {
        name: 'Failed Link',
        value: 0,
        path: '/borrowers/failed-link',
        appDetailsKey: 'failedLink',
      },
    ];
    if (showTransactionsPageInCap) {
      tabs.push({ name: 'Transactions', value: 0, path: '/borrowers/transactions' });
    }
    return tabs;
  }, [showTransactionsPageInCap]);
  const location = useLocation();
  const [counts, setCounts] = useState(BORROWERS_TABS);
  const { data, refetch } = useAppDetails();

  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  useEffect(() => {
    if (data) {
      const count = BORROWERS_TABS.map((tab) => ({
        ...tab,
        value: data.result[tab.appDetailsKey],
      }));
      setCounts(count);
    }
  }, [data, BORROWERS_TABS]);

  return (
    <>
      <SecondNavBar counts={counts} />
      <Container>
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Container>
    </>
  );
}
