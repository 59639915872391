import React from 'react';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import Icon from 'components/Icon/Icon';
import * as yup from 'yup';
import Form from 'components/Form/Form';
import { useForgotPassword } from 'services/auth/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import HomeCard from '../HomeCard/HomeCard';
import HomeCardHeader from '../HomeCard/HomeCardHeader/HomeCardHeader';
import HomeCardForm from '../HomeCard/HomeCardForm/HomeCardForm';
import HomeCardFormInput from '../HomeCard/HomeCardForm/HomeCardFormInput/HomeCardFormInput';
import HomeCardActions from '../HomeCard/HomeCardActions/HomeCardActions';

const validationSchema = yup.object({
  email: yup.string().email('Email address is not valid').required('Email address is required'),
});

const initialValues = {
  email: '',
  platform: 'new_cap',
};

export default function ForgotPassword() {
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const [mutate] = useForgotPassword({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage(response.message);
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleOnSubmit = async (values) => {
    await mutate(values);
  };

  return (
    <HomeCard>
      <HomeCardHeader
        title="Forgot password?"
        description="Enter your email address below and we’ll send you a link to reset your password."
      />
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
      >
        <HomeCardForm>
          <HomeCardFormInput>
            <BasicTextField
              startIcon={<Icon name="mail" />}
              name="email"
              label="Email"
              placeholder="user@gmail.com"
            />
          </HomeCardFormInput>
        </HomeCardForm>
        <HomeCardActions
          secondaryButtonColor="primary.500"
          primaryButtonText="Send Email"
          secondaryButtonText="LOGIN"
          secondaryButtonURL="/"
        />
      </Form>
    </HomeCard>
  );
}
