import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { Grid } from '@material-ui/core';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import { useDetails, useUpdateTermsText } from 'services/client/hooks';
import SettingsSection from '../../Shared/SettingsSection/SettingsSection';
import SettingsSectionUpdate from '../../Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import useBTC from '../useBTC';

export default function AdditionalInformation() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateTermsText({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, {
        alertMessage: 'Additional information updated successfully!',
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      clientCustomUrl1: data.clientCustomUrl1,
      clientCustomUrl2: data.clientCustomUrl2,
      clientCustomUrl1Text: data.clientCustomUrl1Text,
      clientCustomUrl2Text: data.clientCustomUrl2Text,
    }),
    [data],
  );

  const handleOnSubmit = async (values) => {
    await mutate(values);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection
        title="Additional Information"
        description="Add custom URL for custom link on portal"
        name="logoPlaceholder"
      >
        <FlexColumn height="15rem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BasicTextField name="clientCustomUrl1" placeholder="Custom URL 1" />
            </Grid>
            <Grid item xs={6}>
              <BasicTextField name="clientCustomUrl2" placeholder="Custom URL 2" />
            </Grid>
            <Grid item xs={6}>
              <BasicTextField name="clientCustomUrl1Text" placeholder="Text URL 1" />
            </Grid>
            <Grid item xs={6}>
              <BasicTextField name="clientCustomUrl2Text" placeholder="Text URL 2" />
            </Grid>
          </Grid>
          <SettingsSectionUpdate />
        </FlexColumn>
      </SettingsSection>
    </Form>
  );
}
