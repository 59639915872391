import React from 'react';

export default function CloseIcon({ width, height, color }) {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill={color ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.212 6l4.537-4.537A.856.856 0 1 0 10.537.251L6 4.788 1.463.251A.856.856 0 1 0 .251 1.463L4.788 6 .251 10.537a.856.856 0 1 0 1.212 1.212L6 7.212l4.537 4.537a.855.855 0 0 0 1.212 0 .856.856 0 0 0 0-1.212L7.212 6z"
        fill={color ?? "#7C8DA0"}
        fillOpacity=".88"
      />
    </svg>
  );
}
