import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useBorrowerDetails } from 'services/loans/hooks';
import { useClientDetailsContext } from 'services/client/context';
import dummy from 'assets/img/dummy.jpg';
import LoanDetailsBorrower from '../LoanDetailsCommon/LoanDetailsBorrower/LoanDetailsBorrower';
import LoanDetailsPayment from '../LoanDetailsCommon/LoanDetailsPayment/LoanDetailsPayment';
import BasicCardBanner from 'components/BasicCard/BasicCardBanner/BasicCardBanner';
import useMixinStyles from 'styles/mixins';
import { dateWithSlashes, replaceHyphenWithSlashes } from 'shared/utils';

const useStyles = makeStyles((theme) => ({
  media: {
    height: '10rem',
    width: '100%',
    borderRadius: '0.5rem',
    marginBottom: '1.25rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    [theme.breakpoints.only('sm')]: {
      height: '20rem',
    }
  }
}));

export default function LoanData() {
  const mixinStyles = useMixinStyles();
  const classes = useStyles();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { imageName, hideBorPicsInCap } = useClientDetailsContext();
  // If the loan image is not available, then we use the default image
  let loanImage = imageName;
  if (!hideBorPicsInCap && borrowerDetails.vechileImage) {
    loanImage = borrowerDetails.vechileImage;
  }
  // If the borrower image is not available, then we use the default borrower image
  let borrowerImage = dummy;
  if (!hideBorPicsInCap && borrowerDetails.loan.borrower.userImage) {
    borrowerImage = borrowerDetails.loan.borrower.userImage;
  }
  return (
    <>
      {borrowerDetails.loan && (
        <BasicCardWrapper padding="1rem 1.125rem 0">
          <CardMedia className={classes.media} image={loanImage} title="company logo" />
          <CardContent>
            <LoanDetailsBorrower
              avatar={borrowerImage}
              name={
                borrowerDetails.loan.borrower.firstName
                  ? `${borrowerDetails.loan.borrower.firstName} ${borrowerDetails.loan.borrower.lastName}`
                  : 'N/A'
              }
            />
            {borrowerDetails.deletedAt && (
              <BasicCardBanner
                alignSelf="flex-start"
                marginLeft="0.5rem"
                borderRadius="8px"
                padding="0.25rem"
                className={mixinStyles.noWrap}
              >
                {`Account Unlinked On ${dateWithSlashes(borrowerDetails.deletedAt.date)}`}
              </BasicCardBanner>
            )}
            <Box padding="0.75rem 0.875rem" borderRadius="4px" bgcolor="rgba(245, 246, 248, 0.38)">
              <Grid spacing={3} container>
                <Grid item xs={6}>
                  <LoanDetailsPayment
                    primary
                    label="Last Payment"
                    value={borrowerDetails.loan?.lastPmtAmt}
                    date={
                      borrowerDetails.loan?.lastPmtDt?.date
                        ? dateWithSlashes(borrowerDetails.loan?.lastPmtDt?.date)
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <LoanDetailsPayment
                    label="Next Payment"
                    value={borrowerDetails.loan.calNextPayment || borrowerDetails.loan.regularPmtAmt}
                    date={borrowerDetails.loan.daysPastDue > 0 ? borrowerDetails.loan.oldDueDate ? dateWithSlashes(replaceHyphenWithSlashes(borrowerDetails.loan.oldDueDate)) : 'N/A' : borrowerDetails.loan?.newOldestDate?.date ? dateWithSlashes(borrowerDetails.loan?.newOldestDate.date) : 'N/A'}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </BasicCardWrapper>
      )}
    </>
  );
}
