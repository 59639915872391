import FlexColumn from 'components/FlexColumn/FlexColumn';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

const { utcToZonedTime, format } = require('date-fns-tz');

export default function TimezoneRealTime() {
  const { values } = useFormikContext();

  const getCurrentDateString = useCallback(() => {
    const date = values.cron_timezone
      ? utcToZonedTime(new Date(), values.cron_timezone)
      : new Date();
    return date;
  }, [values.cron_timezone]);

  const [realTimeDate, setRealTimeDate] = useState(getCurrentDateString());
  useEffect(() => {
    setRealTimeDate(getCurrentDateString());
    const intervalOfRealTimeDate = setInterval(() => {
      setRealTimeDate(getCurrentDateString());
    }, 1000);
    return () => {
      clearInterval(intervalOfRealTimeDate);
    };
  }, [getCurrentDateString]);

  return (
    <FlexColumn alignItems="center">
      <Text
        fontSize="3rem"
        fontWeight={700}
        letterSpacing="0.72px"
        color="rgba(34, 47, 62, 0.66)"
        lineHeight="1"
      >
        {format(realTimeDate, 'HH:mm:ss', { timeZone: values.cron_timezone })}
      </Text>
      <Text
        fontSize="1.125rem"
        fontWeight={700}
        letterSpacing="0.27px"
        color="rgba(34, 47, 62, 0.66)"
        lineHeight="1"
        marginBottom="0.25rem"
      >
        {format(realTimeDate, 'O', { timeZone: values.cron_timezone })}
      </Text>
      <Text
        fontSize="0.75rem"
        fontWeight={500}
        letterSpacing="0.18px"
        color="rgba(34, 47, 62, 0.66)"
        lineHeight="1"
      >
        {format(realTimeDate, 'EEEE dd, MMMM', { timeZone: values.cron_timezone })}
      </Text>
    </FlexColumn>
  );
}
