import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Text from 'components/Text/Text';
import { CircularProgress } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';

export default function ConfirmationDialog({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  loading,
  mainActionText = 'Delete',
  mainActionColor = 'error.500',
  children,
  disableSubmit = false
}) {
  const mixinClasses = useMixinStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Text component="div" color="text.primary">
            {description}
          </Text>
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button className={mixinClasses.properCase} disabled={loading} onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button disabled={disableSubmit} className={mixinClasses.properCase} onClick={onConfirm}>
          {loading && <CircularProgress size={18} />}
          {!loading && <Text color={mainActionColor}>{mainActionText}</Text>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
