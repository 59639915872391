import React from 'react';
import { Box } from '@material-ui/core';
import { useActiveTab } from '../BasicTabsProvider/BasicTabsProvider';

export default function BasicTabPanel({ children, index, value, ...other }) {
  const { activeTab } = useActiveTab();
  return (
    <Box
      width="100%"
      height="100%"
      role="tabpanel"
      hidden={activeTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {activeTab === index && children}
    </Box>
  );
}
