import React from 'react';
import SearchBarProvider from 'components/SearchBar/SearchBarProvider/SearchBarProvider';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import GroupAssociatedAccountsList from './GroupAssociatedAccountsList/GroupAssociatedAccountsList';

export default function GroupAssociatedAccounts() {
  return (
    <FlexColumn height="100%" padding="1rem 2.5rem">
      <SearchBarProvider>
        <GroupAssociatedAccountsList />
      </SearchBarProvider>
    </FlexColumn>
  );
}
