import React from 'react';
import { Box } from '@material-ui/core';
import AddNewCollection from './AddNewCollection/AddNewCollection';

export default function CollectionsToolbar() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" marginBottom="1.5rem">
      <AddNewCollection />
    </Box>
  );
}
