import React from 'react';
import { useLoanDetails, useBorrowerDetails } from 'services/loans/hooks';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';

import {
  usePaymentFrequencies,
  useSchedulePayment,
  useEditScheduledPayment,
} from 'services/payments/hooks';
import { buildFormData, pick, dateWithDashes, getFollowingDaysFromToday, to } from 'shared/utils';
import { getStoredLoggedInUser } from 'shared/utils/authToken';
import useDisclosure from 'shared/hooks/useDisclosure';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import {
  PAYMENT_CHANNELS,
  SCHEDULED_PAYMENT_TYPES,
  PAYMENT_TYPES,
  SCHEDULED_PAYMENT_FREQUENCIES,
} from 'shared/constants/payments';
import * as yup from 'yup';
import { useClientDetailsContext } from 'services/client/context';
import { useQueryCache } from 'react-query';
import WebPopForm from '../../WebPopForm/WebPopForm';
import PaymentResponse from '../PaymentResponse/PaymentResponse';
import MakePaymentDisclaimer from '../MakePayment/MakePaymentDisclaimer/MakePaymentDisclaimer';
import SchedulePaymentForm from './SchedulePaymentForm/SchedulePaymentForm';

const tomorrow = getFollowingDaysFromToday(1);
const initialValues = {
  id: '',
  loanAmount: '',
  channel: PAYMENT_CHANNELS.CAP,
  type: 0,
  transaction: 0.0,
  scheduleDate: tomorrow,
  scheduleDate2: tomorrow,
  scheFreq: SCHEDULED_PAYMENT_TYPES.ONE_TIME,
  scheduleCount: null,
  convFee: '',
  customEmail: '',
};

const COMMON_SCHEDULED_PAYMENT_KEYS = [
  'loanAmount',
  'convFee',
  'paymentType',
  'id',
  'channel',
  'type',
];
const EDIT_SCHEDULED_PAYMENT_KEYS = [...COMMON_SCHEDULED_PAYMENT_KEYS, 'scheduleId'];
const SCHEDULE_PAYMENT_KEYS = [
  ...COMMON_SCHEDULED_PAYMENT_KEYS,
  'scheFreq',
  'customEmail',
  'scheduleCount',
];

const RECURRING_EDIT = {
  SINGLE: 0,
  APPLY_TO_ALL: 1,
};

const getValidationSchema = (recurringFrequenciesForACHPaymentMethodsOnly, isEdit) =>
  yup.object({
    id: yup
      .string()
      .required('Payment method required')
      .test(
        'isPaymentMethodSelect',
        'Invalid method for recurring frequencies. Please select an ACH',
        function(val) {
          if (
            this.parent.scheFreq !== SCHEDULED_PAYMENT_TYPES.ONE_TIME &&
            recurringFrequenciesForACHPaymentMethodsOnly
          ) {
            return (
              Number(val) !== '' &&
              this.parent.paymentMethodData?.paymentType !== PAYMENT_TYPES.CREDIT_CARD
            );
          }
          return Number(val) !== '';
        },
      ),
    loanAmount: yup
      .string()
      .required('Payment amount is required')
      .test('len', 'Payment amount should be at least $0.10', val => Number(val) >= 0.10),
    scheduleDate: yup.string().required('Schedule date is required').nullable(),
    scheduleDate2: yup.string().when('scheFreq', {
      is: value => value === SCHEDULED_PAYMENT_FREQUENCIES.SEMIMONTHLY && !isEdit,
      then: yup.string().required('Second date is required').nullable(),
      otherwise: yup.string(),
    }),
    convFeeWaivedReasonId: yup.string().when('waiveConvenienceFee', {
      is: true,
      then: yup.string().required('A reason is required'),
      otherwise: yup.string(),
    }),
  });

export default function SchedulePayment({ isEdit, editValues }) {
  const { data: borrowerDetails } = useBorrowerDetails();
  const { isLoading: isPaymentFrequenciesLoading } = usePaymentFrequencies();
  const { isLoading } = useLoanDetails(borrowerDetails.loan.loanNo);
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const onScheduledPaymentSuccess = response => {
    if (response.status === API_RESPONSE_STATUS.SUCCESS) {
      queryCache.invalidateQueries('borrower-details', {
        refetchInactive: true,
      });
      queryCache.invalidateQueries('schedule-list', { refetchInactive: true });
      setAlertMessage(
        isEdit ? 'Scheduled payment updated successfully!' : 'Payment scheduled successfully!',
      );
      return;
    }
    setErrorAlertMessage(response.message);
  };
  const [
    mutate,
    { isLoading: isSchedulePaymentLoading, data: schedulePaymentData, reset },
  ] = useSchedulePayment({
    onSuccess: onScheduledPaymentSuccess,
    onError: () => {
      setErrorAlertMessage('There was a problem scheduling the payment');
    },
  });
  const [mutateEdit] = useEditScheduledPayment({
    onSuccess: onScheduledPaymentSuccess,
    onError: () => {
      setErrorAlertMessage('There was a problem scheduling the payment');
    },
  });
  const {
    isOpen: isDisclaimerOpen,
    onOpen: onDisclaimerOpen,
    onClose: onDisclaimerClose,
  } = useDisclosure();
  const user = getStoredLoggedInUser();
  const { colSchedRecurringAchOnly } = useClientDetailsContext();

  const handleSubmitMakePayment = async (values, { resetForm }) => {
    const apiValues = pick(values, isEdit ? EDIT_SCHEDULED_PAYMENT_KEYS : SCHEDULE_PAYMENT_KEYS);
    const formData = new FormData();
    const formDataObject = {
      ...apiValues,
      scheduleDate1: dateWithDashes(values.scheduleDate),
      ...(!isEdit && { scheduleDate2: dateWithDashes(values.scheFreq === SCHEDULED_PAYMENT_FREQUENCIES.SEMIMONTHLY ? values.scheduleDate2 : values.scheduleDate) }),
      ...(!isEdit && { type: apiValues.scheFreq === 0 ? 1 : 2 }),
      ...(!isEdit && { userno: user.id }),
      ...(isEdit && {
        recurringEdit: values.recurringEdit ? RECURRING_EDIT.APPLY_TO_ALL : RECURRING_EDIT.SINGLE,
      }),
      ...(!isEdit && values.principalOnly && { principalOnly: values.principalOnly }),
      ...(!isEdit && values.waiveConvenienceFee && { convFeeWaivedReasonId: values.convFeeWaivedReasonId }),
      paymentType: values?.paymentMethodData?.paymentType,
      loanId: borrowerDetails.loanId,
    };

    buildFormData(formData, formDataObject);
    if (isEdit) {
      await mutateEdit(formData);
      return;
    }
    const [errors] = await to(mutate(formData));
    if (!errors) {
      resetForm();
    }
  };

  const handleResetProcess = () => {
    reset();
    onDisclaimerClose();
  };

  return (
    <WebPopForm
      initialValues={editValues || initialValues}
      onSubmit={handleSubmitMakePayment}
      validationSchema={getValidationSchema(colSchedRecurringAchOnly, isEdit)}
    >
      {isLoading || isPaymentFrequenciesLoading ? (
        <SpinnerWrapped />
      ) : (
        <>
          {!isEdit && isDisclaimerOpen && !schedulePaymentData && (
            <MakePaymentDisclaimer
              isSchedule
              onAcceptClick={handleSubmitMakePayment}
              onRejectClick={onDisclaimerClose}
              isLoading={isSchedulePaymentLoading}
            />
          )}
          {!isEdit && schedulePaymentData && (
            <PaymentResponse
              isSchedule
              onBackClick={handleResetProcess}
              responseInfo={schedulePaymentData}
            />
          )}
          {!schedulePaymentData && !isDisclaimerOpen && (
            <SchedulePaymentForm isEdit={isEdit} onDisclaimerOpen={onDisclaimerOpen} />
          )}
        </>
      )}
    </WebPopForm>
  );
}
