import React from 'react';
import { Box } from '@material-ui/core';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useFormikContext } from 'formik';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';

export default function ResetPasswordActions({ onClose }) {
  const { isSubmitting } = useFormikContext();
  const { isFormInvalid } = useFormikHelpers();
  return (
    <Box>
      <Box margin="0 auto" maxWidth="15.875rem" marginBottom="1.25rem">
        <ContainedButton
          disabled={isFormInvalid}
          isLoading={isSubmitting}
          type="submit"
          fullWidth
          size="md"
          variant="contained"
        >
          Update Password
        </ContainedButton>
      </Box>
      <Box margin="0 auto" maxWidth="15.875rem">
        <ContainedButton onClick={onClose} fullWidth size="md" variant="outlined">
          Cancel
        </ContainedButton>
      </Box>
    </Box>
  );
}
