import React from 'react';
import { MenuItem, Menu } from '@material-ui/core';
import Text from 'components/Text/Text';
import FlexRow from 'components/FlexRow/FlexRow';
import Icon from 'components/Icon/Icon';
import useMixinStyles from 'styles/mixins';
import { usePagination } from '../PaginationProvider/PaginationProvider';

export default function RowsPerPage() {
  const mixinClasses = useMixinStyles();
  const { handleItemsPerPageChange, itemsPerPage } = usePagination();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleItemSelect = (value) => {
    handleItemsPerPageChange(value);
    handleClose();
  };

  return (
    <FlexRow>
      <Text fontSize="0.625rem" marginRight="0.25rem" letterSpacing="0.01em">
        Rows per page
      </Text>
      <FlexRow
        className={mixinClasses.clickable}
        role="button"
        onClick={handleClick}
        color="text.primary"
      >
        <Text marginRight="0.25rem" fontSize="0.625rem" fontWeight={600} letterSpacing="0.01em">
          {itemsPerPage}
        </Text>
        <Icon name="chevronDown" />
      </FlexRow>
      <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleItemSelect(12)}>12</MenuItem>
        <MenuItem onClick={() => handleItemSelect(24)}>24</MenuItem>
        <MenuItem onClick={() => handleItemSelect(48)}>48</MenuItem>
      </Menu>
    </FlexRow>
  );
}
