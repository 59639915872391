import React from 'react';
import { Box } from '@material-ui/core';
import BasicTab from 'components/BasicTabs/BasicTab/BasicTab';
import BasicTabPanel from 'components/BasicTabs/BasicTabPanel/BasicTabPanel';
import BasicTabs from 'components/BasicTabs/BasicTabs';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import EmptyState from 'components/EmptyState/EmptyState';
import useDisclosure from 'shared/hooks/useDisclosure';
import useMixinStyles from 'styles/mixins';
import GroupAssociatedAccounts from './GroupAssociatedAccounts/GroupAssociatedAccounts';
import GroupDetails from './GroupDetails/GroupDetails';
import GroupsTabActiveHeader from './GroupsTabActiveHeader/GroupsTabActiveHeader';
import { useGroupsActiveTab } from './GroupsTabActiveProvider/GroupsTabActiveProvider';

const GROUP_DETAIL_TABS = {
  DETAILS: 1,
  ASSOCIATED_ACCOUNTS: 2,
};

export default function GroupsTabActive() {
  const mixinStyles = useMixinStyles();
  const { groupsTabActive } = useGroupsActiveTab();
  const { onOpen } = useDisclosure();

  return (
    <Box width="100%" justifyContent="center" display="flex" flexDirection="column">
      {groupsTabActive ? (
        <>
          <GroupsTabActiveHeader onDeleteOpen={onOpen} />
          <Box
            overflow="hidden"
            display="flex"
            flexDirection="column"
            flex={1}
            className={mixinStyles.scrollBar}
          >
            <BasicsTabsContextProvider initialActiveTab={GROUP_DETAIL_TABS.DETAILS}>
              <Box marginBottom="0.875rem">
                <BasicTabs>
                  <Box display="flex" padding="0.5rem 2rem 0">
                    <Box marginRight="3.5rem">
                      <BasicTab index={GROUP_DETAIL_TABS.DETAILS}>Details</BasicTab>
                    </Box>
                    <Box marginRight="3.5rem">
                      <BasicTab index={GROUP_DETAIL_TABS.ASSOCIATED_ACCOUNTS}>
                        Associated Accounts
                      </BasicTab>
                    </Box>
                  </Box>
                </BasicTabs>
              </Box>
              <BasicTabPanel index={GROUP_DETAIL_TABS.DETAILS}>
                <Box height="100%" flex={1}>
                  <GroupDetails />
                </Box>
              </BasicTabPanel>
              <BasicTabPanel
                index={GROUP_DETAIL_TABS.ASSOCIATED_ACCOUNTS}
                height="100%"
                overflow="hidden"
              >
                <Box height="100%" flex={1}>
                  <GroupAssociatedAccounts />
                </Box>
              </BasicTabPanel>
            </BasicsTabsContextProvider>
          </Box>
        </>
      ) : (
        <EmptyState variant="activeGroup" />
      )}
    </Box>
  );
}
