import React, { useMemo } from 'react';
import * as yup from 'yup';
import { Box, Grid } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import Form from 'components/Form/Form';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import { useDetails, useUpdateNotifications } from 'services/client/hooks';
import SettingsTabHeader from '../SettingsTabHeader/SettingsTabHeader';
import NotificationsForm from './NotificationsForm/NotificationsForm';
import NotificationsPreview from './NotificationsForm/NotificationsPreview/NotificationsPreview';

const validationSchema = yup.object({
  notifyBeforeDueDate: yup
    .string().trim()
    .required('No. of days before due date is required'),
    notifyBeforeCardExp: yup.string().trim().required('No. of days before expiry of card is required'),
});

export default function Notifications() {
  const { isLoading, data } = useDetails();
  const [mutate] = useUpdateNotifications({
    onSuccess: () => {

    }
  })

  const initialValues = useMemo(
    () => ({
      notifyBeforeDueDate: data?.notifyBeforeDueDate,
      notifyBeforeCardExp: data?.notifyBeforeCardExp,
    }),
    [data],
  );

  const handleSubmit = async (values) => {
    await mutate({
      dueDate: values.notifyBeforeDueDate,
      expDate: values.notifyBeforeCardExp
    })
  }

  return (
    <Box>
      <SettingsTabHeader
        title="Notifications"
        description="Customize the notifications your borrowers will receive"
      />
      {isLoading ? (
        <SpinnerWrapped />
      ) : (
        <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          <BasicCard noPadding>
            <Grid container spacing={4}>
              <Grid item xs={7}>
                <NotificationsForm />
              </Grid>
              <Grid item xs={5}>
                <NotificationsPreview />
              </Grid>
            </Grid>
          </BasicCard>
        </Form>
      )}
    </Box>
  );
}
