import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'formik';
import { FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '4px',
    width: '0.875rem',
    height: '0.875rem',
    border: ({ checked }) => (checked ? 'none' : `1px solid rgba(124, 141, 160, 0.88)`),
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary[200],
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary[500],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: '0.875rem',
      height: '0.875rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary[500],
    },
  },
  label: {
    fontSize: '0.625rem',
    letterSpacing: '0.15px',
    color: ({ checked }) => (checked ? theme.palette.primary[500] : theme.palette.text.primary),
    fontWeight: ({ checked }) => (checked ? 600 : 400),
  },
  labelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  inputRoot: {
    padding: 0,
    marginRight: '0.5rem',
  },
}));

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

export default function BasicCheckbox({ name, disabled, label, hideErrors, ...rest }) {
  const [field, , helpers] = useField({ name });
  const classes = useStyles({ checked: !!field.value });
  const handleChange = e => {
    helpers.setValue(e.target.checked);
  };
  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          checked={!!field.value}
          disabled={disabled}
          {...field}
          {...rest}
          onChange={handleChange}
        />
      }
      label={label}
      classes={{ label: classes.label, root: classes.labelRoot }}
    />
  );
}
