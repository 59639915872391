import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  cardTitle: {
    fontWeight: 500,
    fontSize: '0.75rem',
    letterSpacing: '0.15px',
    lineHeight: 1,
    marginBottom: '0.25rem',
    alignSelf: 'flex-start',
    padding: ({ padding }) => (padding ? padding : ''),
    opacity: ({ opacity }) => (opacity ? opacity : ''),
  },
}));

export default function BasicCardTitle({ children, color = 'textPrimary', opacity, padding, className }) {
  const classes = useStyles({opacity, padding});
  return (
    <Typography component="div" className={clsx(classes.cardTitle, className)} color={color}>
      {children}
    </Typography>
  );
}
