import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { usePostComment } from 'services/borrowers/hooks';
import { useBorrowerDetails } from 'services/loans/hooks';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PostCommentForm from 'pages/Borrowers/LoanDetails/detailButtons/forms/PostCommentForm';

export default function PostComment({ onOpen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const { data } = useBorrowerDetails();
  
  const [mutate] = usePostComment({
    onSuccess: response => {
      setLoading(false);
      onClose();
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage(response.message);
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleSubmit = (e) => {
    setLoading(true);
    mutate({comment: comment, loanNo: data.loan.loanNo});
  }
  
  const handleChange = (e) => {
    if (e.target.value) {
        setDisableSubmit(false);
    } else {
        setDisableSubmit(true);
    }
    setComment(e.target.value);
  }

  return (
    <ConfirmationDialog
      title="Post Comment"
      isOpen={onOpen}
      onClose={onClose}
      loading={loading}
      mainActionText="Post Comment"
      mainActionColor="primary.500"
      onConfirm={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box width="21.875rem">
        <PostCommentForm handleChange={handleChange}/>
      </Box>
    </ConfirmationDialog>
  );
}
