import React from 'react';
import { useClientDetailsContext } from 'services/client/context';
import { Box } from '@material-ui/core';
import { checkLms } from 'shared/utils/loans';

export default function BorrowersFilters({
  SearchBar,
  SortBy,
  PaymentMade,
  ListTypeToggle,
  ExportBorrowersList,
}) {
  const { showExportBorrowersList, lms } = useClientDetailsContext();
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="2rem">
      <Box flex={1} display="flex">
        {SearchBar && (
          <Box flex={1} maxWidth={checkLms(lms) === "CFS" ? "45.375rem" : "25.375rem"} marginRight="2.5rem">
            {SearchBar}
          </Box>
        )}
        {SortBy && <Box marginRight="2.5rem">{SortBy}</Box>}
        {(ExportBorrowersList && showExportBorrowersList === '1') && <Box marginRight="2.5rem">{ExportBorrowersList}</Box>}
        {PaymentMade && <Box>{PaymentMade}</Box>}
      </Box>
      {ListTypeToggle && <Box>{ListTypeToggle}</Box>}
    </Box>
  );
}
