import React from 'react';
import { Box } from '@material-ui/core';

const Text = ({
  className,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  color,
  textAlign,
  text,
  style,
  children,
  component = 'p',
  ...rest
}) => {
  return (
    <Box
      color={color}
      textAlign={textAlign}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      fontSize={fontSize}
      component={component}
      margin={0}
      className={className}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Text;
