import { webAPI } from 'shared/utils/http';
import { ENDPOINTS } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const deleteFailedLink = async ({ notifyLenderId }) => {
  const response = await webAPI.post('/payments/delete-notify-lender', {
    notifyLenderId,
  });
  return response.data;
};

export const getFailedLinked = async (key, payload) => {
  const response = await webAPI.post(ENDPOINTS.getFailedLenders, payload);
  return response.data;
};

export const addBorrower = async (payload) => {
  const response = await webAPI.post(ENDPOINTS.postAddBorrower, payload);
  return response.data;
};
export const getSignedUp = async (key, payload) => {
  const response = await webAPI.post(ENDPOINTS.getLenders, payload);
  return response.data;
};
export const getOnApp = async (key, { page, searchString = '' }) => {
  const response = await webAPI.get(
    `${ENDPOINTS.getOnApp}?page=${page}&limit=12${
      searchString.trim().length > 0 ? `&searchString=${searchString}` : ''
    }`,
  );
  return response.data;
};
export const getPaymentMade = async (key, payload) => {
  const response = await webAPI.post(ENDPOINTS.getPaymentMade, payload);
  return response.data;
};
export const getAppDetails = async () => {
  const response = await webAPI.get(ENDPOINTS.getAppDetails);
  return response.data;
};

export const postComment = async (payload) => {
  const response = await webAPI.post(ENDPOINTS.postComment, payload);
  return response.data;
};
