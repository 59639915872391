import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicInput from 'components/BasicInputs/BasicInput';
import BasicButton from 'components/BasicButton/BasicButton';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { useCreateMessage } from 'services/client/hooks';
import { useClientDetailsContext } from 'services/client/context';
import { makeStyles } from '@material-ui/core';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

const useStyles = makeStyles(theme => ({
    listMessageSection: { 
      height: ({ onlyMessages }) => (onlyMessages ? '40vh' : '54vh'),
      overflowY: 'scroll'
    },
    typingSection: {
      height: ({ onlyMessages }) => (onlyMessages ? '8.8vh' : '9vh'),
      padding: '10px',
      backgroundColor: '#f4f4f4'
    },
    messagesLeftCard: {
      marginTop: ({ onlyMessages }) => (onlyMessages ? '20px' : ''),
      marginBottom: ({ onlyMessages }) => (onlyMessages ? '20px' : ''),
      marginLeft: ({ onlyMessages }) => (onlyMessages ? '15px' : '')
    },
    messagesRight: {
      display: 'flex',
      justifyContent: 'end'
    },
    messagesRightCard: {
      marginTop: ({ onlyMessages }) => (onlyMessages ? '20px' : ''),
      marginBottom: ({ onlyMessages }) => (onlyMessages ? '20px' : ''),
      marginRight: ({ onlyMessages }) => (onlyMessages ? '15px' : '')
    },
    buttonLoadMoreMessages: {
      width: '100%',
      borderRadius: '0px',
      marginBottom: '10px'
    },
    buttonSendMessage: {
      height: '5vh',
      marginLeft: '5px'
    },
    dateSegment: {
      paddingTop: ({ onlyMessages }) => (onlyMessages ? '10px' : ''),
      '&::before, &::after': {
        display: 'inline-block',
        content: '""',
        borderTop: '.1rem solid #d5d5db',
        width: '9rem',
        margin: '0 1rem',
      },
    }
}));

export default function MessagesSection({
  dataDisplayMessages,
  dataDisplayGroupMessages,
  activeChatId,
  isLoadingMessages,
  onlyMessages,
  currentPage,
  totalPage,
  updatePage,
  resetPage,
  changingPage
}) {
  const classes = useStyles({ onlyMessages });

  const messageRef = useRef(null);
  
  const { chatMaxCharacter } = useClientDetailsContext();
  const { setErrorAlertMessage } = useAlertContext();

  const [inputValue, setInputValue] = useState('');
  const [countInputValue, setCountInputValue] = useState(0);

  const [ mutate ] = useCreateMessage({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setInputValue('');
        setCountInputValue(0);
        resetPage();
        return;
      }
    },
    onError: () => {
      setErrorAlertMessage('There was a problem when creating the message');
    },
  });

  const handleScrollMessages = useCallback(
    () => {
      if ((messageRef.current.scrollHeight - messageRef.current.scrollTop) === messageRef.current.clientHeight && changingPage) {
        resetPage();
      }
    },
    [messageRef, resetPage, changingPage],
  );

  useEffect(() => {
    const ref = messageRef.current;
    if (ref) {
      ref.addEventListener('scroll', handleScrollMessages);
      if (!changingPage) {
        ref.scrollTop = ref.scrollHeight;
      } else {
        ref.scrollTop = 0;
      }
      return () => {
          ref.removeEventListener('scroll', handleScrollMessages);
      };
    }
  }, [dataDisplayMessages, changingPage, handleScrollMessages]);

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
    setCountInputValue(event.target.value.length);
  };

  const handleSendKeyDownInput = (event) => {
    if (event.key === 'Enter' && inputValue !== '') {
      event.preventDefault();
      mutate({message: inputValue, receiverId: activeChatId, type: 1});
    }
  };
  
  const handleSendButtonInput = (event) => {
    event.preventDefault();
    mutate({message: inputValue, receiverId: activeChatId, type: 1});
  };

  return (
    <React.Fragment>
      <Box ref={messageRef} className={classes.listMessageSection}>
        {dataDisplayMessages && dataDisplayGroupMessages.map((item, key) => {
          return (
            <React.Fragment key={item.id + " " + key}>
              <BasicCardContent>
                {(item === 'LOAD_MORE_MESSAGES') ?
                  <BasicButton
                    className={classes.buttonLoadMoreMessages}
                    onClick={(e) => updatePage(currentPage)}>
                    Load more messages
                 </BasicButton> : 
                 <Box className={classes.dateSegment} display="flex" justifyContent="center" alignItems="center">
                   {item}
                 </Box>
                }
              </BasicCardContent>
              {dataDisplayMessages[item].map((newItem) => {
                return (
                  <BasicCardContent key={item.id + " " + newItem.id}>
                    <Grid alignItems="flex-start" container>
                      <Grid container alignItems="center" item xs={6}>
                        {newItem.type === 2 && 
                          <BasicCard className={classes.messagesLeftCard} backgroundColor="#f2faf6">
                            <Box>
                              <BasicCardTitle>
                                {newItem.message}
                              </BasicCardTitle>
                              <BasicCardTitle>
                                {newItem.time}
                              </BasicCardTitle>
                            </Box>
                          </BasicCard>
                        }
                      </Grid>
                      <Grid className={classes.messagesRight} align="right" item xs={6}>
                        {newItem.type === 1 && 
                          <BasicCard className={classes.messagesRightCard} backgroundColor="#f4f4f4">
                            <Box>
                              <BasicCardTitle>
                                {newItem.username}: {newItem.message}
                              </BasicCardTitle>
                              <BasicCardTitle>
                                {newItem.time}
                              </BasicCardTitle>
                            </Box>
                          </BasicCard>
                        }
                      </Grid>
                    </Grid>
                  </BasicCardContent>
                )
              })}
            </React.Fragment>
          )
        })}
      </Box>
      <Box className={classes.typingSection}>
        <BasicInput
          value={inputValue}
          disabled={!activeChatId}
          endAdornment={
            <BasicButton 
              disabled={!activeChatId || !inputValue.length}
              className={classes.buttonSendMessage}
              onClick={(e) => handleSendButtonInput(e)}>
              Send
            </BasicButton>
          }
          inputProps={{ maxLength: chatMaxCharacter }}
          placeholder="Type a message..."
          onChange={handleChangeInput}
          onKeyDown={(e) => handleSendKeyDownInput(e)}
          loading={isLoadingMessages}
        />
        <BasicCardTitle padding="5px">
          {countInputValue} / {chatMaxCharacter}
        </BasicCardTitle>
      </Box>
    </React.Fragment>
  );
}
