import React from 'react';
import BasicModal from 'components/BasicModal/BasicModal';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import { Box, makeStyles } from '@material-ui/core';
import CollectionDetails from './CollectionDetails/CollectionDetails';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '700px',
    padding: '0 2rem',
  },
}));

export default function AddNewCollectionModal({ isOpen, onClose, onSuccess, collectionToEditId }) {
  const classes = useStyles();

  return (
    <BasicModal className={classes.modal} isOpen={isOpen} onClose={onClose} maxHeight="40vh">
      <BasicModalCloseButton onClick={onClose} />
      <Box marginBottom="2.5rem">
        <BasicModalTitle>{collectionToEditId ? 'Edit Collection' : 'Create new collection'}</BasicModalTitle>
      </Box>
      <CollectionDetails collectionToEditId={collectionToEditId} onSuccess={onSuccess} maxHeight="35vh"/>
    </BasicModal>
  );
}
