import React from 'react';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';
import FlexColumn from 'components/FlexColumn/FlexColumn';

export default function RulesFormPlatformsItem({
  name,
  icon,
  active,
  addItem,
  removeItem,
  disabled,
}) {
  const handleOptionToggle = () => {
    if (active) {
      removeItem();
      return;
    }
    addItem();
  };
  const mixinClasess = useMixinStyles();
  const getBackgroundColor = () => {
    if (!disabled) {
      if (active) {
        return 'primary.200';
      }
      return 'white';
    }
    return 'grey.200';
  };
  const getTextColor = () => {
    if (!disabled) {
      if (active) {
        return 'primary.500';
      }
      return 'text.primary';
    }
    return 'grey.500';
  };
  return (
    <FlexColumn
      onClick={!disabled ? handleOptionToggle : null}
      className={clsx(!disabled && mixinClasess.clickable)}
      marginRight="1.5rem"
      alignItems="center"
    >
      <Box
        border="1px solid #222F3E19"
        borderRadius="50%"
        padding="1rem"
        height="64px"
        width="64px"
        marginBottom="0.5rem"
        color={getTextColor()}
        bgcolor={getBackgroundColor()}
      >
        <Icon name={icon} height="100%" width="100%" />
      </Box>
      <Text
        textAlign="center"
        fontSize="0.6875rem"
        color={getTextColor()}
        fontWeight={active ? 600 : 500}
        letterSpacing="0.10px"
      >
        {name}
      </Text>
    </FlexColumn>
  );
}
