import React from 'react';
import { Popover, Box, Grid } from '@material-ui/core';
import Text from 'components/Text/Text';
import BasicButton from 'components/BasicButton/BasicButton';

export default function ConfirmationPopover({
  title,
  open,
  anchorEl,
  onClose,
  onConfirmClick,
  actionButtons,
}) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box padding="0.5rem" maxWidth="500px">
        <Text
          fontSize="0.75rem"
          marginBottom="0.5rem"
          color="text.primary"
          fontWeight={600}
          letterSpacing="-0.2px"
          textAlign="center"
        >
          {title}
        </Text>
        <Grid container justifyContent="center" spacing={1}>
          {actionButtons || (
            <>
              <Grid item xs={6}>
                <BasicButton
                  onClick={onClose}
                  fullWidth
                  size="small"
                  color="textPrimary"
                  variant="text"
                >
                  No
                </BasicButton>
              </Grid>
              <Grid item xs={6}>
                <BasicButton
                  color="error"
                  onClick={onConfirmClick}
                  fullWidth
                  size="small"
                  variant="error"
                >
                  Yes
                </BasicButton>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Popover>
  );
}
