import BasicCard from 'components/BasicCard/BasicCard';
import BasicTable from 'components/BasicTable/BasicTable';
import Pagination from 'components/Pagination/Pagination';
import { usePagination } from 'components/Pagination/PaginationProvider/PaginationProvider';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useCardUploads } from 'services/client/hooks';
import useMixinStyles from 'styles/mixins';

const columns = [
  { id: 'fileName', label: 'File Name' },
  { id: 'upload', label: 'Upload' },
  { id: 'processDate', label: 'Process Date' },
  { id: 'count', label: 'Count' },
  {
    id: 'batchTotal',
    label: 'Batch Total',
    format: (value) => value.toFixed(2)
  },
  { id: 'status', label: 'Status' }
];

function createData(
  fileName,
  upload,
  processDate,
  count,
  batchTotal,
  status
) {
  return { fileName, upload, processDate, count, batchTotal, status };
}

export default function CardUploadTable() {
  const { data } = useCardUploads();
  const { values } = useFormikContext();
  const { setData } = usePagination();
  const mixinStyles = useMixinStyles();
  const rows = data
    ? data.listCard.map((item) => {
        const array = createData(
          item.cardImport.filename,
          format(new Date(item.cardImport.createdAt.date.replace(/-/g, "/").substring(0, 19)), "MM/dd/yyyy hh:mm:ss aaaaa'm'"),
          (parseInt(item.status) === 1) ? format(new Date(item.cardImport.createdAt.date.replace(/-/g, "/").substring(0, 19)), "MM/dd/yyyy hh:mm:ss aaaaa'm'") : 'N/A',
          item.cardCount,
          `$${parseFloat(item.totalAmount).toFixed(2)}`,
          (parseInt(item.status) === 1) ? 'Completed' : ''
        );
        return array;
      })
    : null;
    
  useEffect(() => {
    setData({payload: values, page: 1});
  }, [values, setData])

  return (
    <>
      <BasicCard
        className={mixinStyles.scrollBar}
        flex={1}
        overflow={!data ? 'hidden' : 'auto'}
        height="25rem"
        noPadding
        marginBottom="2rem"
      >
        <BasicTable
          align="left"
          horizontalPadding="0.5rem"
          rows={rows}
          columns={columns}
          isLoading={!data}
        />
      </BasicCard>
      <Pagination count={data?.totalPage} />
    </>
  );
}
