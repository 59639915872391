import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import useBTC from 'pages/Settings/BTC/useBTC';
import { useClientDetailsContext } from 'services/client/context';
import { useUpdateCAPSettings } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import SettingsSectionUploadImage from '../../Shared/SettingsSection/SettingsSectionUploadImage/SettingsSectionUploadImage';

export default function LoginLogo() {
  const { capCustomizationSettings } = useClientDetailsContext();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUpdateCAPSettings({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { 
        alertMessage: 'Client logo updated successfully!', 
        keyToInvalidate: 'client-details' 
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      imageLoginLogo: capCustomizationSettings?.imageLoginLogo,
    }),
    [capCustomizationSettings],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      imageLoginLogo: values.imageLoginLogo,
    });
    await mutate(formData);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSectionUploadImage
        title="Login Logo"
        description="This logo will appear in the login"
        name="imageLoginLogo"
      />
    </Form>
  );
}
