import React, { useMemo } from 'react';
import Form from 'components/Form/Form';
import { useDetails, useUploadClientLogo } from 'services/client/hooks';
import { buildFormData } from 'shared/utils';
import SettingsSectionUploadImage from '../../Shared/SettingsSection/SettingsSectionUploadImage/SettingsSectionUploadImage';
import useBTC from '../useBTC';

export default function ClientLogo() {
  const { data } = useDetails();
  const { onSettingsUpdateSuccess } = useBTC();
  const [mutate] = useUploadClientLogo({
    onSuccess: (response) => {
      onSettingsUpdateSuccess(response, { alertMessage: 'Client logo updated successfully!' });
    },
  });

  const initialValues = useMemo(
    () => ({
      imageUrl: data.imageUrl,
    }),
    [data.imageUrl],
  );

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    buildFormData(formData, {
      image: values.imageUrl,
    });
    await mutate(formData);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSectionUploadImage
        title="Client Logo"
        description="This logo will appear in the platform tools"
        name="imageUrl"
      />
    </Form>
  );
}
