import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import BasicModal from 'components/BasicModal/BasicModal';
import BasicModalCloseButton from 'components/BasicModal/BasicModalCloseButton/BasicModalCloseButton';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import GroupDetails from './GroupDetails/GroupDetails';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '700px',
    padding: '0 2rem',
  },
}));

export default function AddNewGroupModal({ isOpen, onClose, onSuccess, groupToEditId }) {
  const classes = useStyles();

  return (
    <BasicModal className={classes.modal} isOpen={isOpen} onClose={onClose}>
      <BasicModalCloseButton onClick={onClose} />
      <Box marginBottom="2.5rem">
        <BasicModalTitle>{groupToEditId ? 'Edit Group' : 'Create new group'}</BasicModalTitle>
      </Box>
      <GroupDetails groupToEditId={groupToEditId} onSuccess={onSuccess} />
    </BasicModal>
  );
}
