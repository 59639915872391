import { Grid } from '@material-ui/core';
import BasicSwitch from 'components/BasicInputs/BasicSwitch/BasicSwitch';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import Form from 'components/Form/Form';
import Text from 'components/Text/Text';
import SettingsSection from 'pages/Settings/Shared/SettingsSection/SettingsSection';
import SettingsSectionUpdate from 'pages/Settings/Shared/SettingsSectionUpdate/SettingsSectionUpdate';
import React, { useMemo } from 'react';
import { useControlSettings, useUpdateNLS } from 'services/client/hooks';

export default function NLSUpdate() {
  const { data } = useControlSettings();
  const initialValues = useMemo(
    () => ({
      nlsPostProfile: data.nlsPostProfile,
    }),
    [data],
  );
  const [mutate] = useUpdateNLS();
  const handleOnSubmit = async (values) => {
    mutate({
      nlsPostProfile: values.nlsPostProfile,
    });
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      <SettingsSection title="NLS Update">
        <FlexColumn height="8rem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Text fontSize="0.75rem" lineHeight="0.875rem" letterSpacing="0.3px">
                Allows to update customer information to NLS
              </Text>
            </Grid>
            <Grid item xs={6}>
              <BasicSwitch name="nlsPostProfile" label="NLS ENABLED" />
            </Grid>
          </Grid>
          <SettingsSectionUpdate />
        </FlexColumn>
      </SettingsSection>
    </Form>
  );
}
