import React, { useMemo } from 'react';
import BasicModalTitle from 'components/BasicModal/BasicModalTitle/BasicModalTitle';
import BasicModalGoBack from 'components/BasicModal/BasicModalGoBack/BasicModalGoBack';
import { PAYMENT_CHANNELS, SCHEDULED_PAYMENT_TYPES } from 'shared/constants/payments';
import { getDateBySubstring, replaceHyphenWithSlashes } from 'shared/utils';
import { useBorrowerDetails, useLoanDetails } from 'services/loans/hooks';
import SchedulePayment from '../PaymentTabs/SchedulePayment/SchedulePayment';
import { useWebPopSwitch } from '../WebPopSwitchProvider/WebPopSwitchProvider';

export default function EditScheduledPayment() {
  const { activeComponentInfo, hasBack, goBack } = useWebPopSwitch();
  const { data: borrowerDetails } = useBorrowerDetails();
  const { data } = useLoanDetails(borrowerDetails.loan.loanNo);

  const handleGoBack = () => {
    goBack();
  };

  const mappedEditValues = useMemo(() => {
    const {
      nextExecutionDate,
      pmtAmt,
      loanId,
      paymentType,
      id,
      achMethodId,
      cardMethodId,
      scheduleFreq,
    } = activeComponentInfo;
    if (!data) return null;
    const { paymentMethods } = data.result.data;
    const paymentMethodData = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === (achMethodId || cardMethodId),
    );
    return {
      scheduleDate: new Date(replaceHyphenWithSlashes(getDateBySubstring(nextExecutionDate.date))),
      loanAmount: parseFloat(pmtAmt).toFixed(2),
      convFee: paymentMethodData.transactionFeeFinal,
      recurringEdit: 0,
      loanId,
      paymentType,
      scheduleId: id,
      id: achMethodId || cardMethodId,
      channel: PAYMENT_CHANNELS.CAP,
      scheFreq: scheduleFreq || SCHEDULED_PAYMENT_TYPES.ONE_TIME,
      paymentMethodData,
    };
  }, [data, activeComponentInfo]);

  if (!data) return null;

  return (
    <>
      <BasicModalTitle>
        {hasBack && <BasicModalGoBack onClick={handleGoBack} />}
        Edit Scheduled Payment
      </BasicModalTitle>
      <SchedulePayment isEdit editValues={mappedEditValues} />
    </>
  );
}
