import React from 'react';
import PersonIcon from './PersonIcon/PersonIcon';
import EyeIcon from './EyeIcon/EyeIcon';
import CalendarIcon from './CalendarIcon/CalendarIcon';
import AddIcon from './AddIcon/AddIcon';
import DeleteIcon from './DeleteIcon/DeleteIcon';
import PersonFilledIcon from './PersonFilledIcon/PersonFilledIcon';
import MessagesIcon from './MessagesIcon/MessagesIcon';
import PeopleIcon from './PeopleIcon/PeopleIcon';
import FileIcon from './FileIcon/FileIcon';
import SettingsIcon from './SettingsIcon/SettingsIcon';
import PaymentIcon from './PaymentIcon/PaymentIcon';
import WebIcon from './WebIcon/WebIcon';
import SMSIcon from './SMSIcon/SMSIcon';
import MobileIcon from './MobileIcon/MobileIcon';
import PhoneIcon from './PhoneIcon/PhoneIcon';
import CrossIcon from './CrossIcon/CrossIcon';
import TuitionIcon from './TuitionIcon/TuitionIcon';
import TaxIcon from './TaxIcon/TaxIcon';
import InstallationIcon from './InstallationIcon/InstallationIcon';
import SuccessIcon from './SuccessIcon/SuccessIcon';
import FailureIcon from './FailureIcon/FailureIcon';
import PenIcon from './PenIcon/PenIcon';
import VisaIcon from './VisaIcon/VisaIcon';
import MasterCardIcon from './MasterCardIcon/MasterCardIcon';
import AmexIcon from './AmexIcon/AmexIcon';
import DiscoverIcon from './DiscoverIcon/DiscoverIcon';
import BankIcon from './BankIcon/BankIcon';
import BackIcon from './BackIcon/BackIcon';
import CloseIcon from './CloseIcon/CloseIcon';
import ListIcon from './ListIcon/ListIcon';
import DeleteAltIcon from './DeleteAltIcon/DeleteAltIcon';
import MobileAltIcon from './MobileAltIcon/MobileAltIcon';
import MailIcon from './MailIcon/MailIcon';
import LockIcon from './LockIcon/LockIcon';
import CheckIcon from './CheckIcon/CheckIcon';
import PenFilledIcon from './PenFilledIcon/PenFilledIcon';
import VisibleIcon from './VisibleIcon/VisibleIcon';
import InvisibleIcon from './InvisibleIcon/InvisibleIcon';
import ChevronDownIcon from './ChevronDownIcon/ChevronDownIcon';
import PowerIcon from './PowerIcon/PowerIcon';
import UploadIcon from './UploadIcon/UploadIcon';
import RestoreIcon from './RestoreIcon/RestoreIcon';
import AddAltIcon from './AddAltIcon/AddAltIcon';
import DownloadIcon from './DownloadIcon/DownloadIcon';
import SearchIcon from './SearchIcon/SearchIcon';
import ClockIcon from './ClockIcon/ClockIcon';
import ClockwiseIcon from './ClockwiseIcon/ClockwiseIcon';

export default function Icon({ name, height, width, color, fill }) {
  switch (name) {
    case 'person':
      return <PersonIcon height={height} width={width} />;
    case 'eye':
      return <EyeIcon height={height} width={width} />;
    case 'calendar':
      return <CalendarIcon height={height} width={width} />;
    case 'add':
      return <AddIcon height={height} width={width} />;
    case 'addAlt':
      return <AddAltIcon height={height} width={width} />;
    case 'delete':
      return <DeleteIcon height={height} width={width} />;
    case 'personFilled':
      return <PersonFilledIcon height={height} width={width} />;
    case 'messages':
      return <MessagesIcon height={height} width={width} />;
    case 'people':
      return <PeopleIcon height={height} width={width} />;
    case 'file':
      return <FileIcon height={height} width={width} />;
    case 'settings':
      return <SettingsIcon height={height} width={width} />;
    case 'payment':
      return <PaymentIcon height={height} width={width} color={color} />;
    case 'web':
      return <WebIcon height={height} width={width} color={color} />;
    case 'sms':
      return <SMSIcon height={height} width={width} color={color} />;
    case 'mobile':
      return <MobileIcon height={height} width={width} color={color} />;
    case 'phone':
      return <PhoneIcon height={height} width={width} color={color} />;
    case 'cross':
      return <CrossIcon height={height} width={width} color={color} />;
    case 'tuition':
      return <TuitionIcon height={height} width={width} />;
    case 'tax':
      return <TaxIcon height={height} width={width} />;
    case 'installation':
      return <InstallationIcon height={height} width={width} />;
    case 'success':
      return <SuccessIcon height={height} width={width} />;
    case 'failure':
      return <FailureIcon height={height} width={width} />;
    case 'pen':
      return <PenIcon height={height} width={width} fill={fill} />;
    case 'visa':
      return <VisaIcon height={height} width={width} />;
    case 'mastercard':
      return <MasterCardIcon height={height} width={width} />;
    case 'amex':
      return <AmexIcon height={height} width={width} />;
    case 'discover':
      return <DiscoverIcon height={height} width={width} />;
    case 'bank':
      return <BankIcon height={height} width={width} />;
    case 'back':
      return <BackIcon height={height} width={width} />;
    case 'close':
      return <CloseIcon height={height} width={width} />;
    case 'list':
      return <ListIcon height={height} width={width} />;
    case 'deleteAlt':
      return <DeleteAltIcon height={height} width={width} />;
    case 'mobileAlt':
      return <MobileAltIcon height={height} width={width} />;
    case 'mail':
      return <MailIcon height={height} width={width} />;
    case 'lock':
      return <LockIcon height={height} width={width} />;
    case 'check':
      return <CheckIcon height={height} width={width} />;
    case 'penFilled':
      return <PenFilledIcon height={height} width={width} />;
    case 'visible':
      return <VisibleIcon height={height} width={width} />;
    case 'invisible':
      return <InvisibleIcon height={height} width={width} />;
    case 'chevronDown':
      return <ChevronDownIcon height={height} width={width} />;
    case 'power':
      return <PowerIcon height={height} width={width} />;
    case 'upload':
      return <UploadIcon height={height} width={width} />;
    case 'restore':
      return <RestoreIcon height={height} width={width} />;
    case 'download':
      return <DownloadIcon height={height} width={width} />;
    case 'search':
      return <SearchIcon height={height} width={width} />;
    case 'clock':
      return <ClockIcon height={height} width={width} />;
    case 'clockwise':
      return <ClockwiseIcon height={height} width={width} />;
    default:
      return <CloseIcon height={height} width={width} color={color} />;
  }
}
