import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  cardCommonTitle: {
    fontSize: '0.625rem',
    letterSpacing: '-0.1px',
    lineHeight: 1,
    marginBottom: '0.25rem',
    textAlign: 'left',
  },
  cardCommonSubtitle: {
    fontSize: '0.6875rem',
    letterSpacing: '-0.1px',
    lineHeight: 1.25,
    marginBottom: '0.25rem',
    textAlign: 'left',
    fontWeight: 500,
    wordBreak: 'break-word',
  },
}));

export default function BasicCardCommon({ attribute, value, className }) {
  const classes = useStyles();
  const mixinClasses = useMixinStyles();
  return (
    <Grid item xs>
      <Typography
        className={clsx(classes.cardCommonTitle, mixinClasses.ellipsis, className)}
        color="textSecondary"
      >
        {attribute}
      </Typography>
      <Typography
        className={clsx(classes.cardCommonSubtitle, mixinClasses.ellipsis, className)}
        color="textSecondary"
        title={value}
      >
        {value}
      </Typography>
    </Grid>
  );
}
