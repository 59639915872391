import { useCurrentUser } from 'contexts/CurrentUserProvider';
import React from 'react';
import { Redirect } from 'react-router-dom';

const AccessControl = ({ perform, data, yes, no, children, redirectOnNoAccessTo }) => {
  const { canUserAccessTo } = useCurrentUser();
  return (
    <>
      {canUserAccessTo(perform, data)
        ? yes || children
        : (redirectOnNoAccessTo && <Redirect to />) || no}
    </>
  );
};
AccessControl.defaultProps = {
  yes: null,
  no: null,
};

export default AccessControl;
