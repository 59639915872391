import PERMISSIONS from './permissions';

export const ROLES = {
  ADMIN: 1,
  COLLECTOR: 2,
  READ_ONLY: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
};

const ROLES_PERMISSIONS = {
  [ROLES.ADMIN]: {
    static: [
      PERMISSIONS.RULES.VISIT,
      PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      PERMISSIONS.PAYMENTS.REFUND_CARD,
      PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      PERMISSIONS.BORROWERS.IMPERSONATE,
      PERMISSIONS.GROUPS.VISIT,
      PERMISSIONS.SETTINGS.VISIT,

      PERMISSIONS.BORROWERS.UNLINK_ACCOUNT,
      PERMISSIONS.BORROWERS.CHANGE_PASSWORD,
      PERMISSIONS.BORROWERS.EDIT_INFORMATION,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
      PERMISSIONS.PAYMENTS.DELETE_SCHEDULES,
    ],
  },
  [ROLES.COLLECTOR]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      PERMISSIONS.PAYMENTS.REFUND_CARD,
      PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,

      PERMISSIONS.BORROWERS.CHANGE_PASSWORD,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
      PERMISSIONS.PAYMENTS.DELETE_SCHEDULES,
    ],
  },
  [ROLES.READ_ONLY]: {
    static: [
      PERMISSIONS.CHAT.USE,
      PERMISSIONS.CHAT.READ_ONLY,
      // PERMISSIONS.PAYMENTS.MAKE,
      // PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      // PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      // PERMISSIONS.PAYMENTS.REFUND_CARD,
      // PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      // PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
    ],
  },
  [ROLES.FOUR]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      // PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      // PERMISSIONS.PAYMENTS.REFUND_CARD,
      // PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,

      PERMISSIONS.BORROWERS.CHANGE_PASSWORD,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
      PERMISSIONS.PAYMENTS.DELETE_SCHEDULES,
    ],
  },
  [ROLES.FIVE]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      // PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      PERMISSIONS.PAYMENTS.REFUND_CARD,
      PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      // PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      // PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,

      PERMISSIONS.BORROWERS.CHANGE_PASSWORD,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
      PERMISSIONS.PAYMENTS.DELETE_SCHEDULES,
    ],
  },
  [ROLES.SIX]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      // PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.SCHEDULE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      // PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      // PERMISSIONS.PAYMENTS.REFUND_CARD,
      // PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      // PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      // PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
      PERMISSIONS.PAYMENTS.DELETE_SCHEDULES,
    ],
  },
  [ROLES.SEVEN]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      // PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,

      PERMISSIONS.PAYMENTS.READ_HISTORY,
      // PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      // PERMISSIONS.PAYMENTS.REFUND_CARD,
      // PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      // PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      // PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
    ],
  },
  [ROLES.EIGHT]: {
    static: [
      // PERMISSIONS.RULES.VISIT,
      // PERMISSIONS.CHAT.USE,
      PERMISSIONS.PAYMENTS.MAKE,
      PERMISSIONS.PAYMENTS.READ_HISTORY,
      PERMISSIONS.PAYMENTS.EDIT_SCHEDULES,
      // PERMISSIONS.PAYMENTS.REFUND_CARD,
      // PERMISSIONS.PAYMENTS.VOID_ACH,
      PERMISSIONS.BORROWERS.LIST,
      // PERMISSIONS.BORROWERS.EXPORT_LIST,
      PERMISSIONS.BORROWERS.DETAILS,
      PERMISSIONS.BORROWERS.IMPERSONATE,
      // PERMISSIONS.GROUPS.VISIT,
      // PERMISSIONS.SETTINGS.VISIT,
      PERMISSIONS.PAYMENTS.SCHEDULE_LIST,
    ],
  },
};

export default ROLES_PERMISSIONS;
