import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { dateWithSlashes } from 'shared/utils';
import MoneyFormat from 'components/Formatters/MoneyFormat/MoneyFormat';
import { useFormikContext } from 'formik';
import Text from 'components/Text/Text';
import PaymentSummaryItem from '../PaymentSummaryItem/PaymentSummaryItem';

export default function PaymentResponseReceipt({ confirmId }) {
  const { values } = useFormikContext();
  const { refund } = values;
  const { loanAmount, amount, transaction, convFee } = values;
  const paymentAmount = loanAmount || amount || 0;
  const paymentConvenienceFee = transaction || convFee || 0;
  const totalAmount = Number(paymentAmount) + Number(paymentConvenienceFee) || 0;
  const typeOfActionText = refund ? 'Credit' : 'Payment';

  return (
    <>
      <Text
        fontWeight={500}
        letterSpacing="0.21px"
        fontSize="0.875rem"
        lineHeight="0.875rem"
        marginBottom="0.75rem"
      >
        {`${typeOfActionText} #${confirmId}`}
      </Text>
      <Box
        padding="1rem 1rem 1rem"
        borderRadius="4px"
        overflow="hidden"
        bgcolor="common.backgroundMainLight"
        marginBottom="0.875rem"
      >
        <Grid spacing={2} container justifyContent="flex-end">
          <PaymentSummaryItem attribute={`${typeOfActionText} Date`} value={dateWithSlashes()} />
          <PaymentSummaryItem
            attribute={`${typeOfActionText} Amount`}
            value={<MoneyFormat value={paymentAmount} />}
          />
          <PaymentSummaryItem
            attribute="Convenience Fee"
            value={<MoneyFormat value={paymentConvenienceFee} />}
          />
          <PaymentSummaryItem
            attribute="Total Amount"
            value={<MoneyFormat value={refund ? -totalAmount : totalAmount} />}
          />
        </Grid>
      </Box>
    </>
  );
}
