import React from 'react';
import BasicModalButtons from 'components/BasicModal/BasicModalButtons/BasicModalButtons';
import useFormikHelpers from 'shared/hooks/useFormikHelpers';

export default function AddNewCollectionFooter({ isEdit }) {
  const { isFormInvalid } = useFormikHelpers();
  
  return (
    <>
      <BasicModalButtons
        isSubmit
        disabled={isFormInvalid}
        primaryButtonText={isEdit ? 'Update Collection' : 'Create Collection'}
        hideSecondaryButton
      />
    </>
  );
}
