import { Box } from '@material-ui/core';
import { BasicDateField } from 'components/BasicInputs/BasicDateField/BasicDateField';
import React from 'react';
import { dateWithSlashes } from 'shared/utils';

export default function DateColumnHeader({ column }) {
  return (
    <Box marginTop="0.375rem" paddingRight="0.5rem" paddingBottom="0.625rem">
      <BasicDateField
        noKeyboard
        withDateIcon
        maxDate={dateWithSlashes()}
        size="xs"
        name={column.id}
      />
    </Box>
  );
}
